/*
 * @Author: your name
 * @Date: 2021-07-12 15:26:47
 * @LastEditTime: 2021-11-01 15:19:16
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \hiyan_pc_web\src\App.js
 */
import React from 'react';
import { HashRouter, Switch, Route, Redirect } from 'react-router-dom';
import Layout from './views/layout/index';
import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
function App() {
    return (
        <div className="app">
            <HashRouter>
                <Switch>
                    <Route exact path="/" component={() => <Redirect to="/intelligent" />}></Route>
                    <Route path="/intelligent" component={Layout}></Route>
                    <Redirect from="*" to="/" />
                </Switch>
            </HashRouter>
        </div>
    )
}

export default App;
