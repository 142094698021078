/*
 * @Author: your name
 * @Date: 2021-07-12 15:26:47
 * @LastEditTime: 2024-01-10 16:03:22
 * @LastEditors: chenyu chenyu@hiyanai.com
 * @Description: In User Settings Edit
 * @FilePath: \hiyan_pc_web\src\datas\nav\nav.js
 */

const navList = [{
    id: '1',
    name: '首页',
    path: '/intelligent/home',
},
{
    id: '7',
    name: '核心技术',
    path: '/intelligent/coretech',

},
{
    id: '2',
    name: '产品介绍',
    path: '/intelligent/products',
    list: [
        {
            id: 0,
            name: '鹰巡-巡特警',
            path: '/intelligent/products/0',
        }, {
            id: 1,
            name: '监所安全',
            path: '/intelligent/products/1',
        }, {
            id: 2,
            name: '跨巡-交通预警',
            path: '/intelligent/products/2',
        }, {
            id: 3,
            name: '鹰巡-校园防欺凌',
            path: '/intelligent/products/3',
        }, {
            id: 4,
            name: '焊智保',
            path: '/intelligent/products/4',
        },
    ]
}, 
// {
//     id: '3',
//     name: '解决方案',
//     path: '/intelligent/programme',
//     list: [
//         {
//             id: 1,
//             name: '跨媒体音视频笔记平台',
//             path: '/intelligent/programme/0',
//             // path: '/intelligent/programme?index=0',
//             // path: {pathname: '/intelligent/programme', state:{id:0} }
//         },
//         {
//             id: 2,
//             name: '公安大数据实战研判平台',
//             path: '/intelligent/programme/1',
//             // path: '/intelligent/programme?index=1',
//             // path: {pathname: '/intelligent/programme', state:{id:1} }
//         },
//     ]

// }, {
//    id: '8',
//    name: '资质荣誉',
//    path: '/intelligent/aboutushonor',
// },
{
    id: '4',
    name: '新闻动态',
    path: '/intelligent/news',

}, {
    id: '5',
    name: '关于我们',
    path: '/intelligent/aboutus',
    // list: [
    //     {
    //         id: 1,
    //         name: '公司概况',
    //         path: '/intelligent/aboutussurvey',
    //     },
    //     {
    //         id: 2,
    //         name: '团队介绍',
    //         path: '/intelligent/aboutusteam',
    //     },
        
    //     {
    //         id: 3,
    //         name: '联系我们',
    //         path: '/intelligent/aboutuscontact',
    //     },
    // ]

}, {
    id: '6',
    name: '加入我们',
    path: '/intelligent/joinus',

},
    /* to-do
    {
        id: '6',
        name: '控制台',
        path: '/intelligent/control',
    
    }
    */
]

export {
    navList
}
