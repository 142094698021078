/*
 * @Author: your name
 * @Date: 2021-10-12 09:25:07
 * @LastEditTime: 2021-10-13 19:28:32
 * @LastEditors: Please set LastEditors
 * @Description: 首页-核心技术
 * @FilePath: \hiyan_pc_web\src\views\home\components\core-technology\index.js
 */
import React from 'react';
import {
    cjtxy,
    ttrj,
    rtsj,
} from '../../../../asstes/images';
/**
 * @description: 核心技术
 */
const technologyList = [{
    id: 0,
    name: '跨媒体引擎',
    icon: cjtxy,
}, {
    id: 1,
    name: '公安大模型',
    icon: ttrj,
}, {
    id: 2,
    name: '内容生成(AIGC)',
    icon: rtsj,
}];
function Technologies() {
    return (
        <div className="core-technology-content-technologyList">
            {(technologyList || []).map(item => {
                const { id, name, icon } = item;
                return (
                    <div className="technologyList-item" key={id}>
                        <img src={icon} className="technologyList-item-icon" alt="" />
                        <span className="technologyList-item-name">{name}</span>
                    </div>
                );
            })
            }
        </div>
    );
};
function CoreTechnology() {
    return (
        <div className="core-technology">
            <div className="core-technology-content">
                <div className="core-technology-content-title">核心技术</div>
                <Technologies />
            </div>
        </div>
    );

};

export { CoreTechnology, Technologies };