/*
 * @Author: your name
 * @Date: 2021-07-12 15:26:47
<<<<<<< HEAD
 * @LastEditTime: 2024-01-10 16:51:04
=======
<<<<<<< HEAD
 * @LastEditTime: 2021-10-15 11:39:15
=======
 * @LastEditTime: 2021-10-15 09:28:20
>>>>>>> e98d6e2730f6317fd90591563ac96a8b1aafa573
>>>>>>> 973176f82b7b78e81b02f0ec4e43ccc73fe5ae45
 * @LastEditTime: 2021-10-13 11:38:50
 * @LastEditTime: 2021-10-12 18:02:51
 * @LastEditors: chenyu chenyu@hiyanai.com
 * @Description: In User Settings Edit
 * @FilePath: \hiyan_pc_web\src\views\layout\index.js
 */
import React, { useEffect } from 'react';
import { Switch, Route, Redirect, BrowserRouter } from 'react-router-dom';
import { useLocation } from "react-router";  // 安装了react-router-dom, 不需要再安装react-router
import Home from '../home';
import Products from '../products';
import TechnicalDoc from '../technical-doc';
import FuncDemonstration from '../func-demonstration';

import Programme from '../programme';
import News from '../news';
import NewsDetail from '../news-detail';

import AboutUs from '../about-us-new'
import AboutUsSurvey from '../about-us-survey';
import AboutUsTeam from '../about-us-team';
import AboutUsHonor from '../about-us-honor';
import AboutUsContact from '../about-us-contact';

import JoinUs from '../join-us';

// import Control from '../control';
import Header from '../../components/header';
import Footer from '../../components/footer';
import CoreTech from '../core-tech';



/**
 * @description: Layout布局
 */
function Layout() {
    /**
     * @description: 切换路由用React hooks恢复滚动条回到顶部
     * @param {} ***注意自定义Hooks要用useXXX定义
     * @return: null
     */
    function useScrollToTop() { 
        const { pathname } = useLocation();
        useEffect(() => {
            const layout = document.querySelector(".layout-main");
            if (layout) {
                layout.scrollTop = 0;
            }
        }, [pathname]);

        return null;
    };

    function layoutScroll (e) {
        const layout = document.querySelector(".layout-main");
        if (layout) {
            let header = document.querySelector('.header');
            header && layout.scrollTop > 30 && (header.style.backgroundColor = '#0c1030');
            header && layout.scrollTop <= 30 && (header.style.backgroundColor = `rgba(12,16,48, ${layout.scrollTop / 30 })`); // transparent
        }
    }

    useScrollToTop();

    return (
        <div className="layout" onScroll={layoutScroll}>
            <Header />
            <div className="layout-main">
                <Switch>
                    <Route exact path="/intelligent/home" component={Home}></Route>
                    <Route exact path="/intelligent/coretech" component={CoreTech}></Route>

                    <Route exact path="/intelligent/products/0" component={Products}></Route>
                    <Route exact path="/intelligent/products/1" component={Products}></Route>
                    <Route exact path="/intelligent/products/2" component={Products}></Route>
                    <Route exact path="/intelligent/products/3" component={Products}></Route>
                    <Route exact path="/intelligent/products/4" component={Products}></Route>

                    <Route exact path="/intelligent/technicaldoc" component={TechnicalDoc}></Route>
                    <Route exact path="/intelligent/funcdemonstration" component={FuncDemonstration}></Route>
                    
                    <Route exact path="/intelligent/programme/0" component={Programme}></Route>
                    <Route exact path="/intelligent/programme/1" component={Programme}></Route>
                    
                    <Route exact path="/intelligent/news" component={News}></Route>
                    <Route exact path="/intelligent/news/detail/:id" component={NewsDetail}></Route>
                
                    <Route path="/intelligent/aboutus" component={AboutUs}></Route>
                    {/* <Route exact path="/intelligent/aboutussurvey" component={AboutUsSurvey}></Route>
                    <Route exact path="/intelligent/aboutusteam" component={AboutUsTeam}></Route>
                    <Route exact path="/intelligent/aboutushonor" component={AboutUsHonor}></Route> */}
                    {/* <Route exact path="/intelligent/aboutuscontact" component={AboutUsContact}></Route> */}
                    <Route exact path="/intelligent/joinus" component={JoinUs}></Route>
                    {/* <Route exact path="/intelligent/control" component={Control}></Route> */}
                    <Redirect exact from="/intelligent" to="/intelligent/home" />
                    <Redirect from="*" to="/" />
                </Switch>
                <Footer />
            </div>
        </div>
    )
}

export default Layout;
