/*
 * @Author: your name
 * @Date: 2021-10-13 17:44:57
 * @LastEditTime: 2021-10-13 18:55:08
 * @LastEditors: Please set LastEditors
 * @Description: 应用场景
 * @FilePath: \hiyan_pc_web\src\views\programme\components\programme-scene\index.js
 */
import React from "react";
/**
 * @description: 应用场景
 */

function ProgrammeScene({ sceneIcon }) {
    return (
        <div className="programme-scene">
            <div className="programme-scene-content">
                <div className="programme-content-title">
                    <div className="programme-content-title-block"></div>
                    <p className="programme-content-title-text">应用场景</p>
                </div>
                <img src={sceneIcon} className="programme-content-sceneIcon" alt="" />
            </div>
        </div>
    );
};

export default ProgrammeScene;