/*
 * @Author: your name
 * @Date: 2021-07-12 15:26:47
 * @LastEditTime: 2021-11-01 15:24:54
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \hiyan_pc_web\src\index.js
 */
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import "./common/styles/index.scss";

/**
 * @description: 非开发模式下拒绝打印日志
 */
let logDebug = process.env.NODE_ENV === 'production';
console.log = (function (oriLogFunc) {
  return function () {
    if (!logDebug) {
      oriLogFunc.apply(this, arguments);
    }
  }
})(console.log);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
