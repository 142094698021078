/*
 * @Author: your name
 * @Date: 2021-10-14 13:58:48
 * @LastEditTime: 2024-01-12 10:43:05
 * @LastEditors: chenyu chenyu@hiyanai.com
 * @Description: In User Settings Edit
 * @FilePath: \hiyan_pc_web\src\datas\products\products-banner.js
 */
import React from 'react';
import {
    yxxtjbg,
    yxxyfqlbg,
    yxjsaqbg,
    kxjtyjbg,
    hzbbg,
    yxxtjbgtext,
    yxxyfqlbgtext,
    yxjsaqbgtext,
    kxjtyjbgtext,
    hzbbgtext,
} from '../../asstes/images';
const data = [{
    id: 0,
    backgroundImage: yxxtjbg,
    cuttingText: yxxtjbgtext,
    cuttingTextStyle: {
        width: '261px',
        height: '43px',
        lineHeight: '30px'
    },
    copywriting: {
        content: <div>鹰巡系统为实现现场数据采集、分析推理、预警推送、事件显示等系统集成功能，建成设备管理、事件预警、报警信息发布、即时推送、<br />
            远程干预的综合性系统。系统实时抓取并处理音视频流，完成面对室外重点场所的复杂场景下的冲突事件的检测和识别能力</div>
    }
}, {
    id: 1,
    backgroundImage: yxjsaqbg,
    cuttingText: yxjsaqbgtext,
    cuttingTextStyle: {
        width: '309px',
        height: '43px',
        lineHeight: '30px'
    },
    copywriting: {
        content: <div>海宴鹰巡系统融合视频、声音和雷达，通过多模态大模型分析判断监狱看守所监仓安全，可准确监视监仓人员，<br />
            判断监仓人员是否发生斗殴、自杀等行为</div>
    }
}, {
    id: 2,
    backgroundImage: kxjtyjbg,
    cuttingText: kxjtyjbgtext,
    cuttingTextStyle: {
        width: '310px',
        height: '43px',
        lineHeight: '30px'
    },
    copywriting: {
        content: <div>采用多种手段实时监控道路路口或隧道口的行人和非机动车实时动态信息，并利用边缘计算设备现场跨媒体分析违法事件，<br />
            对接已有的应急广播系统，快速响应违法事件通过应急广播进行语音警示</div>
    }
}, {
    id: 3,
    backgroundImage: yxxyfqlbg,
    cuttingText: yxxyfqlbgtext,
    cuttingTextStyle: {
        width: '356px',
        height: '43px',
        lineHeight: '30px'
    },
    copywriting: {
        content: <div>海宴多模态处理机报警信息会实时发布在平台软件，伴随语音示警，安保或老师可联动附近摄像头画面判断<br />
            是否有危险情况发生并通过广播话筒发出警告，及时发现并制止暴力行为</div>
    }
}, {
    id: 4,
    backgroundImage: hzbbg,
    cuttingText: hzbbgtext,
    cuttingTextStyle: {
        width: '140px',
        height: '43px',
        lineHeight: '30px'
    },
    copywriting: {
        content: <div>焊智保特种作业服务平台旨在探索建立电焊作业管理新模式，通过企业端和个人端双向选择，政府端后台监管，<br />
            引导企业通过平台选择持证电焊人员进行电焊作业服务</div>
    }
}
];

export { data };