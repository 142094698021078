/*
 * @Author: your name
 * @Date: 2021-10-14 14:14:35
 * @LastEditTime: 2021-10-14 14:19:46
 * @LastEditors: Please set LastEditors
 * @Description: 新闻列表数据
 * @FilePath: \hiyan_pc_web\src\datas\news-list\index.js
 */

import {
    news1,
    news2,
    news3,
    news4
} from '../../asstes/images';
const data = [
    {
        id: 0,
        title: '跨巡-交通违章',
        icon: news1,
        time: '2023.12.15',
        synopsis: '科技赋能，智慧交管。采用多种手段实时监控道路路口或隧道口的行人和非机动车实时动态信息，并利用边缘计算设备现场跨媒体分析违法事件，深度保障交通安全。',
    }, {
        id: 1,
        title: '创新打造“焊智保” 应用',
        icon: news2,
        time: '2023.11.08',
        synopsis: '衢州市创新打造 “焊智保”应用，破解电气焊监管难',
    }, {
        id: 2,
        title: '如何破解焊智保监管难题',
        icon: news3,
        time: '2023.10.27',
        synopsis: '浙江省衢州市召开新闻发布会，介绍当地推进“电气焊安全监管服务一件事改革”的经验举措。',
    }, {
        id: 3,
        title: '巡特警揭榜挂帅，海宴科技成功揭榜',
        icon: news4,
        time: '2023.07.14',
        synopsis: '《浙江省数字化改革总体方案》:要求提升“云上公安、智慧警务”大数据应用，同步推进跨系统协同应用，构建现代警务模式下的人员动态管理新模式，牵引现代警务模式智能化体系建设。',
    }
];;


export { data };