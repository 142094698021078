/*
 * @Author: jin
 * @Date: 2021-10-14 09:21:56
 * @LastEditTime: 2021-10-14 10:09:55
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \hiyan_pc_web\src\datas\join-us\join-us.js
 */
import {backgroundOfJoinUs, employeeWelfare } from '../../asstes/images';

const joinUsList={
  backgroudImage:backgroundOfJoinUs,
  title:'人员招聘',
  tag:'HUMAN RESOURCES',
  hotJob:{
        head:'热招岗位',
        desctitle:'岗位工作描述：',
        requirementTitle:'招聘要求：',
        jonInfo:[
          {
            station:'总经理助理',
            desc:[
              '协助总经理维护老客户的业务，挖掘客户的潜力。',
              '定期与合作客户进行沟通，建立良好的长期合作关系。',
              '能够跟进并满足不断变化的客户需求，与部门直接积极配合并促进业务达成。',
              '协助总经理进行市场运营支持，参与商务谈判。'
            ],
            requirement:[
              '具有社团组织经验的应届实习生，本科及以上学历（专业不限）。',
              '有较强的沟通协调能力，有良好的纪律性，团队协作以及服务创新精神。',
              '中共党员，有大学生挑战杯大赛经验的优先。'
            ]        
           },
           {
            station:'售前技术支持',
            desc:[
              '熟悉了解公司的产品、技术服务与解决方案，为潜在客户提供咨询。',
              '与公司各部门进行良好的协调沟通，保证商务工作的畅通。',
              '与客户进行有效沟通了解客户需求，编写方案。'
            ],
            requirement:[
              '具有社团组织经验的应届实习生，本科及以上学历。（信息工程，软件，通讯工程，自动化等相关作业的优先）',
              '做事条理清晰，反应敏捷，抗压力强。具有良好的客户服务意识。',
              '对人工智能的未来有理想。',
              '中共党员，大学生挑战杯大赛经验的优先。'
            ]        
           },
           {
            station:'市场专员',
            desc:[
              '负责整理客户资料并进行有效建档，维护。',
              '在部门负责人的指导下与客户进行有效沟通了解客户需求。',
              '跟进公司项目的一切事项，确保项目的顺利完工。',
              '在技术部门支撑下，基本能独立思考，创建，项目雏形。'
            ],
            requirement:[
              '具有社团组织经验的应届实习生，本科及以上学历（专业不限）。',
              '有较强的沟通协调组织能力，有良好的纪律性，团队协作以及服务创新精神。 ',
              '具有很强的抗压能力，具备艰苦奋斗的精神。',
              '对人工智能的未来充满理想。',
              '中共党员，学生干部优先。'
            ]        
           },
           {
            station:'后备干部',
            desc:[
              '协助公司领导做好市场拓展工作；',
              '整理分析归档资料，协助领导做好日常内勤，外联，商务工作。',
              '协助部门负责人制作销售数据的统计、分析工作，并制作销售报表。',
              '渠道主管，大区域经理助理工作。'
            ],
            requirement:[
              '具有社团组织经验的应届实习生，本科及以上学历（专业不限）。',
              '学习能力强，具备客户心理分析及判断能力，沟通能力强，协调组织能力强。',
              '性格开朗，抗压能力强，具备艰苦奋斗精神。',
              '中共党员，学生干部，有大学生挑战杯大赛经验的优先。',
              '有创业精神。'
            ]        
           }
        ] 
    },
    welfare:{
            head:'员工福利',
            img:employeeWelfare
    },
    recruitmentProcess:{
            head:'招聘流程',
            list:[
              {
                id:1,             
                process:'简历投递'
              },{
                id:2,             
                process:'简历筛选'
              },{
                id:3,             
                process:'面试'
              },{
                id:4,             
                process:'offer'
              },{
                id:5,             
                process:'试用期（培训）'
              },{
                id:6,             
                process:'转正'
              },
            ]
            
    },
    resumeDelivery:{
            head:'简历发送至企业邮箱',
            email:'hy@hiyanai.com'
    }
}

export {
  joinUsList
}