import React, { useState } from 'react';
import { arrowTop } from '../../../../asstes/images';

/**
 * @description: Http请求"Request"和"Respones"
 * @param {props}
 * @return: 
 */
function Http({ httpRes, isChecking, isCheckPass, imageErrorInfo: { isImageError } }) {

    const [ open, changeOpen ] = useState('res'); // 默认打开的res: Respones, req: Request
    
    /**
     * @description: 改变打开的状态
     * @param {type} 
     * @return:
     */
    function changeOpenStatus(type) {
        if(open === type) {
            const ty = open === 'req' ? 'res' : 'req';
            return changeOpen(ty);
        }
        changeOpen(type);
    }
    /**
     * @description: 转化json格式: 无法根据json内容添加不同的class类名，添加不同的样式
     * @param {} 
     * @return: str(json)
    function jsonFormat() {
        if(!httpRes) {
            return null;
        }
        const jsonStr = JSON.stringify(httpRes);
        return JSON.stringify(JSON.parse(jsonStr), null, 4);  // 第三个参数可以调整缩进大小
    }
    */
    /**
     * @description: 转化json格式: 根据json内容添加不同的class类名，添加不同的样式
     * @param {json:json对象} 
     * @return:
     */
    function jsonShowFn(json) {
        // 判断是否是json数据，不是直接返回
        if (!JSON.stringify(json).match("^\\{(.+:.+,*){1,}\\}$")) {
            return json 
        }
        // 合规删除data:null字段，页面不显示
        if(json && json.conclusionType === 1 && json.data === null) {
            delete json.data;
        }

        if (typeof json !== 'string') {
            // 第三个参数可以调整缩进大小
            json = JSON.stringify(json, undefined, 4);
        }
        json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
        return json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\\-]?\d+)?)/g, function (match) {
            var cls = 'number';
            if (/^"/.test(match)) {
                if (/:$/.test(match)) {
                    cls = 'key';
                } else {
                    cls = 'string';
                }
            } else if (/true|false/.test(match)) {
                cls = 'boolean';
            } else if (/null/.test(match)) {
                cls = 'null';
            }
            return '<span class="' + cls + '">' + match + '</span>';
        })
    }


    return (
        <div className="http-wrapper">
            <div className={"http-request" + (open === "req" ? " open" : " close")}>
                <div className="http-title-wrapper" onClick={() => changeOpenStatus('req')}>
                    <span>Request</span>
                    <img className="http-title-wrapper-icon" src={arrowTop} alt="" />
                </div>
                <div className="http-request-content">
                    <p>Params</p>
                    <div className="http-request-content-item">
                        <p className="http-request-content-title">Post</p>
                        <p className="http-request-content-text">https://test.hiyanai.com/jeecg-boot/image/demo/imageOpen</p>
                    </div>
                    <div className="http-request-content-item">
                        <p className="http-request-content-title">Header</p>
                        <p className="http-request-content-text">header: Content-Type: "application/x-www-form-urlencoded"</p>
                    </div>
                </div>
            </div>
            <div className={"http-respones" + (open === "res" ? " open" : " close")}>
                <div className="http-title-wrapper" onClick={() => changeOpenStatus('res')}>
                    <span>Respones</span>
                    <img className="http-title-wrapper-icon" src={arrowTop} alt="" />
                </div>
                {
                    isChecking || isImageError ? null :
                    <div className={"http-respones-content" + (isCheckPass ? ' pass' : ' unpass')}>
                        {/*
                        <pre>
                            <code>
                                { jsonFormat() }
                            </code>
                        </pre>
                        */}

                        <p className="http-respones-content-json" dangerouslySetInnerHTML={{__html: jsonShowFn(httpRes)}}></p>

                    </div>
                }
            </div>
        </div>
    )
}

export default Http;
