import React, { useState } from 'react';
import './index.scss';

/**
 * @description: 左边菜单 
 * @param {props} 
 * @return: 
 */
function Menu({ openKey, selectedKey, datas, menuChildrenCheckClick }) {

    const [ list, setShowMenuChildren ] = useState(initList());
    
    /**
     * @description: 初始化Menu菜单数据(打开菜单，默认选中菜单选项)
     * @param {} 
     * @return: 
     */
    function initList() {
        return (datas.list || []).map(item => {
            if(item.id === openKey) {
                item.isShowChildren = true;
                item.children = (item.children || []).map(childItem => {
                    if(childItem.id === selectedKey) {
                        childItem.isChecked = true;
                    }
                    return childItem;
                })
            }
            return item;
        })
    }
    /**
     * @description: 展开菜单点击事件
     * @param {id} 
     * @return: 
     */
    function showMenuChildrenClick(id) {
        const newList = (list || []).map(item => {
            let itemCopy = { ...item };
            if(item.id === id) {
                itemCopy.isShowChildren = !item.isShowChildren;
            }
            return itemCopy;
        });
        setShowMenuChildren(newList);
    }
    /**
     * @description: 选择菜单具体某个选项事件
     * @param {fatherId, childrenId} 
     * @return: 
     */
    function handleMenuChildrenCheckClick({ fatherId, childrenId }) {
        const newList = (list || []).map(item => {
            if(item.id === fatherId) {
                const newChildrenList = (item.children || []).map(childItem=> {
                    if(childItem.children) {
                        childItem.children = (childItem.children || []).map(it => {
                            it.isChecked = false;
                            return it;
                        })
                    }else {
                        if(childItem.id === childrenId) {
                            childItem.isChecked = true;
                        }else {
                            childItem.isChecked = false;
                        }
                    }
                    return childItem;
                })
                item.children = newChildrenList;
            }else {
                item.isShowChildren = false;
                item.children = (item.children || []).map(childItem => {
                    if(childItem.children) {    
                        childItem.children = (childItem.children || []).map(it => {
                            it.isChecked = false;
                            return it;
                        });
                    }else {
                        childItem.isChecked = false;
                    }
                    return childItem;
                })
            }
            return item;
        })
        setShowMenuChildren(newList);
        menuChildrenCheckClick({ fatherId, childrenId });
    }
    /**
     * @description: 展开菜单点击事件--2级菜单
     * @param {isShowChildren, children} 
     * @return: 
     */
    function showMenuChildrenClickLowerLevel(id) {
        const newList = (list || []).map(item => {
            item.children = item.children.map(_item => {
                let itemCopy = { ..._item };
                if(_item.id === id) {
                    itemCopy.isShowChildren = !_item.isShowChildren;
                }
                return itemCopy;
            })
            return item;
        });
        setShowMenuChildren(newList);
    }
    /**
     * @description: 选择菜单具体某个选项事件--2级菜单的子元素
     * @param {grandfatherId, fatherId,childrenId} 
     * @return: 
     */
    function handleMenuChildrenCheckClickLowerLevel({ grandfatherId, fatherId, childrenId }) {
        const newList = (list || []).map(item => {
            if(item.id === grandfatherId) {
                item.children = item.children.map(_item => {
                    if(_item.id === fatherId) {
                        _item.children = _item.children.map(__item => {
                            let itemCopy = { ...__item };
                            if(__item.id === childrenId) {
                                itemCopy.isChecked = true;
                            }else {
                                itemCopy.isChecked = false;
                            }
                            return itemCopy;
                        })
                    }else {
                        if(!_item.children) {
                            _item.isChecked = false;
                        }else {
                            _item.children = _item.children.map(i => {
                                i.isChecked = false;
                                return i;
                            });
                        }
                    }
                    return _item;
                })
            }else {
                item.isShowChildren = false;
                item.children = (item.children || []).map(childItem => {
                    childItem.isChecked = false;
                    return childItem;
                })
            }
            return item;
        })
        setShowMenuChildren(newList);
        menuChildrenCheckClick({ fatherId, childrenId });
    }
    /**
     * @description: 计算高度
     * @param {isShowChildren, children} 
     * @return: 
     */
    function computedHeight(isShowChildren, children) {
        let childrenOpens = children.filter(childrenItem => childrenItem.isShowChildren);
        function computedchildrenOpensHeight() {
            let total = 0;
            childrenOpens.forEach(item => {
                total += item.children.length * 30
            })
            return total;
        }
        if(childrenOpens.length) {
            return isShowChildren ? (children.length * 30 + 34 + computedchildrenOpensHeight()) : 34;
        }
        return isShowChildren ? (children.length * 30 + 34) : 34;
    }

    return (
        <div className="menu">
            <h3 className="menu-title">{datas.title}</h3>
            <div className="menu-main">
                {/*  2级菜单
                {
                    (list || []).map(({ id, showTitle, img, isShowChildren, children }) => (
                        <div 
                            className={"menu-main-item" + (isShowChildren ? " open" : " close")} 
                            key={id}
                            style={{height: isShowChildren ? (children.length * 30 + 34) : 34}}
                        >
                            <div className="menu-main-item-show" onClick={() => showMenuChildrenClick(id)}>
                                <span className="menu-main-item-show-text" title={showTitle}>{showTitle}</span>
                                <img src={img} alt="" className="menu-main-item-show-icon" />
                            </div>
                            <ul className="menu-main-item-children">
                                {
                                    (children || []).map(({ id: _id, name, isChecked }) => (
                                        <li 
                                            className={"menu-main-item-children-item" + (isChecked ? " checked" : "")}
                                            key={_id}
                                            onClick={() => handleMenuChildrenCheckClick({ fatherId: id, childrenId: _id })}
                                        >{name}</li>
                                    ))
                                }
                            </ul>
                        </div>
                    ))
                } */}

                {
                    (list || []).map(({ id, showTitle, img, isShowChildren, children }) => (
                        <div 
                            className={"menu-main-item" + (isShowChildren ? " open" : " close")} 
                            key={id}
                            style={{height: computedHeight(isShowChildren, children)}}
                        >
                            <div className="menu-main-item-show" onClick={() => showMenuChildrenClick(id)}>
                                <span className="menu-main-item-show-text" title={showTitle}>{showTitle}</span>
                                <img src={img} alt="" className="menu-main-item-show-icon" />
                            </div>
                            <ul className="menu-main-item-children">
                                {
                                    (children || []).map(({ id: _id, name, isChecked, showTitle, img, isShowChildren, children }) => {
                                        if(children) {
                                            return (
                                                <div 
                                                    className={"menu-main-item-children-item-wrapper" + (isShowChildren ? " open" : " close")} 
                                                    style={{paddingTop: 0, height: isShowChildren ? (children.length * 30 + 34) : 34}}
                                                    key={_id}
                                                >
                                                    <div className="menu-main-item-show" onClick={() => showMenuChildrenClickLowerLevel(_id)}>
                                                        <span className="menu-main-item-show-text" title={showTitle}>{showTitle}</span>
                                                        <img src={img} alt="" className="menu-main-item-show-icon" />
                                                    </div>
                                                    <ul className="menu-main-item-children">
                                                        {
                                                            children.map(item => (
                                                                <li 
                                                                className={"menu-main-item-children-item" + (item.isChecked ? " checked" : "")}
                                                                    key={item.name}
                                                                    onClick={() => handleMenuChildrenCheckClickLowerLevel({ grandfatherId: id, fatherId: _id, childrenId: item.id })}
                                                                >{item.name}</li>
                                                            ))
                                                        }
                                                        
                                                        
                                                    </ul>
                                                </div>
                                            )
                                            
                                        }
                                        return (
                                            <li 
                                                className={"menu-main-item-children-item" + (isChecked ? " checked" : "")}
                                                key={_id}
                                                onClick={() => handleMenuChildrenCheckClick({ fatherId: id, childrenId: _id })}
                                            >{name}</li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                    ))
                }
            </div>
        </div>
    )
    
}

export default Menu;
