/*
 * @Author: your name
 * @Date: 2021-07-12 15:26:47
 * @LastEditTime: 2024-01-10 16:06:35
 * @LastEditors: chenyu chenyu@hiyanai.com
 * @Description: In User Settings Edit
 * @FilePath: \hiyan_pc_web\src\components\header\index.js
 */
import React from 'react';
import { NavLink } from 'react-router-dom';
import { splicingLogo} from '../../asstes/images';
import { navList } from '../../datas/nav/nav';
import { Menu, Dropdown } from 'antd';
import { useLocation } from "react-router";
import './index.scss';
import './index.less';
/**
 * @description: 头部导航栏
 */
function Header() {
    const { pathname } = useLocation();
    /**
     * @description: 渲染头部导航列表
     * @param {type} 
     * @return: navList
     */ 
    function renderNavList() {
        return (navList || []).map(({ id, name, path,list }) => (
                id==='2' ? 
                <Dropdown overlay={menu(list)} placement="bottomCenter" key={id}>
                    <li className="header-nav-item">
                        <div  className="header-nav-item-link">{name}</div>
                    </li>
                </Dropdown>
                :
                id==='3' ? 
                <Dropdown overlay={menu(list)} placement="bottomCenter" key={id}>
                    <li className="header-nav-item">
                        <div  className="header-nav-item-link">{name}</div>
                    </li>
                </Dropdown>
                // :
                // id==='5'?
                // <Dropdown overlay={menu(list)} placement="bottomCenter" key={id} >
                //    <li className="header-nav-item">
                //      <div  className="header-nav-item-link">{name}</div>
                //   </li>
                // </Dropdown>
                :
                <li className="header-nav-item" key={id}>
                     <NavLink to={path} activeClassName="active-nav" className="header-nav-item-link">{name}</NavLink>
                </li>
        ))
    }

    function RenderMenuList({ menuList }) {
        return (menuList || []).map(({id,name,path,index}) => (
            <Menu.Item className={[ 'header-drop-menu-item', { isCheck: pathname === path }]} key={path}>
                <NavLink to={path}>{name}</NavLink>
            </Menu.Item>
        ))
    }

    const menu = (menuList) => {
        return (
            <Menu className="header-drop-menu" >
                <RenderMenuList menuList={menuList} />
            </Menu>
        );
    };

    return (
        <div className="header">
            <div className="header-content">
                <div className="header-content-left">
                    <div className="logo-wrapper">
                        <img src={splicingLogo} alt="" className="logo-img"/>
                        {/* <span className="webName">海宴智能云平台</span> */}
                    </div>

                    <ul className="header-nav">
                        { renderNavList() }
                    </ul>
                </div>
                <div className="header-content-right">
                    {/* <Button className="login-btn">登录</Button>
                    <Button className="free-trial">免费试用</Button> */}
                    {/* <a className="login-btn" href="https://www.hiyanai.com/user/" target="_blank" rel="noopener noreferrer">登录</a>
                    <a className="free-trial" href="https://www.hiyanai.com/user/" target="_blank" rel="noopener noreferrer">免费试用</a> */}
                </div>
            </div>
        </div>
    )
}

export default Header;
