/*
 * @Author: chenyu chenyu@hiyanai.com
 * @Date: 2024-01-11 09:31:11
 * @LastEditors: chenyu chenyu@hiyanai.com
 * @LastEditTime: 2024-01-11 10:31:30
 * @FilePath: \hiyan_pc_web\src\views\products\components\products-func\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React from 'react';
import { data } from '../../../../datas/products/products-func';
/**
 * @description: 产品功能
 */
function ProductsFunc({ index }) {
    return (
        <div className="products-func">
            <div className="products-detail-content">
                <div className="products-detail-content-header">
                    <h2 className="products-detail-content-title">产品功能</h2>
                    <div className="products-detail-content-title-bottomLine"></div>
                </div>
                <div className="products-func-content-detailList">
                    {
                        (data[index].details || []).map(({ id, title, info }) => {
                            return (
                                <div className="products-func-content-item" key={id} >
                                    <p className="products-func-content-item-title">{title}</p>
                                    <p className="products-func-content-item-info">{info}</p>
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        </div>
    );
};

export default ProductsFunc;