/*
 * @Author: your name
 * @Date: 2021-10-12 15:16:02
 * @LastEditTime: 2021-10-15 09:34:56
 * @LastEditors: Please set LastEditors
 * @Description: 新闻动态
 * @FilePath: \hiyan_pc_web\src\views\home\components\news-information\index.js
 */
import React from "react";
import './index.scss';
import NewsList from "../../../../components/news-list";
import { Link } from 'react-router-dom';
import {     
    newsCenterLeft,
    newsCenterRight,
} from '../../../../asstes/images';
/**
 * @description: 新闻动态
 */
function NewsInformation() {
    return (
        <div className="news-information">
            <div className="news-information-content">
                <div className="news-information-content-title">新闻动态</div>
                <NewsList />
                <Link className="news-information-content-getmore" to={{pathname: '/intelligent/news' }}>更多新闻</Link>
                <div className="news-information-content-bg">
                    <img className="news-information-content-bgLeft" src={ newsCenterLeft } alt="" />
                    <img className="news-information-content-bgRight" src={ newsCenterRight } alt="" />
                </div>
            </div>
        </div>
    );
};

export default NewsInformation;