/**
 * @description: 图片相关的操作
 */
class Img {

    /**
     * @description: 图片转化为base64,***通过<input type="file" onChange={(event)=>{...}} /> 上传获取file文件对象
     *               let file = event.target.files[0];
     * @param {file}
     * @return: promise
     */
    getBase64ByFile(file) {
        return new Promise((resovle) => {
            const fileReader = new FileReader();
            fileReader.onload = function(e) {
                const dataUrlBase64  = e.target.result;
                resovle(dataUrlBase64);
            }
            fileReader.readAsDataURL(file);
        })
    }
    /**
     * @description: 图片转化为base64,***不通过<input type="file" onChange={(event)=>{...}} />上传获取file文件对象
     * @param {img: new Image()构造函数的实例} 
     * @return: dataURL
     */
    getBase64Image(img) {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;

        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, img.width, img.height);
        var ext = img.src.substring(img.src.lastIndexOf(".") + 1).toLowerCase();
        var dataURL = canvas.toDataURL("image/" + ext);
        return dataURL;
    }
    /**
     * @description: 图片base64转化为文件流:***注意：此方法有兼容问题,chrome等浏览器正常, ***IE edge 360可能不兼容
     * @param {dataurl, filename:文件名,和图片文件名保持一致即可} 
     * @return: 文件流
     */
    base64ToFile(dataurl, filename) {
        let arr = dataurl.split(',');
        let mime = arr[0].match(/:(.*?);/)[1];
        if (!filename) {
            filename = `${new Date().getTime()}.${mime.substr(mime.indexOf('/') + 1)}`;
        }
        let bstr = atob(arr[1]);
        let n = bstr.length;
        let u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, {
            type: mime
        });
    }
    /**
     * @description: 图片base64转化为文件流: 
     *              ***先将base转换成blob对象，再将blob对象转换成file对象。
     *              --->解决上述方法(base64ToFile)兼容问题,***IE edge 360可能不兼容的问题
     * @param {dataurl, filename:文件名,和图片文件名保持一致即可} 
     * @return: 文件流
     */
    base64ToFileByBlob(dataurl, filename) {
        return this.blobToFile(this.dataURLtoBlob(dataurl), filename)
    }
    /**
     * @description: 将base64转换为blob
     * @param {dataurl} 
     * @return: blob
     */
    dataURLtoBlob(dataurl) {
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr =atob(arr[1]),
            n = bstr.length,
            u8arr =new Uint8Array(n);
        
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new Blob([u8arr], {type: mime });
    }
    /**
     * @description: 将blob转换成file
     * @param {theBlob, fileName} 
     * @return: file
     */
    blobToFile(theBlob, fileName){
        theBlob.lastModifiedDate = new Date();
        theBlob.name = fileName;
        return theBlob;
    }
    /**
     * @description: 图片加载事件
     * @param {img:图片地址} 
     * @return: promise
     */
    imageLoad(img) {
        return new Promise((resolve) => {
            let image = new Image();
            image.crossOrigin = '';
            image.src = img;
            image.onload = function () {
                resolve(image);
            }
        })
    }
    /**
     * @description: 检测验证图片地址是否存在，能否访问
     * @param {img:图片地址} 
     * @return: promise
     */
    isImgExist(imgUrl) {
        return new Promise((resolve, reject) => {
            let imgObj = new Image();
            imgObj.src= imgUrl;
            imgObj.onload = function() {
                // console.log('图片存在');
                resolve({
                    status: true,
                    msg: '图片存在'
                })
            }
            imgObj.onerror = function() {
                // console.log('图片不存在');
                reject({
                    status: false,
                    msg: '图片不存在'
                })
            }
        })
    }
}

export {
    Img
}
