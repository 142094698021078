/*
 * @Author: your name
 * @Date: 2021-10-15 13:51:21
 * @LastEditTime: 2024-01-12 13:43:18
 * @LastEditors: chenyu chenyu@hiyanai.com
 * @Description: In User Settings Edit
 * @FilePath: \hiyan_pc_web\src\datas\home\home-banner.js
 */
import {  
    homeBanner1,
    homeBanner2,
    homeBanner3
} from '../../asstes/banners';
import {
    homeBannerText1,
    homeBannerText2,
    homeBannerText3
} from '../../asstes/images';

const data = [{
    id: 0,
    backgroundImage: homeBanner1,
    cuttingText: homeBannerText1,
    cuttingTextStyle: {
        width: '712px',
        height: '197px'
    }
},{
    id: 1,
    backgroundImage: homeBanner2,
    cuttingText: homeBannerText2,
    cuttingTextStyle: {
        width: '758px',
        height: '167px'
    }
},{
    id: 2,
    backgroundImage: homeBanner3,
    cuttingText: homeBannerText3,
    cuttingTextStyle: {
        width: '548px',
        height: '147px',
        marginLeft: '330px'
    }
}];

export { data };