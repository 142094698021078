import React, { Component } from 'react';
import './cerofhonor.scss'
import { Xinrenttzs,Yspbjzs,Rqyczs,Txyzs,Chuyingjhzs,Gaoxinqyzs,Ruanzhu } from '../../asstes/about-us'
export default class Cerofhonor extends Component {
    render(){
        return(
            <div className='cer_container'>
                <div className='item'>
                    <span>专利证书</span>
                    <div className='imgsdiv'>
                        <img src={Xinrenttzs} height="360px" alt='zs' />
                        <img src={Yspbjzs} height="360px" alt='zs' />
                        <img src={Rqyczs} height="360px" alt='zs' />
                        <img src={Txyzs} height="360px" alt='zs' />
                    </div>
                </div>
                <div className='item'>
                    <span>公司资质</span>
                    <div className='imgsdiv'>
                        <img src={Chuyingjhzs} height="398px" alt='zs' />
                        <img src={Gaoxinqyzs} height="398px" alt='zs' />
                    </div>
                </div>
                <div className='item'>
                    <span>软件著作</span>
                    <div className='imgdiv'>
                        <img src={Ruanzhu} height="1009px" alt='zs' />
                    </div>
                </div>
            </div>
        )
    }
}