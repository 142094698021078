/*
 * @Author: chenyu chenyu@hiyanai.com
 * @Date: 2024-01-09 14:28:51
 * @LastEditors: chenyu chenyu@hiyanai.com
 * @LastEditTime: 2024-01-10 17:13:37
 * @FilePath: \hiyan_pc_web\src\views\core-tech\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React from 'react';
import Banner from '../../components/banner';
import CoreTechList from './components/core-tech-list';
import CoreTechContent from './components/core-tech-content';
import './index.scss';
function CoreTech() {
    const coreTechObj = {
        id: 0,
        backgroundImage: require('../../asstes/images/core-tech-banner.png'),
        cuttingText: require('../../asstes/images/hxjsbgtext.png'),
        cuttingTextStyle: {
            width: '187px',
            height: '43px'
        },
        advantage: function () {
            const advantageList = [{
                id: 0,
                icon: require('../../asstes/images/core-tech-1.png'),
                content: '跨媒体引擎',
                describe: '跨媒体引擎主要利用人工智能，解决单媒体数据的分析和推理，构造不同媒体的统一表征方法，解决跨媒体数据的分析、推理和生成',
            }, {
                id: 1,
                icon: require('../../asstes/images/core-tech-2.png'),
                content: '公安大模型',
                describe: '依托公安大模型，城市治安事件提前预警、快速现场处置，打破各警种、各部门、各领域之间的信息壁垒，实现各职能部门消息的互联互通。',
            }, {
                id: 2,
                icon: require('../../asstes/images/core-tech-3.png'),
                content: '内容生成(AIGC)',
                describe: '打造AIGC算力中心，基于跨媒体引擎不断生成海量文本、图片、语音、短视频等内容。',
            }];
            return (
                <div className="advantage-list" >
                    {
                        (advantageList || []).map(({ id, icon, content, describe }) => {
                            return (
                                <div className="advantage-list-item" key={id}>
                                    <p className="advantage-list-item-title">{content}</p>
                                    {id % 2 !== 0 ? <div className="advantage-list-item-content">
                                        <img className="advantage-list-item-icon" src={icon} alt="" />
                                        <div className="advantage-list-item-split" />
                                        <p>{describe}</p>
                                    </div>
                                        : <div className="advantage-list-item-content">
                                            <p>{describe}</p>
                                            <div className="advantage-list-item-split" />
                                            <img className="advantage-list-item-icon" src={icon} alt="" />
                                        </div>
                                    }
                                </div>
                            );
                        })
                    }
                </div>
            );
        },
    };

    const coretech = () => {
        return (
            <div>
                <Banner height="448px" {...coreTechObj} />
                {/* <CoreTechList Advantage={coreTechObj.advantage} /> */}
                <CoreTechContent />
            </div>
        );
    };
    return (
        <div id="core-tech">
            {coretech()}
        </div>
    );
};

export default () => {
    return <CoreTech />
};