import React from 'react';
import './index.scss';
/**
 * @description: 动画扫描
 */
function Anmite(props) {
    return props.isAnmiting ? <div className="anmite-mask"><div className="anmite-wrapper"></div></div> : null
}

export default Anmite;
