/*
 * @Author: your name
 * @Date: 2021-10-11 17:47:14
 * @LastEditTime: 2021-10-14 17:46:08
 * @LastEditors: Please set LastEditors
 * @Description: 解决方案
 * @FilePath: \hiyan_pc_web\src\views\programme\index.js
 */

import React from 'react';
import {
    programmeBg1,
    programmeBg2,
    programmeBg3,
    programmeBg4,
} from '../../asstes/banners';
import { 
    group1,
    group2,
    group3,    
} from '../../asstes/images';
import Banner from '../../components/banner';
import ProgrammeDesc from './components/programme-desc';
import ProgrammeAdva from './components/programme-adva';
import ProgrammeScene from './components/programme-scene';
import { Technologies } from '../home/components/core-technology';
import './index.scss';
function Programme({ index }) {
    const programmeList = [{
        id: 0,
        backgroundImageContent: programmeBg1,
        copywriting: {
            title: '跨媒体音视频笔记平台',
            content:  <div>音视频课堂笔记为学生提供课堂内容回顾、重要知识点的巩固和检索学习服务<br />同时也为家长提供了解孩子学习情况和学情状况服务</div>
        },
        describe: '学习的重心在于校园，课堂知识是教与学的根本所在。帮助学生更好掌握课堂知识内容，让学习回归课堂，使学生学好学足课堂知识。在国家“双减”政策的指导下，通过运用AI 2.0技术，把学校课堂内容数字化，采用海宴跨媒体表征推理引擎进行课堂音视频脱敏重构，生成学生个性化的智能课堂笔记。基于互联网云服务、流媒体技术向学生提供学习辅导工具，有效减轻学生学习压力以及家长陪护辅导精力。',
        advantage: function () {
            const advantageList = [{
                id: 0,
                icon: group1,
                content: '关键信息脱敏重构',
            },{
                id: 1,
                icon: group2,
                content: '学习内容来源课堂',
            },{
                id: 2,
                icon: group3,
                content: '标准化+个性化学习',
            }];
            return (
                <div className="advantage-list" >
                    {
                        (advantageList || []).map(( { id, icon, content } ) => {
                            return (
                                <div className="advantage-list-item" key={ id }>
                                    <img className="advantage-list-item-icon" src={icon} alt="" />
                                    <p className="advantage-list-item-content">{content}</p>
                                </div>
                            );
                        })
                    }
                </div>
            );
        },
        sceneIcon: programmeBg2,
    },{
        id: 1,
        backgroundImageContent: programmeBg3,
        copywriting: {
            title: '公安大数据实战研判平台',
            content: <div>基于车流、人流、手机流等特征数据，协助公安机构<br />对涉黄赌毒案件的侦破、线索排查、人员管控以及团伙研判</div>
        },
        describe: '基于人流、车流、手机流等数据，运用海宴跨媒体表征推理引擎实现对目标人群的行程轨迹重构和特征数据汇聚碰撞，通过大数据研判推理人、车、手机等物理对象之间的关联关系，掌握重点关注人员及场所关联信息，协助办案人员完成快速侦破，为高效排查提供线索，精准管控关注人员，达到及时掌控风险的目标。',
        advantage: Technologies,
        sceneIcon: programmeBg4,
    }];

    const programmes = ( item ) => {
        const { describe, advantage, sceneIcon } = item;
        return (
            <div>
                <Banner {...item}  />
                <ProgrammeDesc describe={describe} />
                <ProgrammeAdva Advantage={advantage} />
                <ProgrammeScene sceneIcon={sceneIcon} />
            </div>
        );
    };
    return (
        <div id="programme">
            { programmes(programmeList[index]) }
        </div>
    );
};

// export default Programme;
export default ( parameter ) => {
    const index = parameter.location.pathname.split('/').slice(-1)[0];
    return <Programme index={index} />
};