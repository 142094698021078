/*
 * @Author: your name
 * @Date: 2021-10-11 17:38:58
 * @LastEditTime: 2021-10-15 15:08:02
 * @LastEditors: Please set LastEditors
 * @Description: 新闻中心
 * @FilePath: \hiyan_pc_web\src\views\news\index.js
 */
import React from 'react';
import './index.scss';
import {
    newsBanner,
    newsBannerText,
} from '../../asstes/banners';
import Banner from '../../components/banner';
import NewsList from '../../components/news-list';

function News() {
    const banner = {
        backgroundImage:newsBanner,
        cuttingText:newsBannerText,
        cuttingTextStyle: {
            width: '189px',
            height: '87px'
        }
    }
    return (
        <div id="news">
            <Banner {...banner} height="448px" />
            <div className="news-newsList">
                <NewsList />
            </div>
        </div>
    );
};

export default News;