/*
 * @Author: your name
 * @Date: 2021-10-13 16:27:04
 * @LastEditTime: 2021-10-14 09:39:14
 * @LastEditors: Please set LastEditors
 * @Description: 产品特点
 * @FilePath: \hiyan_pc_web\src\views\programme\components\programme-desc\index.js
 */

import React from "react";
/**
 * @description: 产品特点
 */
function ProgrammeDesc({ describe }) {
    return (
        <div className="programme-desc">
            <div className="programme-desc-content">
                <div className="programme-content-title">
                    <div className="programme-content-title-block"></div>
                    <p className="programme-content-title-text">功能描述</p>
                </div>
                <p className="programme-desc-content-detail">
                    {describe}
                </p>
            </div>
        </div>
    );
};

export default ProgrammeDesc;