/*
 * @Author: your name
 * @Date: 2021-10-12 10:35:57
 * @LastEditTime: 2024-01-10 14:48:41
 * @LastEditors: chenyu chenyu@hiyanai.com
 * @Description: 核心系统
 * @FilePath: \hiyan_pc_web\src\views\home\components\core-system\iindex.js
 */
import React from 'react';
import {     
    yxxtj,
    yxxyfql,
    yxjsaq,
    kxjtyj,
    hzb
} from '../../../../asstes/images';

/**
 * @description: 核心系统
 */
function CoreSystem() {
    const systemList = [{
        id: 0,
        name: [<span key="0">鹰巡-巡特警</span>],
        detail: '为实现现场数据采集、分析推理、预警推送、事件显示等系统集成功能，建成设备管理、事件预警、报警信息发布、即时推送、远程干预的综合性系统',
        background: {
            backgroundImage: "url(" + yxxtj + ")" ,
            backgroundRepeat: "no-repeat",
            backgroundSize: "100% 100%",
        },
        backgroundImage: yxxtj,
    },{
        id: 1,
        name: [<span key="0">鹰巡-校园防欺凌</span>],
        detail: '通过智能AI处理对实时语音流、视频流及红外感知分析，侦测人群语言情绪裂变，融合人群行为，触发系统前后端机制',
        background: {
            backgroundImage: "url(" + yxxyfql + ")" ,
            backgroundRepeat: "no-repeat",
            backgroundSize: "100% 100%",
        },
        backgroundImage: yxxyfql,
    },{
        id: 2,
        name: [<span key="0">鹰巡-监所安全</span>],
        detail: '海宴鹰巡系统融合视频声音和雷达，通过多模态大模型分析判断监狱看守所监仓安全可准确监视监仓安全',
        background: {
            backgroundImage: "url(" + yxjsaq + ")" ,
            backgroundRepeat: "no-repeat",
            backgroundSize: "100% 100%",
        },
        backgroundImage: yxjsaq,
    },{
        id: 3,
        name: [<span key="0">跨巡-交通预警</span>],
        detail: '实时监控动态信息，并利用边缘计算设备现场跨媒体分析违法事件，对接已有的应急广播系统，快速响应违法事件通过应急广播进行语音警示',
        background: {
            backgroundImage: "url(" + kxjtyj + ")" ,
            backgroundRepeat: "no-repeat",
            backgroundSize: "100% 100%",
        },
        backgroundImage: kxjtyj,
    },{
        id: 4,
        name: [<span key="0">焊智保</span>],
        detail: '焊智保特种作业服务平台旨在探索建立电焊作业管理新模式，引导企业通过平台选择持证电焊人员进行电焊作业服务',
        background: {
            backgroundImage: "url(" + hzb + ")" ,
            backgroundRepeat: "no-repeat",
            backgroundSize: "100% 100%",
        },
        backgroundImage: hzb,
    }];
    function systems() {
        return (systemList || []).map(item => {
            const { id, name, detail, background, backgroundImage } = item;
            return (
                <div className="systemList-item"  key={id} > {/* style={background} */}
                    <img src={backgroundImage} className="systemList-item-background" alt="" />
                    <div className="systemList-item-name">
                        <div className="systemList-item-name-block"></div>
                        <div className="systemList-item-name-text">{name}</div>
                    </div>
                    <div className="systemList-item-detail">{detail}</div>
                </div>
            );
        });
    };

    return (
        <div className='core-system-wrap'>
            <div className="core-system-wrap-content">
                <div className="core-system-wrap-content-title">主要产品</div>
                <div className="core-system">
                    <div className="core-system-content">
                        <div className="core-system-content-systemList">
                            {systems()}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

};
 export default CoreSystem;
