import React, { useState, useEffect, Fragment } from 'react';
import { datas } from '../../../../datas/technical-doc/scroll-nav';
import './index.scss';
/**
 * @description: 滚动页面跟踪导航高亮组件
 * @param {props} 
 * @return: 
 */
function ScrolllNav(props) {
  const { currentId } = props;
  const { id, title, updateTime, scrollList, navList } = (datas || []).find(item => item.id === currentId) || {};
  const [ curindex ] = useState(0);
  
  /**
   * @description: nav导航每个item点击事件,滚动到可视区之内：scrollIntoView(true)
   * @param {name} 
   * @return: 
   */
  function navItemClick(name) {
    document.getElementById(name).scrollIntoView(true);
  }
  /**
   * @description: 渲染navList(右侧导航菜单)
   * @param {} 
   * @return: 
   */
  function renderNavList() {
    return (navList || []).map(({ name, id }, index) => (
      <div
        className={"nav-item" + (curindex === index ? " active" : "")}
        data-id={id}
        key={id}
        onClick={() => navItemClick(id)}
      >
        <i className="nav-item-icon"></i>
        <span className="nav-item-text">{name}</span>
      </div>
    ))
  }
  /**
   * @description: 渲染滚动列表
   * @param {} 
   * @return: 
   */
  function renderScrollList() {
    return (scrollList || []).map(({ id, name, content }) => (
      <div className="scroll-main-content-item scroll__item" id={id} key={id}>
        <h5 className="scroll-main-content-item-title">{name}</h5>
        {
          (content || []).map(({ text }, index) => (
            <div className="scroll-main-content-item-text" key={index}>{text}</div>
          ))
        }
      </div>
    ))
  }
  /**
   * @description: 添加class类名
   * @param {dom,cls} 
   * @return: 
   */
  function addClass(dom, cls) {
    if(dom.className !== `nav-item ${cls}`) {
      dom.classList.add(cls);
    }
  }
  /**
   * @description: 移除class类名
   * @param {dom,cls} 
   * @return: 
   */
  function removeClass(obj, cls) {
    if(obj.className === `nav-item ${cls}`) {
      obj.classList.remove(cls);
    }
  }
  /**
   * @description: 获取滚动的dom容器
   * @param {} 
   * @return: 
   */
  function getScrollDom() {
    const scrollDom = document.querySelector(".scroll-main-wrapper");
    return scrollDom;
  }
  
  useEffect(() => {

    const scrollDom = getScrollDom();

    /**
     * @description: 初始化滚动方法
     * @param {} 
     * @return: 
     */
    function initScroll() {
      // 监听页面滚动事件
      if(scrollDom) {
        scrollDom.addEventListener('scroll', addEventScroll)
      }
    }
    /**
     * @description: 给滚动的dom容器添加"scroll"滚动事件
     * @param {} 
     * @return: 
     */
    function addEventScroll() {
      const scrollDom = getScrollDom();
      // let pos = document.documentElement.scrollTop;
      let pos = scrollDom.scrollTop,
      // 获取全部导航dom与元素dom
      navList = document.querySelector(".nav-content").querySelectorAll(".nav-item"),
      items = document.querySelector(".scroll-main-wrapper").querySelectorAll(".scroll__item"),
      currentId = "";
      // 滚动后遍历元素，如果页面滚动位置大于元素的位置，赋值给变量
      for (let i = 0, len = items.length; i < len; i++) {
        let _item = items[i];
        let _itemTop = _item.offsetTop;
        if (pos > _itemTop - 120) {
          currentId = _item.id;
        } else {
          break;
        }
      }
      // 如果已赋值了变量，进行匹配，如果匹配则添加class其他删除
      if (currentId) {
        for (let j = 0, len = navList.length; j < len; j++) {
          let _navItem = navList[j];
          let _navId = _navItem.getAttribute('data-id');
          if (_navId !== currentId) {
            removeClass(_navItem, "active");
          } else {
            addClass(_navItem, "active");
          }
        }
      }
    }

    initScroll();

    return () => {
      scrollDom.removeEventListener("scroll", addEventScroll);
    }
  }, [])

  return (
    <div className="scroll-nav">
      <div className="scroll-main-wrapper">
        <div className="scroll-main-title scroll__item" id={id}>
          <h3 className="scroll-title-text">{title}</h3>
          <span className="scroll-update-time">{`更新时间 ${updateTime}`}</span>
        </div>
        <Fragment>
          { renderScrollList() }
        </Fragment>
      </div>
      <div className="nav-wrapper">
        <div className="nav-content">
          { renderNavList() }
        </div>
      </div>
    </div>
  )
}

export default ScrolllNav;
