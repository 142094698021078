// const MD5 = require("./md5");

/**
 * @description: 接口MD5加密参数方法，接口需要多传两个参数timestamp和signature
 * @param {type} 
 * @return: 
 */
export const MD5Params = function () {
    let timestamp = Date.parse(new Date());
    timestamp = timestamp / 1000;
    return {
        timestamp: timestamp + '',
        // 去掉signature参数 
        // signature: MD5.hexMD5(timestamp + 'RCGTeGiH')
    }
}
/**
 * @description: 阻止默认事件,兼容IE
 * @param {e事件对象} 
 * @return: 
 */
export const stopDefault = function(e) {
    if(e && e.preventDefault) 
        // 阻止默认事件，不支持IE
        e.preventDefault();
    else
        // IE中可用window.event.returnValue = false来阻止默认事件
        window.event.returnValue = false;
}
/**
 * @description: 定义一个前面+0的方法
 * @param {str} 
 * @return: string
 */
export const padLeftZero = function(str) {
    return ('00' + str).substr(str.length)
}

/**
 * @description: 时间转化格式-->字符串
 * @param {date:时间日期date对象,fmt:字符串模板} 
 * @return: string
 */
export const formatDate = function(date, fmt) {
    if (!date) {
        return '';
    }
    if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
    }
    let o = {
        'M+': date.getMonth() + 1,
        'd+': date.getDate(),
        'h+': date.getHours(),
        'm+': date.getMinutes(),
        's+': date.getSeconds()
    };
    for (let k in o) {
        if (new RegExp(`(${k})`).test(fmt)) {
            let str = o[k] + '';
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : padLeftZero(str))
        }
    }
    return fmt;
}
