/*
 * @Author: your name
 * @Date: 2021-10-12 14:30:19
 * @LastEditTime: 2021-10-15 14:29:26
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \hiyan_pc_web\src\datas\about-us\about-us.js
 */
import { swzx, swyx, gsdz, gddh, gsry, gszz, gszs, backgroundOfContact, backgroundOfTeam, backgroundOfQuality, address, rjzz } from '../../asstes/images';

import { aboutusSurvey1920, aboutusBannerText } from '../../asstes/banners';
import { Xiedongf, Yelihua, Liyong, Geluom } from '../../asstes/about-us'

const datas = {
    aboutInfo: {
        title: '关于海宴',
        tag: '河清海晏，兆天下之升平',
        txt: '公司概况',
        backgroudImage: aboutusSurvey1920,
        bannertextImage: aboutusBannerText,
        desc: '杭州海宴科技有限公司基于跨媒体表征推理引擎为人工智能V2.0时代提供全方位解决方案。海宴科技目前专注于算法能创造极大价值的领域：智慧公安、智慧校园、智慧养老和智慧监狱等。我们向客户提供包括算法、软件和硬件产品在内的全栈式、一体化解决方案。海宴核心团队由同济大学叶利华博士担任技术总监，重庆大学黎勇博士团队负责核心算法设计，华盛顿大学黄正能教授担任首席算法科学家。公司团队成员在国内外权威期刊发表论文30余篇，获得30多项国内外知识产权、2个省部级奖项以及多次全球AI顶级赛事冠军。'
    },
    contactInfo: {
        title: '联系我们',
        tag: 'CONTACT US',
        coordinate: '120.146488,30.193156',
        addressImage: address,
        backgroudImage: backgroundOfContact,
        list: [{
            id: '1',
            icon: swzx,
            tit: '商务咨询',
            text: '19941190553'
        }, {
            id: '2',
            icon: gddh,
            tit: '固定电话',
            text: '0571-86628592'
        }, {
            id: '3',
            icon: swyx,
            tit: '商务邮箱',
            text: 'hy@hiyanai.com'
        }, {
            id: '4',
            icon: gsdz,
            tit: '公司地址',
            text: '杭州市滨江区六和路368号海创基地北三楼B12室'
        }]
    },
    teamInfo: {
        title: '我们的团队',
        tag: 'TEAM INTRODUCTION',
        backgroudImage: backgroundOfTeam,
        list: [{
            id: '1',
            name: '谢东福',
            title: '总经理/厦门大学博士',
            icon: Xiedongf,
            desc: '主研国家级项目3项，授权专利30多项，主研国家级项目3项，授权专利30多项，国家信安标委专家及密码专家，研究方向为区块链及隐私计算。'
        }, {
            id: '2',
            name: '叶利华',
            title: '研发负责人/同济大学工学博士',
            icon: Yelihua,
            desc: '主持省部级研究项目3项，企业横向项目7项;荣获省部级科技进步一等奖1项和三等奖1项，师从全国铁路运行图编图首席专家倪少权教授。'
        }, {
            id: '3',
            name: '黎勇',
            title: '首席科学家/加州大学戴维斯分校博士',
            icon: Liyong,
            desc: '主持国家自然科学基金、国家863等项目10余项;主持国家重大科技专项首席科学家。'
        }, {
            id: '4',
            name: '葛洛敏',
            title: '市场部负责人/浙江工业大学',
            icon: Geluom,
            desc: '留校工作十多年，分别在校采购中心，后勤集团，校产业集团担任主任，副总经理。后下海自主创业，2021年加盟海宴科技，具备很强的市场拓展能力。'
        },
        ]
    },
    honorInfo: {
        title: '资质荣誉',
        backgroudImage: backgroundOfQuality,
        tag: 'QUALIFICATION HONOR',
        list: [
            {
                id: 1,
                title: '专利证书',
                icon: gszs
            },
            {
                id: 2,
                title: '公司荣誉',
                desc: '海宴核心团队由同济大学叶利华博士担任技术总监，重庆大学黎勇博导团队承担算法攻关，华盛顿大学IEEE Fellow担任首席算法科学家。公司团队成员在国内外权威期刊发表论文30余篇，获得20多项国内外发明专利、多次全球AI顶级赛事冠军及多个省部级奖项。',
                icon: gsry
            },
            {
                id: 3,
                title: '公司资质',
                icon: gszz
            },
            {
                id: 4,
                title: '软件著作',
                icon: rjzz
            }
        ]
    }


}

export {
    datas
}
