/*
 * @Author: chenyu chenyu@hiyanai.com
 * @Date: 2024-01-11 09:33:20
 * @LastEditors: chenyu chenyu@hiyanai.com
 * @LastEditTime: 2024-01-11 10:14:42
 * @FilePath: \hiyan_pc_web\src\datas\products\products-func.js
 * @Description: 产品介绍-产品功能数据
 */
const data = [{
    id: 0,
    details: [{
        id: 0,
        title: '1.融合分析',
        info: `海宴鹰巡系统融合声音和视频，通过多模态大模型分析判断城市重点场所的危险等级，如公共场所下的吵架行为是否会往不可控趋势发展。`
    }, {
        id: 1,
        title: '2.智能辅助',
        info: `辅助巡特警实现城市安全问题的快速处置。鹰巡系统得到省公安厅的高度认可，已成为揭榜挂帅项目。`
    }, {
        id: 2,
        title: '3.预警推送',
        info: `系统基于音频和视频两种媒体的检测和识别算法实现预警功能，目标是在初始阶段发现人群异常，并通过自动语音喊话功能实时干预，实现早期介入处理，防止事态发展造成严重后果。`
    }, {
        id: 3,
        title: '4.事件显示',
        info: `用于部署在巡特警大队中心数据大屏上做数据展示和数据分析，为巡特警提供指挥视角，实现将警情、警力的数据形象化、直观化、具体化，提供的“一站式”的调度系统。`
    }],
}, {
    id: 1,
    details: [{
        id: 0,
        title: '1.融合分析',
        info: `海宴鹰巡系统融合视频、声音和雷达，通过多模态大模型分析判断监狱看守所监仓安全。`
    }, {
        id: 1,
        title: '2.企业平台',
        info: `可准确监视监仓人员，判断监仓人员是否发生斗殴、自杀等行为。`
    }, {
        id: 2,
        title: '3.检测告警',
        info: `语音告警，后台进行语音提醒值班人员，前端利用网络音柱对目标进行警示；弹窗告警，可在驾驶舱大屏展示弹窗。支持告警事件截图并自动弹窗生成告警信息记录，自动生成事件过程录像、备份存储，为复审快速定位；提供值班人员告警处置信息记录表。`
    }],
}, {
    id: 2,
    details: [{
        id: 0,
        title: '1.事件检测',
        info: `道路路口及隧道口违法事件检测，检测目标是行人及非机动车。隧道口行人及非机动车闯入，护栏翻越行为。`
    }, {
        id: 1,
        title: '2.联动警示',
        info: `对接应急广播系统，事件产生后回调推送事件给应急广播系统，实现低延时联动，利用边缘计算设备现场跨媒体分析违法事件，对接已有的应急广播系统，快速响应违法事件通过应急广播进行语音警示。`
    }, {
        id: 2,
        title: '3.数据分析',
        info: `路口（隧道口）节点及时间段违章统计分析，为警力分配提供科学依据，人员累计违法分析（通过人脸识别），通过大数据平台给与个人合法警示提醒。`
    }, {
        id: 3,
        title: '4.数据驾驶舱',
        info: `直观化、可视化展示违法事件相关信息。每个监控场景分析后的数据可统一汇聚到管理平台，管理平台为用户提供数据驾驶舱、数据分析、数据汇总和查询等功能模块，为制定管理决策提供一手数据。`
    }],
}, {
    id: 3,
    details: [{
        id: 0,
        title: '1.融合分析',
        info: `海宴鹰巡系统融合声音和视频，通过多模态大模型分析判断校园边缘场所是否发生吵架殴打等欺凌行为通过喇叭喊话制止，如果校园欺凌行为没有停止，保安可快速赶到现场，避免欺凌行为造成严重伤害。`
    }, {
        id: 1,
        title: '2.喊话制止',
        info: `通过喇叭喊话制止，如果校园欺凌行为没有停止，保安可快速赶到现场，避免欺凌行为造成严重伤害。`
    }, {
        id: 2,
        title: '3.告警干预',
        info: `可自定义录音存储方式，报警发生时自动采集现场环境声音，还原报警前后的真实场景。 并提供单向喊话、双向对讲、视频联动显示、远程监听等功能对告警事件进行即时判断与干预。`
    }],
}, {
    id: 4,
    details: [{
        id: 0,
        title: '1.市级、区县级平台数据驾驶舱',
        info: `可实现整个平台全局指挥、事件联动和远程控制，实现特种作业管理的集成可视化、智慧化展示，实现现场人员、特种作业过程进行全方位、全过程实时监控。`
    }, {
        id: 1,
        title: '2.企业平台',
        info: `悍智保通过电子工牌、电焊机、小程序三者联合控制并互相验证，创新性地结合智能控制盒与电子工牌，对作业全流程进行痕迹管理。`
    }, {
        id: 2,
        title: '3.全新管理模式',
        info: `焊智保特种作业服务平台旨在探索建立电焊作业管理新模式，通过企业端和个人端双向选择，政府端后台监管，引导企业通过平台选择持证电焊人员进行电焊作业服务。`
    }],
}];

export { data };