import { Http } from '../utils/http';

class FuncModel extends Http {

    /**
     * @description: 检测图片--上传方式
     * @param {imgFile} 
     * @return: 
     */
    checkPictureUpload(imgFile) {
        return this.request({
            url: '/jeecg-boot/image/demo/imageOpen',
            data: imgFile  ,
            method: 'post',
            isFile: true
        })
    }
    /**
     * @description: 检测图片--在线图片检测方式
     * @param {data} 
     * @return: 
     */
    checkPictureNetwork(data) {
        return this.request({
            url: '/jeecg-boot/image/demo/imageOpen',
            data: data  ,
            method: 'post',
            isFile: false
        })
    }
}

export {
    FuncModel
}
