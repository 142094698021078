/*
 * @Author: your name
 * @Date: 2021-10-14 14:03:15
 * @LastEditTime: 2024-01-12 11:49:53
 * @LastEditors: chenyu chenyu@hiyanai.com
 * @Description: 产品特点信息
 * @FilePath: \hiyan_pc_web\src\datas\products\products-detail.js
 */
import {     
    xtj1,
    xtj2,
    xtj3,
    xtj4, 
    xtj5,
    js1,
    js2,
    js3,
    js4,
    jtyj1,
    jtyj2,
    jtyj3,
    jtyj4,
    jtyj5,
    xy1,
    xy2,
    xy3,
    xy4,
    xy5,
    hzb1, 
    hzb2, 
    hzb3, 
    hzb4,
} from '../../asstes/images';

const data = [{
    id: 0,
    details: [{
        id: 0,
        title: '系统数据',
        icon: xtj1,
        info: `支撑警务系统下的各个系统的数据展示和应用需求。`,
        infoStyle: {
            width: '168px'
        }
    },{
        id: 1,
        title: '数据采集',
        icon: xtj2,
        info: `系统经过打通消除数据孤岛，发挥数据作用。`,
        infoStyle: {
            width: '177px'
        }
    },{
        id: 2,
        title: '数据分析系统',
        icon: xtj3,
        info: `严密有效的安全性机制能有效保证数据的安全，为真正的警务集成应用打下良好的基础。`,
        infoStyle: {
            width: '187px'
        }
    },{
        id: 3,
        title: 'GIS平台',
        icon: xtj4,
        info: `开展实时、动态的精准巡防大数据平台建设，起到快速处置的作用。`,
        infoStyle: {
            width: '173px'
        }
    },{
        id: 4,
        title: '鹰巡系统大数据驾驶舱',
        icon: xtj5,
        info: `基于GIS可视化技术，将警情、警力、社会力量上图。`,
        infoStyle: {
            width: '168px'
        }
    }],
},{
    id: 1,
    details: [{
        id: 0,
        title: '系统提供算法功能',
        icon: js1,
        info: `通过多模态大模型分析判断监仓安全`,
        infoStyle: {
            width: '225px'
        }
    },{
        id: 1,
        title: '监狱场所视频分析功能',
        icon: js2,
        info: `可准确监视监仓并判断其行为`,
        infoStyle: {
            width: '225px'
        }
    },{
        id: 2,
        title: '告警常规功能',
        icon: js3,
        info: `对告警事件进行即时判断与干预`,
        infoStyle: {
            width: '225px'
        }
    },{
        id: 3,
        title: '驾驶舱大屏展示',
        icon: js4,
        info: `全面展示监仓画面`,
        infoStyle: {
            width: '225px'
        }
    }],
},{
    id: 2,
    details: [{
        id: 0,
        title: '全局展示摄像头分布图',
        icon: jtyj1,
        info: `直观化、可视化展示违法事件相关信息`,
        infoStyle: {
            width: '154px'
        }
    },{
        id: 1,
        title: '可接入摄像头实时画面',
        icon: jtyj2,
        info: `直观化、可视化展示违法事件相关信息`,
        infoStyle: {
            width: '154px'
        }
    },{
        id: 2,
        title: '实时告警信息弹窗显示',
        icon: jtyj3,
        info: `对告警事件进行即时判断与干预`,
        infoStyle: {
            width: '168px'
        }
    },{
        id: 3,
        title: '分类告警记录信息表',
        icon: jtyj4,
        info: `为制定管理决策提供一手数据`,
        infoStyle: {
            width: '154px'
        }
    },{
        id: 4,
        title: '历史告警记录分析图表',
        icon: jtyj5,
        info: `数据积累，为决策提供依据`,
        infoStyle: {
            width: '168px'
        }
    }],
},{
    id: 3,
    details: [{
        id: 0,
        title: '先进的音频报警技术',
        icon: xy1,
        info: '采用行业领先的音频场景识别与AI语音识别技术',
        infoStyle: {
            width: '154px'
        }
    },{
        id: 1,
        title: '完善的系统功能',
        icon: xy2,
        info: '系统针对校园宿舍管理行业根据用户需求与行业发展趋势而设计',
        infoStyle: {
            width: '158px'
        }
    },{
        id: 2,
        title: '告警事件还原与及时干预能力',
        icon: xy3,
        info: '可自定义录音存储方式，报警发生时自动采集现场环境声音，还原报警前后的真实场景',
        infoStyle: {
            width: '224px'
        }
    },{
        id: 3,
        title: '国产化系统部署',
        icon: xy4,
        info: '平台可支持在鳃鹏、飞腾、兆芯等国产系统下运行,满足当前国产化适配需求',
        infoStyle: {
            width: '172px'
        }
    },{
        id: 4,
        title: '大容量系统部署能力',
        icon: xy5,
        info: '通过服务器组，系统可承载百万级以上终端部署',
        infoStyle: {
            width: '155px'
        }
    }],
},{
    id: 4,
    details: [{
        id: 0,
        title: '电焊作业安全责任明确',
        icon: hzb1,
        info: '确保持证上岗，明确落实责任'
    },{
        id: 1,
        title: '电焊作业统一监管',
        icon: hzb2,
        info: '可以更有效地管理和监管作业人员'
    },{
        id: 2,
        title: '降低企业用人成本',
        icon: hzb3,
        info: '降低用人成本，提高生产效率'
    },{
        id: 3,
        title: '提高地方经济活力',
        icon: hzb4,
        info: '为当地经济带来相当收益'
    }],
}];

export { data };