/*
 * @Author: your name
 * @Date: 2021-10-13 15:14:37
 * @LastEditTime: 2021-10-14 14:17:26
 * @LastEditors: Please set LastEditors
 * @Description: 新闻
 * @FilePath: \hiyan_pc_web\src\components\news\index.js
 */
import React from 'react';
import './index.scss';
import { data } from '../../datas/news-list';
import { useHistory } from 'react-router-dom'; // 导入 useHistory

function NewsList() {
    const history = useHistory();
    const goToDetail = (params) => {
        console.log('aaa',params);
        history.push(`/intelligent/news/detail/${params.id}`)
    }
    const News = () => {
        return (data || []).map(item => {
            const { id, title, icon, time, synopsis } = item;
            return (
                <div className="news-item" key={id} onClick={()=>{goToDetail(item)}}>
                    <img className="news-item-icon" src={icon} alt="notf" />
                    <div className="news-item-title">
                        <div className='title'>{title}</div>
                        <div className="paragraph-container">
                            <p className="custom-paragraph">
                                {synopsis}
                            </p>
                        </div>
                        <div className='new_date'>{time}</div>
                    </div>
                </div>
            );
        });
    };
    return (
        <div className="news-components">
            <div className="news-components-detail">
                <News/>
            </div>
        </div>
    );
};

export default NewsList;