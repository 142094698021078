/*
 * @Author: your name
 * @Date: 2021-07-12 15:26:47
 * @LastEditTime: 2024-01-12 11:20:23
 * @LastEditors: chenyu chenyu@hiyanai.com
 * @Description: In User Settings Edit
 * @FilePath: \hiyan_pc_web\src\components\footer\index.js
 */
import React from 'react';
import { Link } from 'react-router-dom';
import { textLogo, qrcode } from '../../asstes/images';
import Button from '../../components/button';
import { Anchor } from 'antd';
import './index.scss';


/**
 * @description: Web底部
 */
function Footer() {
    return (
        <div className="footer">
            <div className="footer-content">
                <img src={textLogo} alt="" className="logo-img" />

                <ul className="footer-content-item-wrapper">
                    <li className="footer-content-item-li">
                        <span className="footer-content-item-title">关于我们</span>
                    </li>
                    <li className="footer-content-item-li">
                        {/* <Button className="footer-content-item-text">团队介绍</Button> */}
                        {/* <Link to={{ pathname: '/hello', query: { name: 'ryan' } }}>团队介绍</Link> */}
                        <Link to="/intelligent/aboutus/team" className="footer-content-item-text">团队介绍</Link>
                    </li>
                    <li className="footer-content-item-li">
                        {/* <Button className="footer-content-item-text">资质荣誉</Button> */}
                        <Link to="/intelligent/aboutus/cerofhonor" className="footer-content-item-text">资质荣誉</Link>
                    </li>
                </ul>
                <ul className="footer-content-item-wrapper">
                    <li className="footer-content-item-li">
                        <span className="footer-content-item-title">加入我们</span>
                    </li>
                    <li className="footer-content-item-li">
                        {/* <Button className="footer-content-item-text">招聘</Button> */}
                        <Link to="/intelligent/joinus" className="footer-content-item-text">招聘</Link>
                    </li>
                    <li className="footer-content-item-li">
                        {/* <Button className="footer-content-item-text">员工福利</Button> */}
                        <Link to="/intelligent/joinus" className="footer-content-item-text">员工福利</Link>
                    </li>
                </ul>
                <ul className="footer-content-item-wrapper contactUs">
                    <li className="footer-content-item-li">
                        <span className="footer-content-item-title">联系我们</span>
                    </li>
                    <li className="footer-content-item-li">
                        {/* <Button className="footer-content-item-text">商务咨询：0571-86628592</Button> */}
                        {/* <Button className="footer-content-item-text">商务咨询电话：15888830111</Button> */}
                    </li>
                    <li className="footer-content-item-li">
                        <Button className="footer-content-item-text">商务邮箱：business@hiyanai.com</Button>
                    </li>
                </ul>
                <ul className="footer-content-item-wrapper">
                    <li className="footer-content-item-li">
                        <span className="footer-content-item-title">公司地址</span>
                    </li>
                    <li className="footer-content-item-li">
                        <span className="footer-content-item-address">杭州市滨江区六和路368号<br />海创基地北三楼B12室</span>
                    </li>
                </ul>

                <div className='footer-qrcode-wrap'>
                    <img src={qrcode} alt="" className="footer-logo-qrcode" />
                    <span className='footer-qrcode-text'>微信公众号</span>
                </div>
                {/* <ul className="footer-content-item-wrapper">
                    <li className="footer-content-item-li">
                        <Button className="footer-content-item-text">开发资源</Button>
                    </li>
                    <li className="footer-content-item-li">
                        <a className="footer-content-item-text" href="https://www.hiyanai.com/#/intelligent/technicaldoc" target="_blank" rel="noopener noreferrer">新手指南</a>
                    </li>
                    <li className="footer-content-item-li">
                        <a className="footer-content-item-text" href="https://www.hiyanai.com/#/intelligent/technicaldoc" target="_blank" rel="noopener noreferrer">技术指南</a>
                    </li>
                    <li className="footer-content-item-li">
                        <Button className="footer-content-item-text">SDK</Button>
                    </li>
                </ul>
                <ul className="footer-content-item-wrapper">
                    <li className="footer-content-item-li">
                        <a className="footer-content-item-text" href="https://www.hiyanai.com/#/intelligent/aboutus" target="_blank" rel="noopener noreferrer">联系我们</a>
                    </li>
                    <li className="footer-content-item-li">
                        <Button className="footer-content-item-text">商务咨询: 0571-86628592</Button>
                    </li>
                    <li className="footer-content-item-li">
                        <Button className="footer-content-item-text">商务邮箱: business@hiyanai.com</Button>
                    </li>
                    <li className="footer-content-item-li">
                        <Button className="footer-content-item-text">
                            公司地址: 杭州市滨江区六和路368号 <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <span className="footer-content-item-text-dder">海创基地北三楼B12室</span>
                        </Button>
                    </li>
                </ul>
                <ul className="footer-content-item-wrapper">
                    <li className="footer-content-item-li">
                        <a className="footer-content-item-text" href="https://www.hiyanai.com/#/intelligent/aboutus" target="_blank" rel="noopener noreferrer">联系我们</a>
                    </li>
                    <li className="footer-content-item-li">
                        <Button className="footer-content-item-text">商务咨询: 0571-86628592</Button>
                    </li>
                    <li className="footer-content-item-li">
                        <Button className="footer-content-item-text">商务邮箱: business@hiyanai.com</Button>
                    </li>
                    <li className="footer-content-item-li">
                        <Button className="footer-content-item-text">
                            公司地址: 杭州市滨江区六和路368号 <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <span className="footer-content-item-text-dder">海创基地北三楼B12室</span>
                        </Button>
                    </li>
                </ul> */}
            </div>

            {/* <div style={{width: '300px', margin: '0 auto', padding:'20px 0'}}>
		 		<a target="_blank" rel="noopener noreferrer" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010802010947" style={{display:'inline-block',textDecoration:'none',height:'20px',lineHeight:'20px'}}>
                    <img src={require('../../asstes/images/beian.png')} style={{float:'left'}} alt="" />
                    <p className="beian-wrapper" style={{float:'left',height:'20px',lineHeight:'20px',margin: '0px 0px 0px 5px',color:'#939393'}}>浙公网安备 33010802010947号</p>
                </a>
		 	</div> */}

        </div>
    )
}

export default Footer;
