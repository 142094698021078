import React, { Component } from 'react';
import { Route, Switch, NavLink, Redirect, withRouter } from 'react-router-dom';
import { useLocation } from "react-router";
import { Menu } from 'antd';
import Banner from '../../components/banner';
import { datas } from '../../datas/about-us/about-us';
import './index.scss';
import Briintro from './briintro'
import Team from './team'
import Evolution from './evolution'
import Cerofhonor from './cerofhonor'
export default class AboutUs extends Component {
    // app 初始化
    state = {
        menuList: [
            {
                id: 1,
                name: '公司简介',
                path: '/intelligent/aboutus/briintro',
            },
            {
                id: 2,
                name: '技术团队',
                path: '/intelligent/aboutus/team',
            },
            {
                id: 3,
                name: '技术沿革',
                path: '/intelligent/aboutus/evolution',
            },
            {
                id: 4,
                name: '资质荣誉',
                path: '/intelligent/aboutus/cerofhonor',
            },
        ],
        aboutInfo: datas.aboutInfo,
    }
    // 方法
    RenderMenuList() {
        const { menuList } = this.state
        // console.log(this.props.location.pathname);
        return (menuList || []).map(({ id, name, path, index }) => (
            <Menu.Item className={['header-menu-item', { 'ant-menu-item-selected': this.props.location.pathname === path }]} key={path}>
                {/* <Menu.Item className={[ 'header-menu-item']}  key={path}> */}
                <NavLink to={path}>{name}</NavLink>
            </Menu.Item>
        ))
    }
    // 渲染
    render() {
        const { backgroudImage, bannertextImage } = this.state.aboutInfo;
        const banner = {
            backgroundImage: backgroudImage,
            cuttingText: bannertextImage,
            cuttingTextStyle: {
                width: '264px',
                height: '87px'
            }
        }
        return (
            <div className='about_container'>
                <Banner {...banner} />
                <div className='main_con'>
                    <div className='about_con_header'>
                        <Menu className="header-menu" mode="horizontal">
                            {this.RenderMenuList()}
                        </Menu>
                    </div>
                    <div className='about_main'>
                        <Switch>
                            <Route exact path="/intelligent/aboutus/briintro" component={Briintro} />
                            <Route exact path="/intelligent/aboutus/team" component={Team} />
                            <Route exact path="/intelligent/aboutus/evolution" component={Evolution} />
                            <Route exact path="/intelligent/aboutus/cerofhonor" component={Cerofhonor} />
                            <Redirect exact from="/intelligent/aboutus" to="/intelligent/aboutus/briintro" />
                        </Switch>
                    </div>
                </div>
            </div>
        )
    }
}


