/*
 * @Author: chenyu chenyu@hiyanai.com
 * @Date: 2024-01-11 10:44:21
 * @LastEditors: chenyu chenyu@hiyanai.com
 * @LastEditTime: 2024-01-11 11:44:44
 * @FilePath: \hiyan_pc_web\src\views\products\components\application-scenarios\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React from 'react';
import { datas } from '../../../../datas/products/application-scenarios';
import { Carousel } from 'antd';
/**
 * @description: 应用场景
 */
function ApplicationScenarios({ index }) {
    const products = (datas[index].list || []).map(({id, img}) => {
        return (
            <div className="application-scenarios-banner" key={id} >
                <img src={img} className="banner-image" />
            </div>
        );
    });

    return (
        <div className="application-scenarios">
            <div className="products-detail-content">
                <div className="products-detail-content-header">
                    <h2 className="products-detail-content-title">应用场景</h2>
                    <div className="products-detail-content-title-bottomLine"></div>
                </div>
                <div className="application-scenarios-content">
                    <Carousel autoplay autoplaySpeed={3000}>
                        {products}
                    </Carousel>
                </div>
            </div>
        </div>
    );
};

export default ApplicationScenarios;