/*
 * @Author: chenyu chenyu@hiyanai.com
 * @Date: 2024-01-09 15:34:27
 * @LastEditors: chenyu chenyu@hiyanai.com
 * @LastEditTime: 2024-01-10 14:06:19
 * @FilePath: \hiyan_pc_web\src\views\home\components\corporate-culture\index.js
 * @Description: 首页-企业文化
 */
import React from 'react';
import {
    mission,
    vision
} from '../../../../asstes/images';
/**
 * @description: 企业文化
 */
const cultureList = [{
    id: 0,
    name: '使命',
    content: '满足客户需求',
    icon: mission,
}, {
    id: 1,
    name: '愿景',
    content: '成为全球跨媒体分析领军企业',
    icon: vision,
}];
function Cultural() {
    return (
        <div className="corporate-culture-content-cultureList">
            {(cultureList || []).map(item => {
                const { id, name, content, icon } = item;
                return (
                    <div className="cultureList-item" key={id}>
                        <img src={icon} className="cultureList-item-icon" alt="" />
                        <div className='cultureList-item-right'>
                            <div className="cultureList-item-right-name">{name}</div>
                            <div className="cultureList-item-right-content">{content}</div>
                        </div>
                    </div>
                );
            })
            }
        </div>
    );
};
function CorporateCulture() {
    return (
        <div className="corporate-culture">
            <div className="core-technology-content">
                <div className="core-technology-content-title">企业文化</div>
                <Cultural />
            </div>
        </div>
    );

};

export { CorporateCulture, Cultural };
