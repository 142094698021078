/*
 * @Author: your name
 * @Date: 2021-10-13 17:21:36
 * @LastEditTime: 2021-10-14 09:08:52
 * @LastEditors: Please set LastEditors
 * @Description: 核心优势
 * @FilePath: \hiyan_pc_web\src\views\programme\components\programme-adva\index.js
 */

import React from "react";
/**
 * @description: 核心优势
 */

function ProgrammeAdva({Advantage}) {

    return (
        <div className="programme-adva">
            <div className="programme-adva-content">
                <div className="programme-content-title">
                    <div className="programme-content-title-block"></div>
                    <p className="programme-content-title-text">核心优势</p>
                </div>
                <div className="programme-adva-content-detail">
                    <Advantage />
                </div>
            </div>
        </div>
    );
};
export default ProgrammeAdva;
