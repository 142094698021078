/*
 * @Author: your name
 * @Date: 2021-10-11 18:25:56
 * @LastEditTime: 2024-01-12 14:35:29
 * @LastEditors: chenyu chenyu@hiyanai.com
 * @Description: In User Settings Edit
 * @FilePath: \hiyan_pc_web\src\components\banner\index.js
 */
import React from 'react';
import './index.scss';

/**
 * @param {*} backgroundImage: Banner组件全局1920px的背景图片;
 * @param {*} position: backgroundImage全局的背景图片的;
 * @param {*} backgroundImageContent: Banner组件中间1440px的背景图片;
 * @param {*} cuttingText: Banner文字切片;
 * @param {*} cuttingTextStyle: Banner文字切片格式;
 * @param {*} copywriting: Banner文字 Object: { title : '公安大数据实战研判平台', content : <div>基于车流、人流、手机流等特征数据，协助公安机构<br />对涉黄赌毒案件的侦破、线索排查、人员管控以及团伙研判</div> }
 * @param {*} height: 设定 backgroundImage 或 backgroundImageContent 图像高度;
 * @return {*} Banner
 */

function Banner({ backgroundImage, position = 'bottom', backgroundImageContent, cuttingText, cuttingTextStyle, copywriting, height = '462px', }) {
    const bannerStyle = {
        backgroundImage: "url(" + backgroundImage + ")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 125%",
        backgroundAttachment: 'absolute',
        backgroundPosition: position === 'bottom' ? '0% 100%' : '0% 50%',
    };
    const bannerContentStyle = {
        backgroundImage: "url(" + backgroundImageContent + ")" ,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
    };
    // eg:
    // copywriting: {
    //     title : '公安大数据实战研判平台';
    //     content : <div>基于车流、人流、手机流等特征数据，协助公安机构<br />对涉黄赌毒案件的侦破、线索排查、人员管控以及团伙研判</div>
    // }
    return (
        <div id="banner" style={ bannerStyle }>
            <div className="banner-content-bg" style={ { ...bannerContentStyle, height } } >
                <div className="banner-content" >
                    {
                        cuttingText ?
                            <div>
                                <img className="banner-content-cuttingText" style={cuttingTextStyle} src={ cuttingText } alt="" />
                                {copywriting ? <div className="banner-text" style={{fontSize: '18px'}}>{copywriting.content}</div> : ''}
                            </div>
                        :
                        <div>
                            <div className="banner-title">{copywriting.title}</div>
                            <div className="banner-text">{copywriting.content}</div>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default Banner;