import React from 'react';
import Table from '../../components/table';
import { formatDate } from '../../utils';
/**
 * @description: 获取当前时间
 * @param  
 * @return: string
 */
const _time = formatDate(new Date(), 'MM-dd');

const datas = [{
    id: '1-0',
    title: '规范说明',
    updateTime: _time,
    scrollList: [{
        id: '1-0-1',
        name: '通信协议',
        content: [{
            text: 'HTTPS 协议。'
        }]
    },{
        id: '1-0-2',
        name: '请求方法',
        content: [{
            text: '所有请求均只支持POST方式。'
        },]
    },{
        id: '1-0-3',
        name: '字符编码',
        content: [{
            text: '所有接口的请求和响应数据编码皆为 utf-8。'
        }]
    },{
        id: '1-0-4',
        name: 'API请求结构',
        content: [{
            text: '所有HTTP请求中，都包含如下公共请求头（HTTP Header）：'
        },{
            text: <Table 
            headList={['名称','类型','备注']}
            dataSource={[{
                name: 'API入口'
            },{
                name: '具体API接口地址'
            },{
                name: 'https://api.hiyanai.com/vx/xx/check'
            },{
                name: '公共参数'
            },{
                name: '每个接口都包含的通用参数'
            },{
                name: '详见 公共参数 说明'
            },{
                name: '私有参数'
            },{
                name: '每个接口特头的参数'
            },{
                name: '详细参考每个API接口定义'
            }]}
            />
        }]
    },{
        id: '1-0-5',
        name: '公共参数',
        content: [{
            text: '公共参数是用于标识产品和接口鉴权目的的参数，如非必要，在每个接口单独的接口文档中不再对这些参数进行说明，每次请求均需要携带这些参数。'
        }]
    },{
        id: '1-0-6',
        name: '请求公共参数',
        content: [{
            text: <Table 
            headList={['参数名称','类型','是否必须','最大长度','描述']}
            dataSource={[{
                name: 'secretId'
            },{
                name: 'String'
            },{
                name: 'Y'
            },{
                name: '32'
            },{
                name: '产品秘钥 id ，由明辨内容安全服务分配，产品标识'
            },{
                name: 'businessId'
            },{
                name: 'String'
            },{
                name: 'Y'
            },{
                name: '32'
            },{
                name: '业务id ，由明辨内容安全服务分配，业务标识'
            },{
                name: 'timestamp'
            },{
                name: 'Number'
            },{
                name: 'Y'
            },{
                name: '13'
            },{
                name: '请求当前 UNIX 时间戳，请注意服务器时间是否同步'
            },{
                name: 'nonce'
            },{
                name: 'Number'
            },{
                name: 'Y'
            },{
                name: '11'
            },{
                name: '随机整数，与 timestamp 联合起来，用于防止重放攻击'
            },{
                name: 'signature'
            },{
                name: 'String'
            },{
                name: 'Y'
            },{
                name: '32'
            },{
                name: '请求签名，用来验证此次请求的合法性，具体算法见 接口鉴权'
            }]}
            />
        }]
    },{
        id: '1-0-7',
        name: '响应通用字段',
        content: [{
            text: '所有接口响应值采用 json 格式， 如无特殊说明，每次请求的返回值中，都包含下面字段：'
        },{
            text: <Table 
            headList={['参数名称','类型','描述']}
            dataSource={[{
                name: 'code'
            },{
                name: 'Number'
            },{
                name: '接口调用状态，200:正常，其他值：调用出错，返回码见 响应返回码'
            },{
                name: 'msg'
            },{
                name: 'String'
            },{
                name: '结果说明，如果接口调用出错，那么返回错误描述，成功返回 ok'
            },{
                name: 'result'
            },{
                name: 'json'
            },{
                name: '接口返回结果，各个接口自定义，若为空或null标识返回正常'
            }]}
            />
        }]
    },{
        id: '1-0-8',
        name: '响应返回码',
        content: [{
            text: '响应返回码（code）反应了明辨内容安全服务 API 调用和执行的概要结果。当返回码不为 200 时， 表示请求未正常执行，返回码描述 (msg) 对该结果进行了细化补充，用户可根据返回码判断 API 的执行情况。所有接口调用返回值均包含 code 和 msg 字段， code 为返回码值，msg 为返回码描述信息，返回码表如下：'
        },{
            text: <Table 
            headList={['返回码','说明']}
            dataSource={[{
                name: '200'
            },{
                name: '接口调用成功'
            },{
                name: '400'
            },{
                name: '请求缺少 secretId 或 businessId'
            },{
                name: '401'
            },{
                name: 'businessId无效或过期'
            },{
                name: '405'
            },{
                name: '请求参数异常，请检查参数是否有缺失，参数是否正确'
            },{
                name: '410'
            },{
                name: '签名验证失败，目前请求参数仅支持form表单形式，建议content-type设置为 application/x-www-form-urlencoded 编码格式'
            },{
                name: '411'
            },{
                name: '请求频率超过限制'
            },{
                name: '413'
            },{
                name: '试用期请求数据总量超过限制'
            },{
                name: '414'
            },{
                name: '请求长度/大小超过限制'
            },{
                name: '415'
            },{
                name: '业务版本限制'
            },{
                name: '420'
            },{
                name: '请求过期'
            },{
                name: '421'
            },{
                name: 'token过期'
            },{
                name: '422'
            },{
                name: 'token无效'
            },{
                name: '430'
            },{
                name: '重放攻击'
            },{
                name: '503'
            },{
                name: 'servicelist unavailable 接口异常'
            }]}
            />
        }]
    }],
    navList: [{
        name: "规范说明", 
        id: "1-0" 
    },
    {
        name: "通信协议", 
        id: "1-0-1"
    },
    {   
        name: "请求方法", 
        id: "1-0-2" 
    },
    { 
        name: "字符编码", 
        id: "1-0-3" 
    },{
        name: 'API请求结构',
        id: "1-0-4" 
    },{ 
        name: "公共参数", 
        id: "1-0-5" 
    },{ 
        name: "请求公共参数", 
        id: "1-0-6" 
    },{ 
        name: "响应通用字段", 
        id: "1-0-7" 
    },{
        name: '响应返回码',
        id: "1-0-8" 
    }]
},

{
    id: '1-0--1',
    title: '接口鉴权',
    updateTime: _time,
    scrollList: [{
        id: '1-0--1-1',
        name: '鉴权说明',
        content: [{
            text: '海宴明辨系统使用签名方法对接口进行鉴权，所有接口每一次请求都需要包含签名信息（signature 参数），以验证用户身份，防止信息被恶意篡改。'
        }]
    },{
        id: '1-0--1-2',
        name: '申请安全凭证',
        content: [{
            text: '在第一次使用 API 之前，需申请安全凭证，安全凭证包括 SecretId 和 SecretKey ，SecretId 是用于标识 API 调用者的身份，SecretKey 是用于加密签名字符串和服务器端验证签名字符串的密钥。SecretKey 必须严格保管，避免泄露。'
        }]
    },{
        id: '1-0--1-3',
        name: '签名生产算法',
        content: [{
            text: '签名生成方法如下：'
        },{
            text: '1. 对所有请求参数（包括公有参数和私有参数，但不包括 signature 参数），按照参数名ASCII码表升序顺序排序。如：foo=1， bar=2， foo_bar=3， baz=4 排序后的顺序是 bar=2， baz=4， foo=1， foobar=3。'
        },{
            text: '2. 将排序好的参数名和参数值构造成字符串，格式为：key1+value1+key2+value2… 。根据上面的示例得到的构造结果为：bar2baz4foo1foobar3 。'
        },{
            text: '3. 选择与 secretId 配对的 secretKey ，加到上一步构造好的参数字符串之后，如 secretKey=6308afb129ea00301bd7c79621d07591 ，则最后的参数字符串为 bar2baz4foo1foobar36308afb129ea00301bd7c79621d07591。'
        },{
            text: '4. 把c步骤拼装好的字符串采用 utf-8 编码，使用 MD5 算法对字符串进行摘要，计算得到 signature 参数值，将其加入到接口请求参数中即可。MD5 是128位长度的摘要算法，用16进制表示，一个十六进制的字符能表示4个位，所以签名后的字符串长度固定为32位十六进制字符。'
        },{
            text: '示例代码'
        },{
            text: 'Java：'
        },{
            text: <img src={require("../images/pic7.png")} alt="" />
        },{
            text: 'Python: '
        },{
            text: <img src={require("../images/pic8.png")} alt="" />
        },{
            text: 'PHP: '
        },{
            text: <img src={require("../images/pic9.png")} alt="" />
        },{
            text: 'C#: '
        },{
            text: <img src={require("../images/pic10.png")} alt="" />
        }]
    }],
    navList: [{
        name: "接口鉴权", 
        id: "1-0--1" 
    },
    {
        name: "鉴权说明", 
        id: "1-0--1-1" 
    },
    {
        name: "申请安全凭证", 
        id: "1-0--1-2"
    },
    {   
        name: "签名生产算法", 
        id: "1-0--1-3" 
    }]
},

{
    id: '1-1',
    title: '图片检测接口',
    updateTime: _time,
    scrollList: [{
        id: '1-1-1',
        name: '接口地址',
        content: [{
            text: 'https://api.hiyanai.com/v4/image/check'
        }]
    },{
        id: '1-1-2',
        name: '接口描述',
        content: [{
            text: '该接口同步返回内容安全服务实时反垃圾引擎检测结果，产品根据图片分类结果，做图片初步过滤。由于网络环境及图片本身大小的影响（建议产品对图片进行压缩后，再过反垃圾检测），部分图片可能出现下载超时情况，该部分数据会返回检测失败，请重新检测。'
        },{
            text: <img src={require("../images/pic1.png")} alt="" />
        }]
    },{
        id: '1-1-3',
        name: '请求参数',
        content: [{
            text: '公共参数已省略，详细见 请求公共参数'
        },{
            text: '基本参数'
        },{
            text: <Table 
            headList={['参数名称','类型','是否必填','最大长度','描述']}
            dataSource={[{
                name: 'images'
            },{
                name: 'String(json数组)'
            },{
                name: 'Y'
            },{
                name: '32张或10MB'
            },{
                name: 'images为json数组，支持批量检测（暂时只支持一张）'
            },{
                name: 'version'
            },{
                name: 'String'
            },{
                name: 'Y'
            },{
                name: '4'
            },{
                name: '接口版本v4'
            }]}
            />
        },{
            text: 'images参数结构说明'
        },{
            text: <Table 
            headList={['参数名称','类型','是否必填','最大长度','描述']}
            dataSource={[{
                name: 'name'
            },{
                name: 'String'
            },{
                name: 'Y'
            },{
                name: '1024'
            },{
                name: 'images为json数组，支持批量检测（暂时只支持一张）'
            },{
                name: 'type'
            },{
                name: 'Number'
            },{
                name: 'Y'
            },{
                name: '4'
            },{
                name: '接口版本v4'
            },{
                name: 'data'
            },{
                name: 'String'
            },{
                name: 'Y'
            },{
                name: '合计最大10M'
            },{
                name: 'type=1 ,该值为图片url ；type=2 该值为图片base64'
            },{
                name: 'callbackUrl'
            },{
                name: 'String'
            },{
                name: 'N'
            },{
                name: '256'
            },{
                name: '回调地址，主动通知客户检测结果，针对异步'
            },{
                name: 'callback'
            },{
                name: 'String'
            },{
                name: 'N'
            },{
                name: '1024'
            },{
                name: '客户自定义内容，回调的时候会返回，针对异步'
            },{
                name: 'isCall'
            },{
                name: 'Number'
            },{
                name: 'Y'
            },{
                name: '4'
            },{
                name: '非违规结果是否需要回调通知1.需要 2.不需要，针对异步'
            }]}
            />
        }]
    },{
        id: '1-1-4',
        name: '响应结果',
        content: [{
            text: '响应字段如下，响应通用字段已省略，详细见 响应通用字段 ：'
        },{
            text: '反垃圾结果'
        },{
            text: 'antispam 数组数据结构'
        },{
            text: <Table 
            headList={['参数名称','类型','描述']}
            dataSource={[{
                name: 'name'
            },{
                name: 'String'
            },{
                name: '图片名称(或图片标识)'
            },{
                name: 'taskId'
            },{
                name: 'String'
            },{
                name: '本次请求数据标识，可以根据该标识查询数据最新结果'
            },{
                name: 'status'
            },{
                name: 'Number'
            },{
                name: '图片检测状态码，定义为：0：检测成功'
            },{
                name: 'action'
            },{
                name: 'String'
            },{
                name: '建议动作，2：建议删除，1：建议审核，0：建议通过'
            },{
                name: 'censorType'
            },{
                name: 'Number'
            },{
                name: '审核模式，0：纯机审，1：机审+部分人审，2：机审+全量人审'
            },{
                name: 'labels'
            },{
                name: 'json数组'
            },{
                name: '分类信息'
            },{
                name: 'callback'
            },{
                name: 'String'
            },{
                name: '请求中客户传的内容'
            },{
                name: 'imgBase64'
            },{
                name: 'String'
            },{
                name: '结果为正常的时候为空，原始图片base64'
            }]}
            />
        },{
            text: 'Labels 数据结构'
        },{
            text: <Table 
            headList={['参数名称','类型','描述']}
            dataSource={[{
                name: 'label'
            },{
                name: 'Number'
            },{
                name: '分类信息，100：色情，110：性感低俗，200：广告，210：二维码，300：暴恐，400：违禁，500：涉政，900：其他'
            },{
                name: 'subLabels'
            },{
                name: 'json数组'
            },{
                name: '细分类信息,可能包含多个，可能为空'
            },{
                name: 'level'
            },{
                name: 'Number'
            },{
                name: '分类级别，0：正常，1：不确定，2：确定'
            },{
                name: 'rate'
            },{
                name: 'Number'
            },{
                name: '置信度分数，0-1之间取值，1为置信度最高，0为置信度最低。若level为正常，置信度越大，说明正常的可能性越高。若level为不确定或确定，置信度越大，说明垃圾的可能性越高'
            }]}
            />
        },{
            text: 'subLabels 数据结构'
        },{
            text: <Table 
            headList={['参数名称','类型','描述']}
            dataSource={[{
                name: 'subLabel'
            },{
                name: 'Number'
            },{
                name: '细分类，详细编码请参考下方对应细分类编码对照表'
            },{
                name: 'rate'
            },{
                name: 'Number'
            },{
                name: '置信度分数，0-1之间取值，1为置信度最高，0为置信度最低'
            },{
                name: 'details'
            },{
                name: 'json数组'
            },{
                name: 'hitInfos:命中详细信息，针对subLabel的补充说明'
            }]}
            />
        },{
            text: 'subLabel细分类编码对应表'
        },{
            text: <Table 
            headList={['返回编码','描述','hitInfos']}
            dataSource={[{
                name: '10000'
            },{
                name: '色情'
            },{
                name: '若命中图片中文字检测策略，可返回文字线索信息'
            },{
                name: '10001'
            },{
                name: '女下体'
            },{
                name: ''
            },{
                name: '10002'
            },{
                name: '女胸'
            },{
                name: ''
            },{
                name: '10003'
            },{
                name: '男下体'
            },{
                name: ''
            },{
                name: '10004'
            },{
                name: '性行为'
            },{
                name: ''
            },{
                name: '10005'
            },{
                name: '臀部'
            },{
                name: ''
            },{
                name: '10006'
            },{
                name: '口交'
            },{
                name: ''
            },{
                name: '10007'
            },{
                name: '卡通色情'
            },{
                name: ''
            },{
                name: '10008'
            },{
                name: '色情人物'
            },{
                name: '人物名'
            },{
                name: '10009'
            },{
                name: '儿童色情'
            },{
                name: ''
            },{
                name: '11000'
            },{
                name: '性感低俗'
            },{
                name: ''
            },{
                name: '11001'
            },{
                name: '亲吻'
            },{
                name: ''
            },{
                name: '11002'
            },{
                name: '腿部特写'
            },{
                name: ''
            },{
                name: '11003'
            },{
                name: '赤膊'
            },{
                name: ''
            },{
                name: '20000'
            },{
                name: '广告'
            },{
                name: '若命中图片中文字检测策略，可返回文字线索信息'
            },{
                name: '21000'
            },{
                name: '二维码'
            },{
                name: '若命中图片中文字检测策略，可返回文字线索信息'
            },{
                name: '30000'
            },{
                name: '暴恐'
            },{
                name: '若命中图片中文字检测策略，可返回文字线索信息'
            },{
                name: '30001'
            },{
                name: '暴恐图集'
            },{
                name: ''
            },{
                name: '30002'
            },{
                name: '暴恐旗帜'
            },{
                name: '旗帜名'
            },{
                name: '30003'
            },{
                name: '暴恐人物'
            },{
                name: '人物名'
            },{
                name: '30004'
            },{
                name: '暴恐标识'
            },{
                name: '标识名'
            },{
                name: '30005'
            },{
                name: '暴恐场景'
            },{
                name: '场景名'
            },{
                name: '40000'
            },{
                name: '违禁'
            },{
                name: '若命中图片中文字检测策略，可返回文字线索信息'
            },{
                name: '40001'
            },{
                name: '违禁图集'
            },{
                name: ''
            },{
                name: '40002'
            },{
                name: '违禁品'
            },{
                name: '违禁品名'
            },{
                name: '40003'
            },{
                name: '特殊标识'
            },{
                name: '标识名'
            },{
                name: '40004'
            },{
                name: '血腥模型'
            },{
                name: ''
            },{
                name: '40005'
            },{
                name: '公职服饰'
            },{
                name: '服饰名'
            },{
                name: '40006'
            },{
                name: '不文明'
            },{
                name: '不文明行为'
            },{
                name: '40007'
            },{
                name: '违禁人物'
            },{
                name: '人物名'
            },{
                name: '40008'
            },{
                name: '违禁场景'
            },{
                name: '场景名'
            },{
                name: '40009'
            },{
                name: '火焰'
            },{
                name: ''
            },{
                name: '40010'
            },{
                name: '骷髅'
            },{
                name: ''
            },{
                name: '40011'
            },{
                name: '货币'
            },{
                name: ''
            },{
                name: '40012'
            },{
                name: '毒品'
            },{
                name: ''
            },{
                name: '50000'
            },{
                name: '涉政'
            },{
                name: '若命中图片中文字检测策略，可返回文字线索信息'
            },{
                name: '50001'
            },{
                name: '涉政图集'
            },{
                name: ''
            },{
                name: '50002'
            },{
                name: '中国地图'
            },{
                name: ''
            },{
                name: '50003'
            },{
                name: '涉政人物'
            },{
                name: '人物名'
            },{
                name: '50004'
            },{
                name: '涉政旗帜'
            },{
                name: '旗帜名'
            },{
                name: '50005'
            },{
                name: '涉政标识'
            },{
                name: '标识名'
            },{
                name: '50006'
            },{
                name: '涉政场景'
            },{
                name: '场景名'
            },{
                name: '90000'
            },{
                name: '其他'
            },{
                name: ''
            },{
                name: '90002'
            },{
                name: '自定义用户名单'
            },{
                name: '名单列表'
            },{
                name: '90003'
            },{
                name: '自定义IP名单'
            },{
                name: '名单列表'
            }]}
            />
        }]
    }],
    navList: [{
        name: "图片检测接口", 
        id: "1-1" 
    },
    {
        name: "接口地址", 
        id: "1-1-1"
    },
    {   
        name: "接口描述", 
        id: "1-1-2" 
    },
    {  
        name: "请求参数", 
        id: "1-1-3"
    },
    { 
        name: "响应结果", 
        id: "1-1-4" 
    }],
},{
    id: '1-2',
    title: '视频点播信息提交',
    updateTime: _time,
    scrollList: [{
        id: '1-2-1',
        name: '接口地址',
        content: [{
            text: 'https://api.hiyanai.com/v3/video/submit'
        },]
    },{
        id: '1-2-2',
        name: '接口描述',
        content: [{
            text: '提交点播视频相关信息接口，信息提交后，明辨会根据客户自己设置的截图频率对点播视频进行截图检测，检测结果需产品自行定期调用视频结果获取接口获取或者通过接受异步回调获取检测结果。'
        },{
            text: <img src={require("../images/pic2.png")} alt="" />
        }]
    },{
        id: '1-2-3',
        name: '支持格式',
        content: [{
            text: '检测接口支持的视频封装格式：flv、mkv 、mp4 、rmvb 、avi 、wmv、3gp、ts、mov等。'
        },]
    },{
        id: '1-2-4',
        name: '大小限制',
        content: [{
            text: '视频大小限制：<5G。'
        }]
    },{
        id: '1-2-5',
        name: '请求参数',
        content: [{
            text: '公共参数已省略，详细见 请求公共参数'
        },{
            text: '基本参数'
        },{
            text: <Table 
            headList={['参数名称','类型','是否必须','最大长度','描述']}
            dataSource={[{
                name: 'url'
            },{
                name: 'String'
            },{
                name: 'Y'
            },{
                name: '512'
            },{
                name: '点播视频地址'
            },{
                name: 'dataId'
            },{
                name: 'String'
            },{
                name: 'Y'
            },{
                name: '128'
            },{
                name: '点播视频唯一标识'
            },{
                name: 'version'
            },{
                name: 'String'
            },{
                name: 'Y'
            },{
                name: '4'
            },{
                name: '接口版本号，可选值 v3.1'
            },{
                name: 'title'
            },{
                name: 'String'
            },{
                name: 'N'
            },{
                name: '512'
            },{
                name: '视频名称'
            },{
                name: 'callback'
            },{
                name: 'String'
            },{
                name: 'N'
            },{
                name: '512'
            },{
                name: '数据回调参数，产品根据业务情况自行设计，当获取离线检测结果时，明辨内容安全服务会返回该字段'
            },{
                name: 'callbackUrl'
            },{
                name: 'String'
            },{
                name: 'N'
            },{
                name: '256'
            },{
                name: '离线结果回调通知到客户的URL。主动回调数据接口超时时间设置为2s，为了保证顺利接收数据，需保证接收接口性能稳定并且保证幂等性。'
            },{
                name: 'uniqueKey'
            },{
                name: 'String'
            },{
                name: 'N'
            },{
                name: '256'
            },{
                name: '客户个性化视频唯一性标识，传入后，将以此值作为重复检测依据，若不传，默认以URL作为查重依据'
            }]}
            />
        },{
            text: '业务扩展参数'
        },{
            text: '业务扩展参数，有助于通过业务维度辅助反垃圾结果判定'
        },{
            text: <Table 
            headList={['参数名称','类型','是否必填','最大长度','描述']}
            dataSource={[{
                name: 'account'
            },{
                name: 'String'
            },{
                name: 'N'
            },{
                name: '128'
            },{
                name: '用户唯一标识'
            },{
                name: 'ip'
            },{
                name: 'String'
            },{
                name: 'N'
            },{
                name: '128'
            },{
                name: '用户 IP 地址'
            }]}
            />
        },{
            text: '业务截帧频率'
        },{
            text: <Table 
            headList={['参数名称','类型','是否必填','最大长度','描述']}
            dataSource={[{
                name: 'scFrequency'
            },{
                name: 'Number'
            },{
                name: 'N'
            },{
                name: '4'
            },{
                name: '默认截帧设置，默认5秒截图检测一次，可设置范围为0.5~600秒'
            },{
                name: 'advancedFrequency'
            },{
                name: 'json字符串'
            },{
                name: 'N'
            },{
                name: '128'
            },{
                name: '高级截帧设置，此项填写，默认截帧策略失效'
            }]}
            />
        },{
            text: 'advancedFrequency字符串数据结构'
        },{
            text: <Table 
            headList={['参数名称','类型','是否必填','最大长度','描述']}
            dataSource={[{
                name: 'durationPoints'
            },{
                name: '数组'
            },{
                name: 'Y'
            },{
                name: '5'
            },{
                name: '视频时长区间分割，单位为秒'
            },{
                name: 'frequencies'
            },{
                name: '数组'
            },{
                name: 'Y'
            },{
                name: '6'
            },{
                name: '视频时长区间对应的截帧频率，可设置范围为0.5~600秒'
            }]}
            />
        }]
    }],
    navList: [{
        name: "视频点播信息提交", 
        id: "1-2" 
    },
    {
        name: "接口地址", 
        id: "1-2-1"
    },
    {   
        name: "接口描述", 
        id: "1-2-2" 
    },
    {  
        name: "支持格式", 
        id: "1-2-3"
    },
    { 
        name: "大小限制", 
        id: "1-2-4" 
    },{ 
        name: "请求参数", 
        id: "1-2-5" 
    }],
},

{
    id: '1-3',
    title: '视频点播检测结果获取',
    updateTime: _time,
    scrollList: [{
        id: '1-3-1',
        name: '接口地址',
        content: [{
            text: 'https://api.hiyanai.com/v3/video/callback/results'
        },]
    },{
        id: '1-3-2',
        name: '接口描述',
        content: [{
            text: '该接口用于获取点播视频检测结果，检测结果包含截图证据信息及违规分类信息等。'
        },]
    },{
        id: '1-3-3',
        name: '接口请求频率',
        content: [{
            text: '小于10s/20次，请求频率过快服务器会拒绝处理.'
        },]
    },{
        id: '1-3-4',
        name: '请求参数',
        content: [{
            text: '公共参数已省略，详细见 请求公共参数'
        }]
    },{
        id: '1-3-5',
        name: '响应结果',
        content: [{
            text: '响应字段如下，响应通用字段已省略，详细见 响应通用字段 ：'
        },{
            text: <Table 
            headList={['参数名称','类型','描述']}
            dataSource={[{
                name: 'result'
            },{
                name: 'json数组'
            },{
                name: '检测结果数组'
            }]}
            />
        },{
            text: 'result 数据结构'
        },{
            text: <Table 
            headList={['参数名称','类型','描述']}
            dataSource={[{
                name: 'status'
            },{
                name: 'Number'
            },{
                name: '视频处理状态码，定义为：0：检测成功，110：请求重复，120：参数错误，130：解析错误，140：数据类型错误，160：视频大小超限（>5G）'
            },{
                name: 'taskId'
            },{
                name: 'String'
            },{
                name: '音视频数据请求标识，可以根据该标识查询视频数据最新结果'
            },{
                name: 'callback'
            },{
                name: 'String'
            },{
                name: '产品提交视频点播信息时带的 callback 字段数据，用于标识视频，产品根据业务情况自行设计'
            },{
                name: 'level'
            },{
                name: 'Number'
            },{
                name: '视频级别信息，分为0:正常，1：不确定，2：确定，其中不确定的建议人工复审'
            },{
                name: 'evidences'
            },{
                name: 'json对象数组'
            },{
                name: '证据信息数组，如果数组为空，则表示该视频为正常视频'
            }]}
            />
        },{
            text: 'evidences对象数据结构：'
        },{
            text: <Table 
            headList={['参数名称','类型','描述']}
            dataSource={[{
                name: 'beginTime'
            },{
                name: 'Number'
            },{
                name: '证据开始相对时间，单位为毫秒，调用方获取后可自行格式化为可视化时间，如：149000 转换为"00:02:29"'
            },{
                name: 'endTime'
            },{
                name: 'Number'
            },{
                name: '证据结束相对时间，单位为毫秒，调用方获取后可自行格式化为可视化时间，如：149000 转换为"00:02:29"'
            },{
                name: 'type'
            },{
                name: 'Number'
            },{
                name: '1：图片，2：视频'
            },{
                name: 'url'
            },{
                name: 'String'
            },{
                name: '证据信息'
            },{
                name: 'labels'
            },{
                name: 'json对象数组'
            },{
                name: '证据结果数组'
            }]}
            />
        },{
            text: 'labels对象数据结构'
        },{
            text: <Table 
            headList={['参数名称','类型','描述']}
            dataSource={[{
                name: 'label'
            },{
                name: 'Number'
            },{
                name: '分类信息，100：色情，110：性感；200：广告，210：二维码；300：暴恐，400：违禁，500：涉政，1020：黑屏，1030：挂机'
            },{
                name: 'subLabels'
            },{
                name: 'json数组'
            },{
                name: '细分类信息,可能包含多个，可能为空'
            },{
                name: 'level'
            },{
                name: 'Number'
            },{
                name: '级别信息，分为1：不确定，2：确定'
            },{
                name: 'rate'
            },{
                name: 'Number'
            },{
                name: '分数'
            }]}
            />
        },{
            text: 'subLabels 数据结构'
        },{
            text: <Table 
            headList={['参数名称','类型','描述']}
            dataSource={[{
                name: 'subLabel'
            },{
                name: 'Number'
            },{
                name: '细分类，详细编码请参考下方对应细分类编码对照表'
            },{
                name: 'rate'
            },{
                name: 'Number'
            },{
                name: '置信度分数，0-1之间取值，1为置信度最高，0为置信度最低'
            },{
                name: 'details'
            },{
                name: 'json数组'
            },{
                name: 'htiInfos:命中详细信息'
            }]}
            />
        }]
    }],
    navList: [{
        name: "视频点播检测结果获取", 
        id: "1-3" 
    },
    {
        name: "接口地址", 
        id: "1-3-1"
    },
    {   
        name: "接口描述", 
        id: "1-3-2" 
    },
    {  
        name: "接口请求频率", 
        id: "1-3-3"
    },
    { 
        name: "请求参数", 
        id: "1-3-4" 
    },{ 
        name: "响应结果", 
        id: "1-3-5" 
    }],
},

{
    id: '1-4',
    title: '文本识别接口',
    updateTime: _time,
    scrollList: [{
        id: '1-4-1',
        name: '接口地址',
        content: [{
            text: 'https://api.hiyanai.com/v3/text/check'
        },]
    },{
        id: '1-4-2',
        name: '接口描述',
        content: [{
            text: '审核文本内容是否违规'
        },]
    },{
        id: '1-4-3',
        name: '请求参数',
        content: [{
            text: '公共参数已省略，详细见 请求公共参数，其他参数如下：'
        },{
            text: '基本参数'
        },{
            text: <Table 
            headList={['参数名称','类型','是否必选','最大长度','描述']}	           				
            dataSource={[{
                name: 'dataId'
            },{
                name: 'String'
            },{
                name: 'Y'
            },{
                name: '128'
            },{
                name: '数据唯一标识，能够根据该值定位到该条数据，如对数据结果有异议，可以发送该值给客户经理查询'
            },{
                name: 'content'
            },{
                name: 'String'
            },{
                name: 'Y'
            },{
                name: '2^24-1'
            },{
                name: '用户发表内容，建议对内容中JSON、表情符、HTML标签、UBB标签等做过滤，只传递纯文本，以减少误判概率'
            },{
                name: 'title'
            },{
                name: 'String'
            },{
                name: 'N'
            },{
                name: '512'
            },{
                name: '内容标题，适用于贴子、博客的文章标题等场景，建议抄送，辅助机审策略精准调优'
            },{
                name: 'dataType'
            },{
                name: 'Number'
            },{
                name: 'N'
            },{
                name: '4'
            },{
                name: '子数据类型，与明辨内容安全服务约定即可'
            },{
                name: 'version'
            },{
                name: 'String'
            },{
                name: 'Y'
            },{
                name: '4'
            },{
                name: '接口版本号，可选值 v3.1'
            },{
                name: 'callback'
            },{
                name: 'String'
            },{
                name: 'N'
            },{
                name: '2^16-1'
            },{
                name: '数据回调参数，调用方根据业务情况自行设计，当调用文本离线结果获取接口时，该接口会原样返回该字段，详细见文本离线检测结果获取。作为数据处理标识，因此该字段应该设计为能唯一定位到该次请求的数据结构，如对用户的昵称进行检测，dataId可设为用户标识（用户ID），用户修改多次，每次请求数据的dataId可能一致，但是callback参数可以设计成定位该次请求的数据结构，比如callback字段设计成json，包含dataId和请求的时间戳等信息，当然如果不想做区分，也可以直接把callback设置成dataId的值。'
            },{
                name: 'publishTime'
            },{
                name: 'Number'
            },{
                name: 'N'
            },{
                name: '13'
            },{
                name: '发表时间，UNIX 时间戳(毫秒值)'
            },{
                name: 'callbackUrl'
            },{
                name: 'String'
            },{
                name: 'N'
            },{
                name: '256'
            },{
                name: '人工审核结果回调通知到客户的URL。主动回调数据接口超时时间设置为2s，为了保证顺利接收数据，需保证接收接口性能稳定并且保证幂等性。'
            }]}
            />
        },{
            text: '业务扩展参数'
        },{
            text: '业务扩展参数，有助于通过业务维度辅助反垃圾结果判定'
        },{
            text: <Table 
            headList={['参数名称','类型','是否必选','最大长度','描述']}	           				
            dataSource={[{
                name: 'ip'
            },{
                name: 'String'
            },{
                name: 'Y'
            },{
                name: '128'
            },{
                name: '用户IP地址，建议抄送，辅助机审策略精准调优'
            },{
                name: 'account'
            },{
                name: 'String'
            },{
                name: 'N'
            },{
                name: '128'
            },{
                name: '用户唯一标识，建议抄送，辅助机审策略精准调优如果无需登录则为空'
            },{
                name: 'nickname'
            },{
                name: 'String'
            },{
                name: 'N'
            },{
                name: '128'
            },{
                name: '用户昵称，建议抄送，辅助机审策略精准调优'
            },{
                name: 'deviceType'
            },{
                name: 'Number'
            },{
                name: 'N'
            },{
                name: '4'
            },{
                name: '用户设备类型，1：web， 2：wap， 3：android， 4：iphone， 5：ipad， 6：pc， 7：wp ，建议抄送，辅助机审策略精准调优'
            },{
                name: 'deviceId'
            },{
                name: 'String'
            },{
                name: 'Y'
            },{
                name: '128'
            },{
                name: '用户设备 id ，建议抄送，辅助机审策略精准调优'
            },{
                name: 'extStr1'
            },{
                name: 'String'
            },{
                name: 'N'
            },{
                name: '128'
            },{
                name: '自定义扩展参数'
            },{
                name: 'extStr2'
            },{
                name: 'String'
            },{
                name: 'N'
            },{
                name: '128'
            },{
                name: '自定义扩展参数'
            },{
                name: 'extLon1'
            },{
                name: 'Long'
            },{
                name: 'N'
            },{
                name: '2^63-1'
            },{
                name: '自定义扩展参数'
            },{
                name: 'extLon2'
            },{
                name: 'Long'
            },{
                name: 'N'
            },{
                name: '2^63-1'
            },{
                name: '自定义扩展参数'
            }]}
            />
        },{
            text: '反垃圾防刷版专属字段'
        },
        {
            text: '如在您的内容检测场景中，存在一定作弊行为，建议开启反垃圾防刷版，如下参数，需开启反垃圾防刷功能后，传入方可生效，如需开启，请联系您的专属商务'
        },{
            text: <Table 
            headList={['参数名称','类型','是否必选','最大长度','描述']}
            dataSource={[{
                name: 'email'
            },{
                name: 'String'
            },{
                name: 'N'
            },{
                name: '64'
            },{
                name: '用户邮箱'
            },{
                name: 'phone'
            },{
                name: 'String'
            },{
                name: 'N'
            },{
                name: '11'
            },{
                name: '用户手机号'
            },{
                name: 'token'
            },{
                name: 'String'
            },{
                name: 'N'
            },{
                name: '256'
            },{
                name: '来自明辨反作弊SDK返回的token'
            },{
                name: 'extension'
            },{
                name: 'json'
            },{
                name: 'N'
            },{
                name: '512'
            },{
                name: '扩展字段'
            }]}
            />
        }]
    },{
        id: '1-4-4',
        name: '响应结果',
        content: [{
            text: '响应字段如下，响应通用字段已省略，详细见 响应通用字段：'
        },{
            text: 'antispam 数组数据结构'
        },{
            text: <Table 
            headList={['参数名称','类型','描述']}
            dataSource={[{
                name: 'name'
            },{
                name: 'String'
            },{
                name: '图片名称(或图片标识)'
            },{
                name: 'taskId'
            },{
                name: 'String'
            },{
                name: '在线接口检测返回的数据请求标识，可以根据该标识查询数据最新结果'
            },{
                name: 'action'
            },{
                name: 'Number'
            },{
                name: '建议动作，2：建议删除，0：建议通过'
            },{
                name: 'labels'
            },{
                name: 'json数组'
            },{
                name: '分类信息'
            }]}
            />
        },{
            text: 'labels 数据结构'
        },{
            text: <Table 
            headList={['参数名称','类型','描述']}
            dataSource={[{
                name: 'label'
            },{
                name: 'Number'
            },{
                name: '分类信息，100：色情，110：性感，200：广告，210：二维码，300：暴恐，400：违禁，500：涉政'
            },{
                name: 'level'
            },{
                name: 'Number'
            },{
                name: '分类级别，0：正常，2：确定'
            },{
                name: 'rate'
            },{
                name: 'Number'
            },{
                name: '置信度分数'
            }]}
            />
        }]
    }],
    navList: [{
        name: "文本识别接口", 
        id: "1-4" 
    },
    {
        name: "接口地址", 
        id: "1-4-1"
    },
    {   
        name: "接口描述", 
        id: "1-4-2" 
    },
    {  
        name: "请求参数", 
        id: "1-4-3"
    },
    { 
        name: "响应结果", 
        id: "1-4-4" 
    }]
},

{
    id: '1-5',
    title: '离线查询接口',
    updateTime: _time,
    scrollList: [{
        id: '1-5-1',
        name: '接口地址',
        content: [{
            text: 'https://api.hiyanai.com/v3/open/getResults'
        },]
    },{
        id: '1-5-2',
        name: '接口描述',
        content: [{
            text: '通过返回的taskId主动查询识别结果'
        },]
    },{
        id: '1-5-3',
        name: '接口请求频率',
        content: [{
            text: ''
        },]
    },{
        id: '1-5-4',
        name: '请求参数',
        content: [{
            text: '公共参数已省略，详细见 请求公共参数'
        },{
            text: '基本字段'
        },{
            text: <Table 
            headList={['参数名称','类型','描述']}
            dataSource={[{
                name: 'taskid'
            },{
                name: 'json数组'
            },{
                name: '同步返回的taskid'
            }]}
            />
        }]
    },{
        id: '1-5-5',
        name: '响应结果',
        content: [{
            text: '参考具体接口的返回参数'
        }]
    }],
    navList: [{
        name: "离线查询接口", 
        id: "1-5" 
    },
    {
        name: "接口地址", 
        id: "1-5-1"
    },
    {   
        name: "接口描述", 
        id: "1-5-2" 
    },
    {  
        name: "接口请求频率", 
        id: "1-5-3"
    },
    { 
        name: "请求参数", 
        id: "1-5-4" 
    },{ 
        name: "响应结果", 
        id: "1-5-5" 
    }],
},

{
    id: '2-0',
    title: '规范说明',
    updateTime: _time,
    scrollList: [{
        id: '2-0-1',
        name: '通信协议',
        content: [{
            text: 'HTTPS 协议。'
        }]
    },{
        id: '2-0-2',
        name: '请求方法',
        content: [{
            text: '所有请求均只支持POST方式。'
        },]
    },{
        id: '2-0-3',
        name: '字符编码',
        content: [{
            text: '所有接口的请求和响应数据编码皆为 utf-8。'
        }]
    },{
        id: '2-0-4',
        name: 'API请求结构',
        content: [{
            text: '所有HTTP请求中，都包含如下公共请求头（HTTP Header）：'
        },{
            text: <Table 
            headList={['名称','类型','备注']}
            dataSource={[{
                name: 'API入口'
            },{
                name: '具体API接口地址'
            },{
                name: 'https://api.hiyanai.com/vx/xx/check'
            },{
                name: '公共参数'
            },{
                name: '每个接口都包含的通用参数'
            },{
                name: '详见 公共参数 说明'
            },{
                name: '私有参数'
            },{
                name: '每个接口特头的参数'
            },{
                name: '详细参考每个API接口定义'
            }]}
            />
        }]
    },{
        id: '2-0-5',
        name: '公共参数',
        content: [{
            text: '公共参数是用于标识产品和接口鉴权目的的参数，如非必要，在每个接口单独的接口文档中不再对这些参数进行说明，每次请求均需要携带这些参数。'
        }]
    },{
        id: '2-0-6',
        name: '请求公共参数',
        content: [{
            text: <Table 
            headList={['参数名称','类型','是否必须','最大长度','描述']}
            dataSource={[{
                name: 'secretId'
            },{
                name: 'String'
            },{
                name: 'Y'
            },{
                name: '32'
            },{
                name: '产品秘钥 id ，由明辨内容安全服务分配，产品标识'
            },{
                name: 'businessId'
            },{
                name: 'String'
            },{
                name: 'Y'
            },{
                name: '32'
            },{
                name: '业务id ，由明辨内容安全服务分配，业务标识'
            },{
                name: 'timestamp'
            },{
                name: 'Number'
            },{
                name: 'Y'
            },{
                name: '13'
            },{
                name: '请求当前 UNIX 时间戳，请注意服务器时间是否同步'
            },{
                name: 'nonce'
            },{
                name: 'Number'
            },{
                name: 'Y'
            },{
                name: '11'
            },{
                name: '随机整数，与 timestamp 联合起来，用于防止重放攻击'
            },{
                name: 'signature'
            },{
                name: 'String'
            },{
                name: 'Y'
            },{
                name: '32'
            },{
                name: '请求签名，用来验证此次请求的合法性，具体算法见 接口鉴权'
            }]}
            />
        }]
    },{
        id: '2-0-7',
        name: '响应通用字段',
        content: [{
            text: '所有接口响应值采用 json 格式， 如无特殊说明，每次请求的返回值中，都包含下面字段：'
        },{
            text: <Table 
            headList={['参数名称','类型','描述']}
            dataSource={[{
                name: 'code'
            },{
                name: 'Number'
            },{
                name: '接口调用状态，200:正常，其他值：调用出错，返回码见 响应返回码'
            },{
                name: 'msg'
            },{
                name: 'String'
            },{
                name: '结果说明，如果接口调用出错，那么返回错误描述，成功返回 ok'
            },{
                name: 'result'
            },{
                name: 'json'
            },{
                name: '接口返回结果，各个接口自定义，若为空或null标识返回正常'
            }]}
            />
        }]
    },{
        id: '2-0-8',
        name: '响应返回码',
        content: [{
            text: '响应返回码（code）反应了明辨内容安全服务 API 调用和执行的概要结果。当返回码不为 200 时， 表示请求未正常执行，返回码描述 (msg) 对该结果进行了细化补充，用户可根据返回码判断 API 的执行情况。所有接口调用返回值均包含 code 和 msg 字段， code 为返回码值，msg 为返回码描述信息，返回码表如下：'
        },{
            text: <Table 
            headList={['返回码','说明']}
            dataSource={[{
                name: '200'
            },{
                name: '接口调用成功'
            },{
                name: '400'
            },{
                name: '请求缺少 secretId 或 businessId'
            },{
                name: '401'
            },{
                name: 'businessId无效或过期'
            },{
                name: '405'
            },{
                name: '请求参数异常，请检查参数是否有缺失，参数是否正确'
            },{
                name: '410'
            },{
                name: '签名验证失败，目前请求参数仅支持form表单形式，建议content-type设置为 application/x-www-form-urlencoded 编码格式'
            },{
                name: '411'
            },{
                name: '请求频率超过限制'
            },{
                name: '413'
            },{
                name: '试用期请求数据总量超过限制'
            },{
                name: '414'
            },{
                name: '请求长度/大小超过限制'
            },{
                name: '415'
            },{
                name: '业务版本限制'
            },{
                name: '420'
            },{
                name: '请求过期'
            },{
                name: '421'
            },{
                name: 'token过期'
            },{
                name: '422'
            },{
                name: 'token无效'
            },{
                name: '430'
            },{
                name: '重放攻击'
            },{
                name: '503'
            },{
                name: 'servicelist unavailable 接口异常'
            }]}
            />
        }]
    }],
    navList: [{
        name: "规范说明", 
        id: "2-0" 
    },
    {
        name: "通信协议", 
        id: "2-0-1"
    },
    {   
        name: "请求方法", 
        id: "2-0-2" 
    },
    { 
        name: "字符编码", 
        id: "2-0-3" 
    },{
        name: 'API请求结构',
        id: "2-0-4" 
    },{ 
        name: "公共参数", 
        id: "2-0-5" 
    },{ 
        name: "请求公共参数", 
        id: "2-0-6" 
    },{ 
        name: "响应通用字段", 
        id: "2-0-7" 
    },{
        name: '响应返回码',
        id: "2-0-8" 
    }]
},

{
    id: '2-0--1',
    title: '接口鉴权',
    updateTime: _time,
    scrollList: [{
        id: '2-0--1-1',
        name: '鉴权说明',
        content: [{
            text: '海宴明辨系统使用签名方法对接口进行鉴权，所有接口每一次请求都需要包含签名信息（signature 参数），以验证用户身份，防止信息被恶意篡改。'
        }]
    },{
        id: '2-0--1-2',
        name: '申请安全凭证',
        content: [{
            text: '在第一次使用 API 之前，需申请安全凭证，安全凭证包括 SecretId 和 SecretKey ，SecretId 是用于标识 API 调用者的身份，SecretKey 是用于加密签名字符串和服务器端验证签名字符串的密钥。SecretKey 必须严格保管，避免泄露。'
        }]
    },{
        id: '2-0--1-3',
        name: '签名生产算法',
        content: [{
            text: '签名生成方法如下：'
        },{
            text: '1. 对所有请求参数（包括公有参数和私有参数，但不包括 signature 参数），按照参数名ASCII码表升序顺序排序。如：foo=1， bar=2， foo_bar=3， baz=4 排序后的顺序是 bar=2， baz=4， foo=1， foobar=3。'
        },{
            text: '2. 将排序好的参数名和参数值构造成字符串，格式为：key1+value1+key2+value2… 。根据上面的示例得到的构造结果为：bar2baz4foo1foobar3 。'
        },{
            text: '3. 选择与 secretId 配对的 secretKey ，加到上一步构造好的参数字符串之后，如 secretKey=6308afb129ea00301bd7c79621d07591 ，则最后的参数字符串为 bar2baz4foo1foobar36308afb129ea00301bd7c79621d07591。'
        },{
            text: '4. 把c步骤拼装好的字符串采用 utf-8 编码，使用 MD5 算法对字符串进行摘要，计算得到 signature 参数值，将其加入到接口请求参数中即可。MD5 是128位长度的摘要算法，用16进制表示，一个十六进制的字符能表示4个位，所以签名后的字符串长度固定为32位十六进制字符。'
        },{
            text: '示例代码'
        },{
            text: 'Java：'
        },{
            text: <img src={require("../images/pic7.png")} alt="" />
        },{
            text: 'Python: '
        },{
            text: <img src={require("../images/pic8.png")} alt="" />
        },{
            text: 'PHP: '
        },{
            text: <img src={require("../images/pic9.png")} alt="" />
        },{
            text: 'C#: '
        },{
            text: <img src={require("../images/pic10.png")} alt="" />
        }]
    }],
    navList: [{
        name: "接口鉴权", 
        id: "2-0--1" 
    },
    {
        name: "鉴权说明", 
        id: "2-0--1-1" 
    },
    {
        name: "申请安全凭证", 
        id: "2-0--1-2"
    },
    {   
        name: "签名生产算法", 
        id: "2-0--1-3" 
    }]
},

{
    id: '2-1',
    title: '授权token 获取接口',
    updateTime: _time,
    scrollList: [{
        id: '2-1-1',
        name: '接口地址',
        content: [{
            text: 'https://api.hiyanai.com/v1/auth/getToken'
        },]
    },{
        id: '2-1-2',
        name: '接口描述',
        content: [{
            text: '获取人证合一其他API的访问鉴权参数accessToken,无需每次调用都重新获取，accessToken有效期内可以无限次重复使用'
        },]
    },{
        id: '2-1-3',
        name: '请求参数',
        content: [{
            text: '公共参数已省略，详细见 请求公共参数'
        }]
    },{
        id: '2-1-4',
        name: '响应结果',
        content: [{
            text: '响应字段如下，响应通用字段已省略，详细见 响应通用字段'
        },{
            text: <Table 
            headList={['参数名称','类型','描述']}
            dataSource={[{
                name: 'result'
            },{
                name: 'Json对象'
            },{
                name: '检测结果对象'
            }]}
            />
        },{
            text: 'result 数据结构'
        },{
            text: <Table 
            headList={['参数名称','类型','描述']}
            dataSource={[{
                name: 'accessToken'
            },{
                name: 'String'
            },{
                name: '授权码'
            },{
                name: 'expiressIn'
            },{
                name: 'number'
            },{
                name: '过期时间'
            }]}
            />
        }]
    }],
    navList: [{
        name: "授权token 获取接口", 
        id: "2-1" 
    },
    {
        name: "接口地址", 
        id: "2-1-1"
    },
    {   
        name: "接口描述", 
        id: "2-1-2" 
    },
    { 
        name: "请求参数", 
        id: "2-1-3" 
    },{ 
        name: "响应结果", 
        id: "2-1-4" 
    }],
},

{
    id: '2-2',
    title: '人脸识别',
    updateTime: _time,
    scrollList: [{
        id: '2-2-1',
        name: '接口地址',
        content: [{
            text: 'https://api.hiyanai.com/v1/face/feature'
        },]
    },{
        id: '2-2-2',
        name: '接口描述',
        content: [{
            text: '上传人脸图像，获得人脸特征'
        },]
    },{
        id: '2-2-3',
        name: '请求参数',
        content: [{
            text: '公共参数accessToken'
        },{
            text: '基本参数'
        },{
            text: <Table 
            headList={['参数名称','类型','描述']}
            dataSource={[{
                name: 'type'
            },{
                name: 'number'
            },{
                name: '图片类型 1.url 2.base64'
            },{
                name: 'face'
            },{
                name: 'string'
            },{
                name: '人脸图像'
            }]}
            />
        }]
    },{
        id: '2-2-4',
        name: '响应结果',
        content: [{
            text: '响应字段如下，响应通用字段已省略，详细见 响应通用字段 ：'
        },{
            text: <Table 
            headList={['参数名称','类型','描述']}
            dataSource={[{
                name: 'result'
            },{
                name: 'Json对象'
            },{
                name: '检测结果对象'
            }]}
            />
        },{
            text: 'result 数据结构'
        },{
            text: <Table 
            headList={['参数名称','类型','描述']}
            dataSource={[{
                name: 'resultDetail'
            },{
                name: 'String'
            },{
                name: '检查结果“0000000”表示成功，其他失败'
            },{
                name: 'feature'
            },{
                name: 'String'
            },{
                name: 'Base64编码之后的人脸特征'
            }]}
            />
        }]
    }],
    navList: [{
        name: "人脸识别", 
        id: "2-2" 
    },
    {
        name: "接口地址", 
        id: "2-2-1"
    },
    {   
        name: "接口描述", 
        id: "2-2-2" 
    },
    { 
        name: "请求参数", 
        id: "2-2-3" 
    },{ 
        name: "响应结果", 
        id: "2-2-4" 
    }],
},

{
    id: '2-3',
    title: '人脸对比',
    updateTime: _time,
    scrollList: [{
        id: '2-3-1',
        name: '接口地址',
        content: [{
            text: 'https://api.hiyanai.com/v1/face/compare'
        },]
    },{
        id: '2-3-2',
        name: '接口描述',
        content: [{
            text: '上传原始的人脸特征（原始人脸特则可以通过人脸识别接口和其他人证合一接口获得），和目标对比图片，来判断是不是同一个人。可以用作人证合一识别通过之后，以后的简单人证合一校验，降低接口调用成本'
        },]
    },{
        id: '2-3-3',
        name: '请求参数',
        content: [{
            text: '公共参数accessToken'
        },{
            text: '基本参数'
        },{
            text: <Table 
            headList={['参数名称','类型','描述']}
            dataSource={[{
                name: 'feature'
            },{
                name: 'string'
            },{
                name: '人脸特征'
            },{
                name: 'type'
            },{
                name: 'number'
            },{
                name: '图片类型 1.url 2.base64'
            },{
                name: 'face'
            },{
                name: 'string'
            },{
                name: '需要对比的人脸图像'
            }]}
            />
        }]
    },{
        id: '2-3-4',
        name: '响应结果',
        content: [{
            text: '响应字段如下，响应通用字段已省略，详细见 响应通用字段 ：'
        },{
            text: <Table 
            headList={['参数名称','类型','描述']}
            dataSource={[{
                name: 'result'
            },{
                name: 'Json对象'
            },{
                name: '检测结果对象'
            }]}
            />
        },{
            text: 'result 数据结构'
        },{
            text: <Table 
            headList={['参数名称','类型','描述']}
            dataSource={[{
                name: 'resultDetail'
            },{
                name: 'String'
            },{
                name: '检查结果“0000000”表示同一个人，其他表示不是同一个人'
            },{
                name: 'resultMsg'
            },{
                name: 'String'
            },{
                name: '对比结果描述'
            },{
                name: 'similarity'
            },{
                name: 'Float64'
            },{
                name: '相似度'
            }]}
            />
        }]
    }],
    navList: [{
        name: "人脸对比", 
        id: "2-3" 
    },
    {
        name: "接口地址", 
        id: "2-3-1"
    },
    {   
        name: "接口描述", 
        id: "2-3-2" 
    },
    { 
        name: "请求参数", 
        id: "2-3-3" 
    },{ 
        name: "响应结果", 
        id: "2-3-4" 
    }],
},

{
    id: '2-4',
    title: '人证合一简单检测',
    updateTime: _time,
    scrollList: [{
        id: '2-4-1',
        name: '接口地址',
        content: [{
            text: 'https://api.hiyanai.com/v1/identity/simple/check'
        },]
    },{
        id: '2-4-2',
        name: '接口描述',
        content: [{
            text: '该接口用于获取人证检测结果，检测结果包含检测信息。'
        },]
    },{
        id: '2-4-3',
        name: '请求参数',
        content: [{
            text: 'accessToken'
        },{
            text: '基本参数'
        },{
            text: <Table 
            headList={['参数名称','类型','是否必选','最大长度','描述']}
            dataSource={[{
                name: 'name'
            },{
                name: 'String'
            },{
                name: 'Y'
            },{
                name: '256'
            },{
                name: '姓名'
            },{
                name: 'idNum'
            },{
                name: 'String'
            },{
                name: 'Y'
            },{
                name: '256'
            },{
                name: '证件号码'
            },{
                name: 'idType'
            },{
                name: 'String'
            },{
                name: 'N'
            },{
                name: '10'
            },{
                name: '证件类型，默认身份证'
            }]}
            />
        }]
    },{
        id: '2-4-4',
        name: '响应结果',
        content: [{
            text: '响应字段如下，响应通用字段已省略，详细见 响应通用字段 ：'
        },{
            text: <Table 
            headList={['参数名称','类型','描述']}
            dataSource={[{
                name: 'result'
            },{
                name: 'Json对象'
            },{
                name: '检测结果对象'
            }]}
            />
        },{
            text: 'result 数据结构'
        },{
            text: <Table 
            headList={['参数名称','类型','描述']}
            dataSource={[{
                name: 'resultDetail'
            },{
                name: 'String'
            },{
                name: '结果码'
            },
            /*
            {
                name: 'similarity'
            },{
                name: 'String'
            },{
                name: '人像相似度'
            },
            */
            {
                name: 'resultMsg'
            },{
                name: 'String'
            },{
                name: '结果说明'
            }]}
            />
        }]
    }],
    navList: [{
        name: "人证合一简单检测", 
        id: "2-4" 
    },
    {
        name: "接口地址", 
        id: "2-4-1"
    },
    {   
        name: "接口描述", 
        id: "2-4-2" 
    },
    { 
        name: "请求参数", 
        id: "2-4-3" 
    },{ 
        name: "响应结果", 
        id: "2-4-4" 
    }],
},

{
    id: '2-5',
    title: '人证合一照片检测',
    updateTime: _time,
    scrollList: [{
        id: '2-5-1',
        name: '接口地址',
        content: [{
            text: 'https://api.hiyanai.com/v1/identity/photo/check'
        },]
    },{
        id: '2-5-2',
        name: '接口描述',
        content: [{
            text: <img src={require('../images/pic3.png')} alt="" />
        },{
            text: '该接口用于获取人证检测结果，检测结果包含检测信息。'
        },]
    },{
        id: '2-5-3',
        name: '请求参数',
        content: [{
            text: 'accessToken'
        },{
            text: '基本参数'
        },{
            text: <Table 
            headList={['参数名称','类型','是否必选','最大长度','描述']}
            dataSource={[{
                name: 'image64'
            },{
                name: 'String'
            },{
                name: 'Y'
            },{
                name: '10MB'
            },{
                name: '图片base64编码'
            },{
                name: 'name'
            },{
                name: 'String'
            },{
                name: 'Y'
            },{
                name: '256'
            },{
                name: '姓名'
            },{
                name: 'idNum'
            },{
                name: 'String'
            },{
                name: 'Y'
            },{
                name: '256'
            },{
                name: '证件号码'
            },{
                name: 'idType'
            },{
                name: 'String'
            },{
                name: 'N'
            },{
                name: '10'
            },{
                name: '证件类型，默认身份证'
            }]}
            />
        }]
    },{
        id: '2-5-4',
        name: '响应结果',
        content: [{
            text: '响应字段如下，响应通用字段已省略，详细见 响应通用字段 ：'
        },{
            text: <Table 
            headList={['参数名称','类型','描述']}
            dataSource={[{
                name: 'result'
            },{
                name: 'Json对象'
            },{
                name: '检测结果对象'
            }]}
            />
        },{
            text: 'result 数据结构'
        },{
            text: <Table 
            headList={['参数名称','类型','描述']}
            dataSource={[{
                name: 'resultDetail'
            },{
                name: 'String'
            },{
                name: '结果码'
            },{
                name: 'similarity'
            },{
                name: 'String'
            },{
                name: '人像相似度'
            },{
                name: 'resultMsg'
            },{
                name: 'String'
            },{
                name: '结果说明'
            }]}
            />
        }]
    }],
    navList: [{
        name: "人证合一照片检测", 
        id: "2-5" 
    },
    {
        name: "接口地址", 
        id: "2-5-1"
    },
    {   
        name: "接口描述", 
        id: "2-5-2" 
    },
    { 
        name: "请求参数", 
        id: "2-5-3" 
    },{ 
        name: "响应结果", 
        id: "2-5-4" 
    }],
},

{
    id: '2-6',
    title: '人证合一静默视频检测',
    updateTime: _time,
    scrollList: [{
        id: '2-6-1',
        name: '接口地址',
        content: [{
            text: 'https://api.hiyanai.com/v1/identity/video/check'
        },]
    },{
        id: '2-6-2',
        name: '接口描述',
        content: [{
            text: '该接口用于获取人证检测结果，检测结果包含检测信息。'
        },]
    },{
        id: '2-6-3',
        name: '请求参数',
        content: [{
            text: 'accessToken'
        },{
            text: '基本参数'
        },{
            text: <Table 
            headList={['参数名称','类型','是否必选','最大长度','描述']}
            dataSource={[{
                name: 'video'
            },{
                name: 'file'
            },{
                name: 'N'
            },{
                name: '10MB'
            },{
                name: 'video 为需要检测的视频流 (如type=1，则该值为必填)视频文件 需要用post multipart/form-data的方式上传，此字段不加入签名'
            },{
                name: 'videoUrl'
            },{
                name: 'String'
            },{
                name: 'N'
            },{
                name: '1024'
            },{
                name: 'videoUrl 为需要检测的视频URL(如type=2，则该值为必填)'
            },{
                name: 'type'
            },{
                name: 'Number'
            },{
                name: 'N'
            },{
                name: '4'
            },{
                name: '类型，分别为1：视频流，2: 视频URL'
            },{
                name: 'name'
            },{
                name: 'String'
            },{
                name: 'Y'
            },{
                name: '256'
            },{
                name: '姓名'
            },{
                name: 'idNum'
            },{
                name: 'String'
            },{
                name: 'Y'
            },{
                name: '256'
            },{
                name: '证件号码'
            },{
                name: 'idType'
            },{
                name: 'String'
            },{
                name: 'N'
            },{
                name: '10'
            },{
                name: '证件类型，默认身份证'
            }]}
            />
        }]
    },{
        id: '2-6-4',
        name: '响应结果',
        content: [{
            text: '响应字段如下，响应通用字段已省略，详细见 响应通用字段 ：'
        },{
            text: <Table 
            headList={['参数名称','类型','描述']}
            dataSource={[{
                name: 'result'
            },{
                name: 'Json对象'
            },{
                name: '检测结果对象'
            }]}
            />
        },{
            text: 'result 数据结构'
        },{
            text: <Table 
            headList={['参数名称','类型','描述']}
            dataSource={[{
                name: 'resultDetail'
            },{
                name: 'String'
            },{
                name: '结果码'
            },{
                name: 'similarity'
            },{
                name: 'String'
            },{
                name: '人像相似度'
            },{
                name: 'resultMsg'
            },{
                name: 'String'
            },{
                name: '结果说明'
            }]}
            />
        }]
    }],
    navList: [{
        name: "人证合一静默视频检测", 
        id: "2-6" 
    },
    {
        name: "接口地址", 
        id: "2-6-1"
    },
    {   
        name: "接口描述", 
        id: "2-6-2" 
    },
    { 
        name: "请求参数", 
        id: "2-6-3" 
    },{ 
        name: "响应结果", 
        id: "2-6-4" 
    }],
},

{
    id: '2-7',
    title: '人证合一动作获取',
    updateTime: _time,
    scrollList: [{
        id: '2-7-1',
        name: '接口地址',
        content: [{
            text: 'https://api.hiyanai.com/v1/identity/session/get'
        },]
    },{
        id: '2-7-2',
        name: '接口描述',
        content: [{
            text: '该接口用于获取人证检测结果，检测结果包含检测信息。'
        },]
    },{
        id: '2-7-3',
        name: '请求参数',
        content: [{
            text: 'accessToken'
        },{
            text: '基本参数'
        },{
            text: '无'
        }]
    },{
        id: '2-7-4',
        name: '响应结果',
        content: [{
            text: '响应字段如下，响应通用字段已省略，详细见 响应通用字段 ：'
        },{
            text: <Table 
            headList={['参数名称','类型','描述']}
            dataSource={[{
                name: 'result'
            },{
                name: 'Json对象'
            },{
                name: '检测结果对象'
            }]}
            />
        },{
            text: 'result 数据结构'
        },{
            text: <Table 
            headList={['参数名称','类型','描述']}
            dataSource={[{
                name: 'sessionId'
            },{
                name: 'String'
            },{
                name: '动作会话id'
            },{
                name: 'type'
            },{
                name: 'Number'
            },{
                name: '动作类型1.动作2.读数字'
            },{
                name: 'msg'
            },{
                name: 'String'
            },{
                name: '动作或者数字列表'
            }]}
            />
        }]
    }],
    navList: [{
        name: "人证合一动作获取", 
        id: "2-7" 
    },
    {
        name: "接口地址", 
        id: "2-7-1"
    },
    {   
        name: "接口描述", 
        id: "2-7-2" 
    },
    { 
        name: "请求参数", 
        id: "2-7-3" 
    },{ 
        name: "响应结果", 
        id: "2-7-4" 
    }],
},

{
    id: '2-8',
    title: '人证合一动作视频检测',
    updateTime: _time,
    scrollList: [{
        id: '2-8-1',
        name: '接口地址',
        content: [{
            text: 'https://api.hiyanai.com/v1/identity/video/actioncheck'
        }]
    },{
        id: '2-8-2',
        name: '接口描述',
        content: [{
            text: '该接口用于获取人证检测结果，检测结果包含检测信息。'
        },]
    },{
        id: '2-8-3',
        name: '请求参数',
        content: [{
            text: 'accessToken'
        },{
            text: '基本参数'
        },{
            text: <Table 
            headList={['参数名称','类型','是否必选','最大长度','描述']}
            dataSource={[{
                name: 'video'
            },{
                name: 'file'
            },{
                name: 'N'
            },{
                name: '10MB'
            },{
                name: 'video 为需要检测的视频流 (如type=1，则该值为必填)视频文件 需要用post multipart/form-data的方式上传，此字段不加入签名'
            },{
                name: 'videoUrl'
            },{
                name: 'String'
            },{
                name: 'N'
            },{
                name: '1024'
            },{
                name: 'videoUrl 为需要检测的视频URL(如type=2，则该值为必填)'
            },{
                name: 'sessionId'
            },{
                name: 'String'
            },{
                name: 'Y'
            },{
                name: '256'
            },{
                name: '动作会话列表'
            },{
                name: 'type'
            },{
                name: 'Number'
            },{
                name: 'N'
            },{
                name: '4'
            },{
                name: '类型，分别为1：视频流，2: 视频URL'
            },{
                name: 'name'
            },{
                name: 'String'
            },{
                name: 'Y'
            },{
                name: '256'
            },{
                name: '姓名'
            },{
                name: 'idNum'
            },{
                name: 'String'
            },{
                name: 'Y'
            },{
                name: '256'
            },{
                name: '证件号码'
            },{
                name: 'idType'
            },{
                name: 'String'
            },{
                name: 'N'
            },{
                name: '10'
            },{
                name: '证件类型，默认身份证'
            }]}
            />
        },]
    },{
        id: '2-8-4',
        name: '响应结果',
        content: [{
            text: '响应字段如下，响应通用字段已省略，详细见 响应通用字段 ：'
        },{
            text: <Table 
            headList={['参数名称','类型','描述']}
            dataSource={[{
                name: 'result'
            },{
                name: 'Json对象'
            },{
                name: '检测结果对象'
            }]}
            />
        },{
            text: 'result 数据结构'
        },{
            text: <Table 
            headList={['参数名称','类型','描述']}
            dataSource={[{
                name: 'resultDetail'
            },{
                name: 'String'
            },{
                name: '结果码'
            },{
                name: 'similarity'
            },{
                name: 'String'
            },{
                name: '人像相似度'
            },{
                name: 'resultMsg'
            },{
                name: 'String'
            },{
                name: '结果说明'
            }]}
            />
        },{
            text: 'resultDetail编码对应表'
        },{
            text: <Table 
            headList={['返回编码','描述']}
            dataSource={[{
                name: '0000000'
            },{
                name: '姓名身份证匹配、返回人像相似度。'
            },{
                name: '0301003'
            },{
                name: '无法获取到人脸或存在多张人脸'
            },{
                name: '0301002'
            },{
                name: '身份证存在，姓名与身份证不匹配'
            },{
                name: '0301001'
            },{
                name: '库中无此号'
            },{
                name: '0399023'
            },{
                name: '公安库找不到人脸照片'
            },{
                name: '0399006'
            },{
                name: '人脸库中的照片检测不到人脸'
            },{
                name: '0301004'
            },{
                name: '请求姓名不标准：姓名为空或者包含特殊字符'
            },{
                name: '0301005'
            },{
                name: '攻击人脸'
            },{
                name: '0301006'
            },{
                name: '人脸清晰度不足'
            },{
                name: '0399005'
            },{
                name: 'image无法正确识别'
            },{
                name: '0399010'
            },{
                name: '身份证号码错误'
            }]}
            />
        }]
    }],
    navList: [{
        name: "人证合一动作视频检测", 
        id: "2-8" 
    },
    {
        name: "接口地址", 
        id: "2-8-1"
    },
    {   
        name: "接口描述", 
        id: "2-8-2" 
    },
    { 
        name: "请求参数", 
        id: "2-8-3" 
    },{ 
        name: "响应结果", 
        id: "2-8-4" 
    }],
},

{
    id: '3-0',
    title: '接口规范说明',
    updateTime: _time,
    scrollList: [{
        id: '3-0-1',
        name: '通信协议',
        content: [{
            text: 'HTTPS 协议。'
        }]
    },{
        id: '3-0-2',
        name: '请求方法',
        content: [{
            text: '内容安全服务提供的所有接口，只支持 POST方法。'
        },]
    },{
        id: '3-0-3',
        name: '字符编码',
        content: [{
            text: '所有接口的请求和响应数据编码皆为 utf-8。'
        }]
    },{
        id: '3-0-4',
        name: '公共请求头',
        content: [{
            text: '所有HTTP请求中，都包含如下公共请求头（HTTP Header）：'
        },{
            text: <Table 
            headList={['名称','类型','是否必须','描述']}
            dataSource={[{
                name: 'Accept'
            },{
                name: 'String'
            },{
                name: '是'
            },{
                name: '接受的返回类型，目前只支持JSON：application/json'
            },{
                name: 'Content-Type'
            },{
                name: 'String'
            },{
                name: '是'
            },{
                name: '当前请求body的数据类型，目前只支持JSON：application/json'
            },{
                name: 'Content-MD5'
            },{
                name: 'String'
            },{
                name: '是'
            },{
                name: '根据请求body计算所得，算法如下：对body做md5（返回二进制格式），然后做base64编码。'
            },{
                name: 'Date'
            },{
                name: 'String'
            },{
                name: '是'
            },{
                name: 'GMT日期格式，例如：Tue, 17 Jan 2017 10:16:36 GMT'
            },{
                name: 'x-acs-version'
            },{
                name: 'String'
            },{
                name: '是'
            },{
                name: '内容安全接口版本，当前版本为：2018-05-09'
            },{
                name: 'x-acs-signature-nonce'
            },{
                name: 'String'
            },{
                name: '是'
            },{
                name: '随机字符串，用来避免回放攻击。'
            },{
                name: 'x-acs-signature-version'
            },{
                name: 'String'
            },{
                name: '是'
            },{
                name: '签名版本，目前取值：1.0'
            },{
                name: 'x-acs-signature-method'
            },{
                name: 'String'
            },{
                name: '是'
            },{
                name: '签名方法，目前只支持：HMAC-SHA1'
            },{
                name: 'Authorization'
            },{
                name: 'String'
            },{
                name: '是'
            },{
                name: `认证方式，取值格式为："acs" + " " + AccessKeyId + ":" + signature
                其中，AccessKeyId向我们申请获得，signature为请求签名。签名算法参见签名机制。`
            }]}
            />
        }]
    },{
        id: '3-0-5',
        name: '公共返回参数',
        content: [{
            text: '所有请求均返回JSON格式数据。下表描述了返回参数中的公共字段。'
        },{
            text: <Table 
            headList={['名称','类型','是否必须','描述']}
            dataSource={[{
                name: 'code'
            },{
                name: '整型'
            },{
                name: '是'
            },{
                name: `错误码，和HTTP状态码一致（但有扩展）。
                2xx：表示成功。
                4xx：表示请求有误。
                5xx：表示后端有误。
                具体请参见公共错误码。`
            },{
                name: 'msg'
            },{
                name: '字符串'
            },{
                name: '是'
            },{
                name: '错误的详细描述。'
            },{
                name: 'requestId'
            },{
                name: '字符串'
            },{
                name: '是'
            },{
                name: '唯一标识该请求的ID，可用于定位问题。'
            },{
                name: 'data'
            },{
                name: '整型/字符串/JSON对象'
            },{
                name: '否'
            },{
                name: 'API（业务）相关的返回数据。出错情况下，该字段可能为空。一般来说，该字段为一个JSON结构体或数组。'
            }]}
            />
        }]
    },{
        id: '3-0-6',
        name: '公共错误码',
        content: [{
            text: <Table 
            headList={['错误代码','HTTP 状态码','描述']}
            dataSource={[{
                name: 'TIMEOUT'
            },{
                name: '581'
            },{
                name: '超时。建议重试，若持续返回该错误码，请通过工单联系我们。'
            },{
                name: 'CACHE_FAILED'
            },{
                name: '585'
            },{
                name: '缓存出错。建议重试，若持续返回该错误码，请通过工单联系我们。'
            },{
                name: 'ALGO_FAILED'
            },{
                name: '586'
            },{
                name: '算法出错。请通过工单联系我们。'
            },{
                name: 'MQ_FAILED'
            },{
                name: '587'
            },{
                name: '中间件出错。请通过工单联系我们。'
            },{
                name: 'EXCEED_QUOTA'
            },{
                name: '588'
            },{
                name: '请求频率超出配额。默认配额：图片检测50张/秒，视频检测20路/秒，语音检测20路/秒，文本检测100条/秒。如果需要调整配额，请通过工单联系我们。说明 关于价格说明，请参见内容安全产品定价。'
            },{
                name: 'TOO_LARGE'
            },{
                name: '589'
            },{
                name: '待检测内容过大，请确保检测的内容在API的限制范围内。建议重试，若持续返回该错误码，请通过工单联系我们。'
            },{
                name: 'BAD_FORMAT'
            },{
                name: '590'
            },{
                name: '待检测内容格式错误，请确保检测的内容在API的限制范围内。'
            },{
                name: 'CONNECTION_POOL_FULL'
            },{
                name: '591'
            },{
                name: '连接池满。请通过工单联系我们。'
            },{
                name: 'DOWNLOAD_TIMEOUT'
            },{
                name: '592'
            },{
                name: '下载超时，下载时间限制为3s，请确保检测的内容大小在API的限制范围内。'
            },{
                name: 'EXPIRED'
            },{
                name: '594'
            },{
                name: '任务过期，如taskId过期。'
            },{
                name: 'CATCH_FRAME_FAILED'
            },{
                name: '595'
            },{
                name: '截帧失败，请通过工单联系我们。'
            },{
                name: 'PERMISSION_DENY'
            },{
                name: '596'
            },{
                name: '账号未授权、账号欠费、账号未开通、账号被禁等原因，具体可以参考返回的msg。'
            }]}
            />
        }]
    }],
    navList: [{
        name: "接口规范说明", 
        id: "3-0" 
    },
    {
        name: "通信协议", 
        id: "3-0-1"
    },
    {   
        name: "请求方法", 
        id: "3-0-2" 
    },
    { 
        name: "字符编码", 
        id: "3-0-3" 
    },{ 
        name: "公共请求头", 
        id: "3-0-4" 
    },{ 
        name: "公共返回参数", 
        id: "3-0-5" 
    },{ 
        name: "公共错误码", 
        id: "3-0-6" 
    }],
},

{
    id: '3-1',
    title: '图片同步检测',
    updateTime: _time,
    scrollList: [{
        id: '3-1-1',
        name: '接口调用流程',
        content: [{
            text: <img src={require("../images/pic4.png")} alt="" />
        }]
    },{
        id: '3-1-2',
        name: '接口地址',
        content: [{
            text: 'https://api.hiyanai.com/green/image/scan'
        },]
    },{
        id: '3-1-3',
        name: '接口描述',
        content: [{
            text: '同步检测图片中的违规内容，实时返回检测结果。支持检测的场景包括：图片智能鉴黄、图片暴恐涉政识别、图文违规识别、图片二维码识别、图片不良场景识别、图片logo识别。'
        },]
    },{
        id: '3-1-4',
        name: '请求参数',
        content: [{
            text: '关于在请求中必须包含的公共请求参数，请参见公共参数。'
        },{
            text: '请求body是一个JSON对象，字段说明如下：'
        },{
            text: <Table 
            headList={['名称','类型','是否必须','描述']}
            dataSource={[{
                name: 'bizType'
            },{
                name: '字符串'
            },{
                name: '否'
            },{
                name: '该字段用于标识业务场景。针对不同的业务场景，您可以配置不同的内容审核策略，以满足不同场景下不同的审核标准或算法策略的需求。您可以通过云盾内容安全控制台创建业务场景（bizType），或者通过工单联系我们帮助您创建业务场景。'
            },{
                name: 'scenes'
            },{
                name: '字符串数组'
            },{
                name: '是'
            },{
                name: `指定图片检测的应用场景，可选值包括:
                porn：图片智能鉴黄
                terrorism：图片暴恐涉政识别
                ad：图文违规识别
                qrcode：图片二维码识别
                live：图片不良场景识别
                logo：图片logo识别
                说明 支持多场景（scenes）一起检测。例如，使用scenes=[“porn”, “terrorism”]，对一张图片同时进行鉴黄和暴恐识别，计费时也将按照两个场景计费。`
            },{
                name: 'tasks'
            },{
                name: 'JSON数组'
            },{
                name: '是'
            },{
                name: '指定检测对象，JSON数组中的每个元素是一个图片检测任务结构体（image表）。最多支持10个元素，即对10张图片进行检测。每个元素的具体结构描述见task。'
            }]}
            />
        },{
            text: '表 1. task'
        },{
            text: <Table 
            headList={['名称','类型','是否必须','描述']}
            dataSource={[{
                name: 'dataId'
            },{
                name: '字符串'
            },{
                name: '否'
            },{
                name: '数据Id。需要保证在一次请求中所有的Id不重复。'
            },{
                name: 'url'
            },{
                name: '字符串'
            },{
                name: '是'
            },{
                name: '待检测图像的URL。'
            }]}
            />
        }]
    },{
        id: '3-1-5',
        name: '返回参数',
        content: [{
            text: '返回结果说明，参见返回结果。'
        },{
            text: '返回body中的data字段是JSON数组，每一个元素包含如下字段：'
        },{
            text: <Table 
            headList={['名称','类型','是否必须','描述']}
            dataSource={[{
                name: 'code'
            },{
                name: '整型'
            },{
                name: '是'
            },{
                name: '错误码，和HTTP的status code一致。'
            },{
                name: 'msg'
            },{
                name: '字符串'
            },{
                name: '是'
            },{
                name: '错误描述信息。'
            },{
                name: 'dataId'
            },{
                name: '字符串'
            },{
                name: '否'
            },{
                name: '对应请求中的dataId。'
            },{
                name: 'taskId'
            },{
                name: '字符串'
            },{
                name: '是'
            },{
                name: '该检测任务的ID。'
            },{
                name: 'url'
            },{
                name: '字符串'
            },{
                name: '是'
            },{
                name: '对应请求中的URL。'
            },{
                name: 'extras'
            },{
                name: 'Map'
            },{
                name: '否'
            },{
                name: `额外附加信息。
                图文违规识别场景（scenes包含ad）中，extras可能返回以下内容。
                hitLibInfo：若检测结果命中了自定义文本库，则返回命中的文本库信息。格式为数组，具体结构描述见hitLibInfo。
                示例值：
                "hitLibInfo":[{"context":"好看视频","libCode":"2144002","libName":"预发测试广告相似文本库a"}]`
            },{
                name: 'results'
            },{
                name: '数组'
            },{
                name: '否'
            },{
                name: '返回结果。调用成功时（code=200），返回结果中包含一个或多个元素。每个元素是个结构体，具体结构描述见result。'
            }]}
            />
        },{
            text: '表 2. result'
        },{
            text: <Table 
            headList={['名称','类型','是否必须','描述']}
            dataSource={[{
                name: 'scene'
            },{
                name: '字符串'
            },{
                name: '是'
            },{
                name: '图片检测场景，和调用请求中的场景（scenes）对应。'
            },{
                name: 'label'
            },{
                name: '字符串'
            },{
                name: '是'
            },{
                name: '检测结果的分类，与具体的scene对应。取值范围参见scene和label说明。'
            },{
                name: 'suggestion'
            },{
                name: '字符串'
            },{
                name: '是'
            },{
                name: `建议您执行的操作，取值范围：
                pass：图片正常，无需进行其余操作；或者未识别出目标对象
                review：检测结果不确定，需要进行人工审核；或者识别出目标对象
                block：图片违规，建议执行进一步操作（如直接删除或做限制处理）`
            },{
                name: 'rate'
            },{
                name: '浮点数'
            },{
                name: '是'
            },{
                name: '结果为该分类的概率，取值范围为[0.00-100.00]。值越高，表示越有可能属于该分类。'
            },{
                name: 'frames'
            },{
                name: '数组'
            },{
                name: '否'
            },{
                name: '如果待检测图片因为过长被截断，该参数返回截断后的每一帧图像的临时访问地址，供您参考。具体结构描述见表 3。'
            },{
                name: 'hintWordsInfo'
            },{
                name: '数组'
            },{
                name: '否'
            },{
                name: `图片中含有广告时，返回图片中广告文字命中的风险关键词信息。格式为数组，具体结构描述见hintWordsInfo。
                说明 仅适用于ad场景。
                示例值：
                "hintWordsInfo":[{"context":"敏感词"}]`
            },{
                name: 'qrcodeData'
            },{
                name: '字符串数组'
            },{
                name: '否'
            },{
                name: '图片中含有二维码时，返回图片中所有二维码包含的文本信息。说明 仅适用于qrcode场景。'
            },{
                name: 'programCodeData'
            },{
                name: '数组'
            },{
                name: '否'
            },{
                name: '图片中含有小程序码时，返回小程序码的位置信息，具体结构描述见programCodeData。说明 仅适用于qrcode场景，且已通过工单联系我们开通了小程序码识别。'
            },{
                name: 'logoData'
            },{
                name: '数组'
            },{
                name: '否'
            },{
                name: `图片中含有logo时，返回识别出来的logo信息，具体结构描述见logoData。
                说明 仅适用于logo场景。`
            },{
                name: 'sfaceData'
            },{
                name: '数组'
            },{
                name: '否'
            },{
                name: `图片中包含暴恐识涉政内容时，返回识别出来的暴恐涉政信息，具体结构描述见sfaceData。
                说明 仅适用于terrorism和sface场景。`
            },{
                name: 'ocrData'
            },{
                name: '字符串数组'
            },{
                name: '否'
            },{
                name: '识别到的图片中的完整文字信息。说明 默认不返回，如需返回请通过工单联系我们。'
            }]}
            />
        },{
            text: '表 3. frames'
        },{
            text: <Table 
            headList={['名称','类型','是否必须','描述']}
            dataSource={[{
                name: 'rate'
            },{
                name: '浮点数'
            },{
                name: '是'
            },{
                name: '置信度，仅作参考，不建议使用。'
            },{
                name: 'url'
            },{
                name: '字符串'
            },{
                name: '是'
            },{
                name: '被截断的图片的临时访问url，地址有效期是5分钟。'
            }]}
            />
        },{
            text: '表 4. programCodeData'
        },{
            text: <Table 
            headList={['名称','类型','是否必须','描述']}
            dataSource={[{
                name: 'x'
            },{
                name: '浮点数'
            },{
                name: '是'
            },{
                name: '以图片左上角为坐标原点，小程序码区域左上角到y轴距离。'
            },{
                name: 'y'
            },{
                name: '浮点数'
            },{
                name: '是'
            },{
                name: '以图片左上角为坐标原点，小程序码区域左上角到x轴距离。'
            },{
                name: 'w'
            },{
                name: '浮点数'
            },{
                name: '是'
            },{
                name: '小程序码区域宽度。'
            },{
                name: 'h'
            },{
                name: '浮点数'
            },{
                name: '是'
            },{
                name: '小程序码区域高度。'
            }]}
            />
        },{
            text: '表 5. logoData'
        },{
            text: <Table 
            headList={['名称','类型','是否必须','描述']}
            dataSource={[{
                name: 'type'
            },{
                name: '字符串'
            },{
                name: '是'
            },{
                name: '识别出的logo类型，取值为TV （台标）。'
            },{
                name: 'name'
            },{
                name: '字符串'
            },{
                name: '是'
            },{
                name: '识别出的logo名称。'
            },{
                name: 'x'
            },{
                name: '浮点数'
            },{
                name: '是'
            },{
                name: '以图片左上角为坐标原点，logo区域左上角到y轴距离。'
            },{
                name: 'y'
            },{
                name: '浮点数'
            },{
                name: '是'
            },{
                name: '以图片左上角为坐标原点，logo区域左上角到x轴距离。'
            },{
                name: 'w'
            },{
                name: '浮点数'
            },{
                name: '是'
            },{
                name: 'logo区域宽度。'
            },{
                name: 'h'
            },{
                name: '浮点数'
            },{
                name: '是'
            },{
                name: 'logo区域高度。'
            }]}
            />
        },{
            text: '表 6. sfaceData'
        },{
            text: <Table 
            headList={['名称','类型','是否必须','描述']}
            dataSource={[{
                name: 'x'
            },{
                name: '浮点数'
            },{
                name: '是'
            },{
                name: '以图片左上角为坐标原点，人脸区域左上角到y轴距离。'
            },{
                name: 'y'
            },{
                name: '浮点数'
            },{
                name: '是'
            },{
                name: '以图片左上角为坐标原点，人脸区域左上角到x轴距离。'
            },{
                name: 'w'
            },{
                name: '浮点数'
            },{
                name: '是'
            },{
                name: '人脸区域宽度。'
            },{
                name: 'h'
            },{
                name: '浮点数'
            },{
                name: '是'
            },{
                name: '人脸区域高度。'
            },{
                name: 'faces'
            },{
                name: '数组'
            },{
                name: '否'
            },{
                name: '识别出的人脸信息，具体结构描述见face。'
            }]}
            />
        },{
            text: '表 7. face'
        },{
            text: <Table 
            headList={['名称','类型','是否必须','描述']}
            dataSource={[{
                name: 'name'
            },{
                name: '字符串'
            },{
                name: '否'
            },{
                name: '相似人物的名称。'
            },{
                name: 'rate'
            },{
                name: '浮点数'
            },{
                name: '否'
            },{
                name: '相似概率。'
            },{
                name: 'id'
            },{
                name: '字符串'
            },{
                name: '是'
            },{
                name: '人脸Id。'
            }]}
            />
        },{
            text: '表 8. hitLibInfo'
        },{
            text: <Table 
            headList={['名称','类型','是否必须','描述']}
            dataSource={[{
                name: 'context'
            },{
                name: '字符串'
            },{
                name: '否'
            },{
                name: '图片中文字命中的自定义文本内容。'
            },{
                name: 'libCode'
            },{
                name: '字符串'
            },{
                name: '否'
            },{
                name: '图片中文字命中的自定义文本内容对应的库code。'
            },{
                name: 'libName'
            },{
                name: '字符串'
            },{
                name: '否'
            },{
                name: '图片中文字命中的自定义文本内容对应的库名称。'
            }]}
            />
        },{
            text: '表 9. hintWordsInfo'
        },{
            text: <Table 
            headList={['名称','类型','是否必须','描述']}
            dataSource={[{
                name: 'context'
            },{
                name: '字符串'
            },{
                name: '是'
            },{
                name: '图片中文字命中的风险关键词内容。'
            }]}
            />
        }]
    }],
    navList: [{
        name: "图片同步检测", 
        id: "3-1" 
    },
    {
        name: "接口调用流程", 
        id: "3-1-1"
    },
    {   
        name: "接口地址", 
        id: "3-1-2" 
    },
    { 
        name: "接口描述", 
        id: "3-1-3" 
    },{ 
        name: "请求参数", 
        id: "3-1-4" 
    },{ 
        name: "返回参数", 
        id: "3-1-5" 
    }],
},

{
    id: '3-2',
    title: '文本检测',
    updateTime: _time,
    scrollList: [{
        id: '3-2-1',
        name: '接口调用流程',
        content: [{
            text: <img src={require("../images/pic5.png")} alt="" />
        }]
    },{
        id: '3-2-2',
        name: '接口地址',
        content: [{
            text: 'https://api.hiyanai.com/green/text/scan'
        },]
    },{
        id: '3-2-3',
        name: '接口描述',
        content: [{
            text: '检测文本中是否包含违规信息。'
        },{
            text: '在提交检测任务时，您需要指定scenes场景参数；而在检测返回结果中，则包含了您指定的场景对应的label分类参数。'
        },{
            text: '在文本反垃圾中，scene与label的对应关系如下。'
        },{
            text: <Table 
            headList={['功能','描述','scene','label']}
            dataSource={[{
                name: '垃圾文本检测'
            },{
                name: '结合行为、内容，采用多维度、多模型、多检测手段，识别文本中的垃圾内容，规避色情、广告、灌水、渉政、辱骂等内容风险。'
            },{
                name: 'antispam'
            },{
                name: `normal：正常文本
                spam：含垃圾信息
                ad：广告
                politics：涉政
                terrorism：暴恐
                abuse：辱骂
                porn：色情
                flood：灌水
                contraband：违禁
                meaningless：无意义
                customized：自定义（例如命中自定义关键词）`
            }]}
            />
        }]
    },{
        id: '3-2-4',
        name: '请求参数',
        content: [{
            text: '关于在请求中必须包含的公共请求参数，请参见公共参数。'
        },{
            text: '请求body是一个JSON对象，字段说明如下：'
        },{
            text: <Table 
            headList={['名称','类型','是否必须','描述']}
            dataSource={[{
                name: 'bizType'
            },{
                name: '字符串'
            },{
                name: '否'
            },{
                name: '该字段用于标识业务场景。针对不同的业务场景，您可以配置不同的内容审核策略，以满足不同场景下不同的审核标准或算法策略的需求。您可以通过云盾内容安全控制台创建业务场景（bizType），或者通过工单联系我们帮助您创建业务场景。'
            },{
                name: 'scenes'
            },{
                name: '字符串数组'
            },{
                name: '是'
            },{
                name: '指定检测场景，取值：antispam。'
            },{
                name: 'tasks'
            },{
                name: 'JSON数组'
            },{
                name: '是'
            },{
                name: '文本检测任务列表，包含一个或多个元素。每个元素是个结构体，最多可添加100个元素，即最多对100段文本进行检测。每个元素的具体结构描述请参见task。'
            }]}
            />
        },{
            text: '表 1. task'
        },{
            text: <Table 
            headList={['名称','类型','是否必须','描述']}
            dataSource={[{
                name: 'dataId'
            },{
                name: '字符串'
            },{
                name: '否'
            },{
                name: '数据Id。需要保证在一次请求中所有的ID不重复。'
            },{
                name: 'content'
            },{
                name: '字符串'
            },{
                name: '是'
            },{
                name: '待检测文本，最长10000个中文字符（含标点）。'
            }]}
            />
        }]
    },{
        id: '3-2-5',
        name: '返回参数',
        content: [{
            text: <Table 
            headList={['名称','类型','是否必须','描述']}
            dataSource={[{
                name: 'code'
            },{
                name: '整型'
            },{
                name: '是'
            },{
                name: '错误码，和HTTP的status code一致。'
            },{
                name: 'msg'
            },{
                name: '字符串'
            },{
                name: '是'
            },{
                name: '错误描述信息。'
            },{
                name: 'dataId'
            },{
                name: '字符串'
            },{
                name: '否'
            },{
                name: '检测对象对应的数据ID。说明 如果在请求参数中传入了dataId，则此处返回对应的dataId。'
            },{
                name: 'taskId'
            },{
                name: '字符串'
            },{
                name: '是'
            },{
                name: '该检测任务的ID。'
            },{
                name: 'content'
            },{
                name: '字符串'
            },{
                name: '否'
            },{
                name: '对应请求的内容。'
            },{
                name: 'filteredContent'
            },{
                name: '字符串'
            },{
                name: '否'
            },{
                name: '如果检测文本命中您自定义关键词词库中的词，该字段会返回，并将命中的关键词替换为“*”。'
            },{
                name: 'results'
            },{
                name: '数组'
            },{
                name: '否'
            },{
                name: '返回结果。调用成功时（code=200），返回结果中包含一个或多个元素。每个元素是个结构体，具体结构描述见result。'
            }]}
            />
        },{
            text: '表 2. result'
        },{
            text: <Table 
            headList={['名称','类型','是否必须','描述']}
            dataSource={[{
                name: 'scene'
            },{
                name: '字符串'
            },{
                name: '是'
            },{
                name: '检测场景，和调用请求中的场景对应。'
            },{
                name: 'suggestion'
            },{
                name: '字符串'
            },{
                name: '是'
            },{
                name: `建议用户执行的操作，取值范围：
                pass：文本正常
                review：需要人工审核
                block：文本违规，可以直接删除或者做限制处理`
            },{
                name: 'label'
            },{
                name: '字符串'
            },{
                name: '是'
            },{
                name: '检测结果的分类，与具体的scene对应。取值范围请参见scene 和 label说明。'
            },{
                name: 'rate'
            },{
                name: '浮点数'
            },{
                name: '是'
            },{
                name: '结果为该分类的概率，取值范围为[0.00-100.00]。值越高，表示越有可能属于该分类。说明 分值仅供参考，您需要关注label和suggestion内容。'
            },{
                name: 'extras'
            },{
                name: 'JSON对象'
            },{
                name: '否'
            },{
                name: '附加信息，扩展字段。'
            },{
                name: 'details'
            },{
                name: '数组'
            },{
                name: '否'
            },{
                name: '命中风险的详细信息，一条文本可能命中多条风险详情。具体结构描述请参见detail。'
            }]}
            />
        },{
            text: '表 3. detail'
        },{
            text: <Table 
            headList={['名称','类型','是否必须','描述']}
            dataSource={[{
                name: 'label'
            },{
                name: '字符串'
            },{
                name: '是'
            },{
                name: '文本命中风险的分类，与具体的scene对应。取值范围请参见scene 和 label说明。'
            },{
                name: 'contexts'
            },{
                name: '数组'
            },{
                name: '否'
            },{
                name: '命中该风险的上下文信息。具体结构描述请参见context。'
            },{
                name: 'hintWords'
            },{
                name: 'JSON数组'
            },{
                name: '否'
            },{
                name: '文本命中的关键词信息，用于提示您违规的原因，可能会返回多个命中的关键词。具体结构描述请参见hintWord。'
            }]}
            />
        },{
            text: '表 4. context'
        },{
            text: <Table 
            headList={['名称','类型','是否必须','描述']}
            dataSource={[{
                name: 'context'
            },{
                name: '字符串'
            },{
                name: '是'
            },{
                name: '检测文本命中的风险内容上下文内容。如果命中了您自定义的风险文本库，则会返回命中的文本内容（关键词或相似文本）。'
            },{
                name: 'libName'
            },{
                name: '字符串'
            },{
                name: '否'
            },{
                name: '命中自定义词库时，才有本字段。取值为创建词库时填写的词库名称。'
            },{
                name: 'libCode'
            },{
                name: '字符串'
            },{
                name: '否'
            },{
                name: '命中您自定义文本库时，才会返回该字段，取值为创建风险文本库后系统返回的文本库code。'
            },{
                name: 'ruleType'
            },{
                name: '字符串'
            },{
                name: '否'
            },{
                name: `命中行为规则时，才有该字段。可能取值包括：
                user_id
                ip
                umid
                content
                similar_content
                imei
                imsi`
            }]}
            />
        },{
            text: '表 5. hintWord'
        },{
            text: <Table 
            headList={['名称','类型','是否必须','描述']}
            dataSource={[{
                name: 'context'
            },{
                name: '字符串'
            },{
                name: '是'
            },{
                name: '文本命中的系统关键词内容。'
            }]}
            />
        }]
    }],
    navList: [{
        name: "文本检测", 
        id: "3-2" 
    },
    {
        name: "接口调用流程", 
        id: "3-2-1"
    },
    {   
        name: "接口地址", 
        id: "3-2-2" 
    },
    { 
        name: "接口描述", 
        id: "3-2-3" 
    },{ 
        name: "请求参数", 
        id: "3-2-4" 
    },{ 
        name: "返回参数", 
        id: "3-2-5" 
    }],
},

{
    id: '3-3',
    title: '视频异步检测',
    updateTime: _time,
    scrollList: [{
        id: '3-3-1',
        name: '接口调用流程',
        content: [{
            text: <img src={require("../images/pic6.png")} alt="" />
        }]
    },{
        id: '3-3-2',
        name: '接口地址',
        content: [{
            text: 'https://api.hiyanai.com/green/video/asyncscan'
        },]
    },{
        id: '3-3-3',
        name: '接口描述',
        content: [{
            text: '异步检测视频文件或视频流中是否包含违规内容。支持检测的场景包括：视频智能鉴黄、视频暴恐涉政识别、视频图文违规识别、视频不良场景识别、视频logo识别、视频语音违规内容识别。同时检测多个场景的情况下，将按照“每个场景的检测视频截帧数量×每个场景的单价”进行累加计费。'
        },{
            text: '异步检测结果需要通过调用结果查询接口进行查询（具体请参见查询视频异步检测结果）'
        },{
            text: 'scene与label参数, 在提交检测任务时，您需要指定scenes场景参数；而在检测返回结果中，则包含您指定的场景对应的label结果分类参数。在视频审核中，scene与label的对应关系如下。'
        },{
            text: <Table 
            headList={['场景','描述','scene','label']}
            dataSource={[{
                name: '视频智能鉴黄'
            },{
                name: '识别短视频是否为色情视频。'
            },{
                name: 'porn'
            },{
                name: `normal：正常视频
                porn：色情视频`
            },{
                name: '视频暴恐涉政识别'
            },{
                name: '识别短视频是否为暴恐涉政视频。'
            },{
                name: 'terrorism'
            },{
                name: `normal：正常视频
                terrorism：暴恐涉政视频`
            },{
                name: '视频不良场景识别'
            },{
                name: '识别短视频中的不良场景。'
            },{
                name: 'live'
            },{
                name: `normal：正常视频
                live：包含不良场景的视频`
            },{
                name: '视频logo识别'
            },{
                name: '识别短视频中的特定logo。'
            },{
                name: 'logo'
            },{
                name: `normal：正常视频
                logo：带有logo的视频`
            },{
                name: '视频图文违规识别'
            },{
                name: '识别视频画面中包含的广告或者文字违规内容。'
            },{
                name: 'ad'
            },{
                name: `normal：正常视频
                ad：包含广告的视频`
            }]}
            />
        }]
    },{
        id: '3-3-4',
        name: '请求参数',
        content: [{
            text: '关于在请求中必须包含的公共请求参数，请参见公共参数。'
        },{
            text: '请求body是一个JSON对象，字段说明如下：'
        },{
            text: <Table 
            headList={['名称','类型','是否必须','描述']}
            dataSource={[{
                name: 'bizType'
            },{
                name: '字符串'
            },{
                name: '否'
            },{
                name: '该字段用于标识业务场景。针对不同的业务场景，您可以配置不同的内容审核策略，以满足不同场景下不同的审核标准或算法策略的需求。您可以通过云盾内容安全控制台创建业务场景（bizType），或者通过工单联系我们帮助您创建业务场景。'
            },{
                name: 'scenes'
            },{
                name: '字符串数组'
            },{
                name: '是'
            },{
                name: `指定视频检测的应用场景，可选值包括：
                porn：视频智能鉴黄
                terrorism：视频暴恐涉政识别
                ad：视频图文违规识别
                live：视频不良场景识别
                logo：视频logo识别`
            },{
                name: 'tasks'
            },{
                name: 'JSON数组'
            },{
                name: '是'
            },{
                name: 'JSON数组中的每个元素是一个结构体（Video表）。每个元素的具体结构描述请参见task。'
            }]}
            />
        },{
            text: '表 1. task'
        },{
            text: <Table 
            headList={['名称','类型','是否必须','描述']}
            dataSource={[{
                name: 'dataId'
            },{
                name: '字符串'
            },{
                name: '否'
            },{
                name: '数据ID。需要保证在一次请求中所有的ID不重复。'
            },{
                name: 'frames'
            },{
                name: 'JSON数组'
            },{
                name: '否'
            },{
                name: '截帧信息。frames中的每个元素是个结构体（Frame表），每个元素的具体结构描述请参见frame。'
            }]}
            />
        },{
            text: '表 2. frame'
        },{
            text: <Table 
            headList={['名称','类型','是否必须','描述']}
            dataSource={[{
                name: 'url'
            },{
                name: '字符串'
            },{
                name: '是'
            },{
                name: '截帧图片地址。'
            },{
                name: 'offset'
            },{
                name: '整型'
            },{
                name: '否'
            },{
                name: '该截帧距离片头的时间戳，单位为秒。'
            }]}
            />
        }]
    },{
        id: '3-3-5',
        name: '返回参数',
        content: [{
            text: <Table 
            headList={['名称','类型','是否必须','描述']}
            dataSource={[{
                name: 'taskId'
            },{
                name: '字符串'
            },{
                name: '是'
            },{
                name: '该检测任务的ID。'
            },{
                name: 'dataId'
            },{
                name: '字符串'
            },{
                name: '否'
            },{
                name: '检测对象对应的数据ID。说明 如果在请求参数中传入了dataId，则此处返回对应的dataId。'
            }]}
            />
        }]
    }],
    navList: [{
        name: "视频异步检测", 
        id: "3-3" 
    },
    {
        name: "接口调用流程", 
        id: "3-3-1"
    },
    {   
        name: "接口地址", 
        id: "3-3-2" 
    },
    { 
        name: "接口描述", 
        id: "3-3-3" 
    },{ 
        name: "请求参数", 
        id: "3-3-4" 
    },{ 
        name: "返回参数", 
        id: "3-3-5" 
    }],
},

{
    id: '3-4',
    title: '查询视频异步检测结果',
    updateTime: _time,
    scrollList: [{
        id: '3-4-1',
        name: '业务接口',
        content: [{
            text: 'https://api.hiyanai.com/green/video/results'
        },]
    },{
        id: '3-4-2',
        name: '接口描述',
        content: [{
            text: `查询异步检测的结果。建议您将查询间隔设置为30秒，最长不能超过4个小时，否则结果将会消失。`
        },{
            text: '说明 该接口为免费接口。'
        }]
    },{
        id: '3-4-3',
        name: '请求参数',
        content: [{
            text: <Table 
            headList={['名称','类型','是否必须','描述']}
            dataSource={[{
                name: 'body'
            },{
                name: 'JSON数组'
            },{
                name: '是'
            },{
                name: '要查询的taskId列表。最大长度不超过100。'
            }]}
            />
        }]
    },{
        id: '3-4-4',
        name: '返回参数',
        content: [{
            text: <Table 
            headList={['名称','类型','是否必须','描述']}
            dataSource={[{
                name: 'code'
            },{
                name: '整型'
            },{
                name: '是'
            },{
                name: '错误码，和HTTP的status code一致。'
            },{
                name: 'msg'
            },{
                name: '字符串'
            },{
                name: '是'
            },{
                name: '错误描述信息。'
            },{
                name: 'dataId'
            },{
                name: '字符串'
            },{
                name: '否'
            },{
                name: '检测对象对应的数据ID。说明 如果在请求参数中传入了dataId，则此处返回对应的dataId。'
            },{
                name: 'taskId'
            },{
                name: '字符串'
            },{
                name: '是'
            },{
                name: '该检测任务的ID。'
            },{
                name: 'results'
            },{
                name: '数组'
            },{
                name: '否'
            },{
                name: `返回结果，调用成功时（code=200），返回结果中包含一个或多个元素。每个元素是个结构体，具体结构描述请参见result。
                说明 拉流检测场景中，code返回280表示在检测中，返回200表示检测完成。在检测中状态时，检测结果中包含从开始检测到当前时间检测到的问题。`
            }]}
            />
        },{
            text: '表 3. result'
        },{
            text: <Table 
            headList={['名称','类型','是否必须','描述']}
            dataSource={[{
                name: 'scene'
            },{
                name: '字符串'
            },{
                name: '是'
            },{
                name: '视频检测场景，和调用请求中的场景对应。'
            },{
                name: 'label'
            },{
                name: '字符串'
            },{
                name: '是'
            },{
                name: '检测结果的分类，与具体的scene对应。取值请参见scene 和 label说明。'
            },{
                name: 'suggestion'
            },{
                name: '字符串'
            },{
                name: '是'
            },{
                name: `建议您执行的操作，取值范围：
                pass：视频正常，无需进行其余操作，或者未识别出目标对象
                review：检测结果不确定，需要进行人工审核，或识别出目标对象
                block：视频违规，建议执行进一步操作（直接删除或做限制处理）`
            },{
                name: 'rate'
            },{
                name: '浮点数'
            },{
                name: '是'
            },{
                name: '结果为该分类的概率，取值范围为0.00~100.00。值越高，表示越有可能属于该分类。'
            },{
                name: 'frames'
            },{
                name: 'JSON数组'
            },{
                name: '否'
            },{
                name: '判断为该分类的一些截帧检测结果（FrameScanResult），截帧检测结果表的具体结构描述请参见frame。'
            },{
                name: 'extras'
            },{
                name: 'JSON对象'
            },{
                name: '否'
            },{
                name: `额外附加信息。
                图文违规识别场景（scenes包含ad）中，extras可能返回以下内容。
                hitLibInfo：若检测结果命中了自定义文本库，则返回命中的文本库信息。格式为数组，具体结构描述请参见hitLibInfo。
                示例值：
                "hitLibInfo":[{"context":"好看视频","libCode":"2144002","libName":"预发测试广告相似文本库a"}]`
            },{
                name: 'hintWordsInfo'
            },{
                name: '数组'
            },{
                name: '否'
            },{
                name: `视频中含有广告时，返回视频中广告文字命中的风险关键词信息。格式为数组，具体结构描述请参见hintWordsInfo。
                说明 仅适用于ad场景。
                示例值：
                "hintWordsInfo":[{"context":"敏感词"}]`
            },{
                name: 'logoData'
            },{
                name: '数组'
            },{
                name: '否'
            },{
                name: '图片中含有logo时，返回识别出来的logo信息，具体结构描述请参见logoData。说明 仅适用于logo场景。'
            },{
                name: 'sfaceData'
            },{
                name: '数组'
            },{
                name: '否'
            },{
                name: '视频中包含暴恐识涉政内容时，返回识别出来的暴恐涉政信息，具体结构描述请参见sfaceData。说明 仅适用于terrorism和sface场景（scene）。关于该参数在sface场景下的具体内容，请参见视频敏感人脸识别。'
            }]}
            />
        },{
            text: '表 4. frame'
        },{
            text: <Table 
            headList={['名称','类型','是否必须','描述']}
            dataSource={[{
                name: 'url'
            },{
                name: '字符串'
            },{
                name: '否'
            },{
                name: '截帧地址。说明 若开启了视频证据转存，则返回转存的视频截帧的OSS URL链接。更多信息，请参见证据转存。'
            },{
                name: 'offset'
            },{
                name: '整型'
            },{
                name: '否'
            },{
                name: '该截帧距离片头的时间戳，单位为秒。'
            },{
                name: 'label'
            },{
                name: '字符串'
            },{
                name: '是'
            },{
                name: '该视频截帧的风险分类，取值范围请参见scene和label参数说明。'
            },{
                name: 'rate'
            },{
                name: '浮点数'
            },{
                name: '是'
            },{
                name: '该视频截帧结果为label所述分类的概率，取值范围为0.00~100.00。值越高，表示越有可能属于该分类。'
            }]}
            />
        },{
            text: '表 5. logoData'
        },{
            text: <Table 
            headList={['名称','类型','是否必须','描述']}
            dataSource={[{
                name: 'type'
            },{
                name: '字符串'
            },{
                name: '是'
            },{
                name: '识别出的logo类型，取值为TV （台标）。'
            },{
                name: 'name'
            },{
                name: '字符串'
            },{
                name: '是'
            },{
                name: '识别出的logo名称。'
            },{
                name: 'x'
            },{
                name: '浮点数'
            },{
                name: '是'
            },{
                name: '以图片左上角为坐标原点，logo区域左上角到y轴距离。'
            },{
                name: 'y'
            },{
                name: '浮点数'
            },{
                name: '是'
            },{
                name: '以图片左上角为坐标原点，logo区域左上角到x轴距离。'
            },{
                name: 'w'
            },{
                name: '浮点数'
            },{
                name: '是'
            },{
                name: 'logo区域宽度。'
            },{
                name: 'h'
            },{
                name: '浮点数'
            },{
                name: '是'
            },{
                name: 'logo区域高度。'
            }]}
            />
        },{
            text: '表 6. sfaceData'
        },{
            text: <Table 
            headList={['名称','类型','是否必须','描述']}
            dataSource={[{
                name: 'x'
            },{
                name: '浮点数'
            },{
                name: '是'
            },{
                name: '以图片左上角为坐标原点，人脸区域左上角到y轴距离。'
            },{
                name: 'y'
            },{
                name: '浮点数'
            },{
                name: '是'
            },{
                name: '以图片左上角为坐标原点，人脸区域左上角到x轴距离。'
            },{
                name: 'w'
            },{
                name: '浮点数'
            },{
                name: '是'
            },{
                name: '人脸区域宽度。'
            },{
                name: 'h'
            },{
                name: '浮点数'
            },{
                name: '是'
            },{
                name: '人脸区域高度。'
            },{
                name: 'faces'
            },{
                name: '数组'
            },{
                name: '否'
            },{
                name: '识别出的人脸信息，具体结构描述请参见face。'
            }]}
            />
        },{
            text: '表 7. face'
        },{
            text: <Table 
            headList={['名称','类型','是否必须','描述']}
            dataSource={[{
                name: 'name'
            },{
                name: '字符串'
            },{
                name: '否'
            },{
                name: '相似人物的名称。'
            },{
                name: 'rate'
            },{
                name: '浮点数'
            },{
                name: '否'
            },{
                name: '相似概率。'
            },{
                name: 'id'
            },{
                name: '字符串'
            },{
                name: '是'
            },{
                name: '人脸Id。'
            }]}
            />
        },{
            text: '表 10. hitLibInfo'
        },{
            text: <Table 
            headList={['名称','类型','是否必须','描述']}
            dataSource={[{
                name: 'context'
            },{
                name: '字符串'
            },{
                name: '否'
            },{
                name: '视频中文字命中的自定义文本内容。'
            },{
                name: 'libCode'
            },{
                name: '字符串'
            },{
                name: '否'
            },{
                name: '视频中文字命中的自定义文本内容对应的库code。'
            },{
                name: 'libName'
            },{
                name: '字符串'
            },{
                name: '否'
            },{
                name: '视频中文字命中的自定义文本内容对应的库名称。'
            }]}
            />
        },{
            text: '表 11. hintWordsInfo'
        },{
            text: <Table 
            headList={['名称','类型','是否必须','描述']}
            dataSource={[{
                name: 'context'
            },{
                name: '字符串'
            },{
                name: '是'
            },{
                name: '视频中文字命中的风险关键词内容。'
            }]}
            />
        }]
    }],
    navList: [{
        name: "查询视频异步检测结果", 
        id: "3-4" 
    },
    {
        name: "业务接口", 
        id: "3-4-1"
    },
    { 
        name: "接口描述", 
        id: "3-4-2" 
    },{ 
        name: "请求参数", 
        id: "3-4-3" 
    },{ 
        name: "返回参数", 
        id: "3-4-4" 
    }],
}]


export {
    datas
}
