import { arrow } from '../../asstes/images';

const datas = {
    title: '内容审核',
    list: [{
        id: '1',
        showTitle: '内容审核API',
        img: arrow,
        isShowChildren: false,
        children: [{
            id: '1-0',
            name: '规范说明',
            isChecked: false
        },{
            id: '1-0--1',
            name: '接口鉴权',
            isChecked: false
        },{
            id: '1-1',
            name: '图片检测接口',
            isChecked: false
        },{
            id: '1-2',
            name: '视频点播信息提交',
            isChecked: false
        },{
            id: '1-3',
            name: '视频点播检测结果获取',
            isChecked: false
        },{
            id: '1-4',
            name: '文本识别接口',
            isChecked: false
        },{
            id: '1-5',
            name: '离线查询接口',
            isChecked: false
        }, 
        {
            id: '1-6',
            showTitle: '兼容阿里接口',
            img: arrow,
            isShowChildren: false,
            children: [{
                id: '3-0',
                name: '接口规范说明',
                isChecked: false
            },{
                id: '3-1',
                name: '图片同步检测',
                isChecked: false
            },{
                id: '3-2',
                name: '文本检测',
                isChecked: false
            },{
                id: '3-3',
                name: '视频异步检测',
                isChecked: false
            },{
                id: '3-4',
                name: '查询视频异步检测结果',
                isChecked: false
            }]
        },
        {
            id: '1-7',
            showTitle: '兼容网易接口',
            img: arrow,
            isShowChildren: false,
            children: [{
                id: '1-0',
                name: '规范说明',
                isChecked: false
            },{
                id: '1-0--1',
                name: '接口鉴权',
                isChecked: false
            },{
                id: '1-1',
                name: '图片检测接口',
                isChecked: false
            },{
                id: '1-2',
                name: '视频点播信息提交',
                isChecked: false
            },{
                id: '1-3',
                name: '视频点播检测结果获取',
                isChecked: false
            },{
                id: '1-4',
                name: '文本识别接口',
                isChecked: false
            },{
                id: '1-5',
                name: '离线查询接口',
                isChecked: false
            }]
        }
    ]},
    /*
    {
        id: '3',
        showTitle: '兼容阿里接口',
        img: arrow,
        isShowChildren: false,
        children: [{
            id: '3-0',
            name: '接口规范说明',
            isChecked: false
        },{
            id: '3-1',
            name: '图片同步检测',
            isChecked: false
        },{
            id: '3-2',
            name: '文本检测',
            isChecked: false
        },{
            id: '3-3',
            name: '视频异步检测',
            isChecked: false
        },{
            id: '3-4',
            name: '查询视频异步检测结果',
            isChecked: false
        }]
    },
    */
    {
        id: '2',
        showTitle: '人证核验API',
        img: arrow,
        isShowChildren: false,
        children: [{
            id: '2-0',
            name: '规范说明',
            isChecked: false
        },{
            id: '2-0--1',
            name: '接口鉴权',
            isChecked: false
        },{
            id: '2-1',
            name: '授权token 获取接口',
            isChecked: false
        },{
            id: '2-2',
            name: '人脸识别',
            isChecked: false
        },{
            id: '2-3',
            name: '人脸对比',
            isChecked: false
        },{
            id: '2-4',
            name: '人证合一简单检测',
            isChecked: false
        },{
            id: '2-5',
            name: '人证合一照片检测',
            isChecked: false
        },{
            id: '2-6',
            name: '人证合一静默视频检测',
            isChecked: false
        },{
            id: '2-7',
            name: '人证合一动作获取',
            isChecked: false
        },{
            id: '2-8',
            name: '人证合一动作视频检测',
            isChecked: false
        }]
    }]
}

export { datas };
