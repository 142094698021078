/*
 * @Author: chenyu chenyu@hiyanai.com
 * @Date: 2024-01-11 10:55:20
 * @LastEditors: chenyu chenyu@hiyanai.com
 * @LastEditTime: 2024-01-11 16:18:35
 * @FilePath: \hiyan_pc_web\src\datas\products\application-scenarios.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { 
    yycj1_1,
    yycj1_2,
    yycj2_1,
    yycj2_2,
    yycj2_3,
    yycj2_4,
    yycj3_1,
    yycj3_2,
    yycj4_1,
    yycj4_2,
    yycj4_3,
    yycj5_1,
    yycj5_2,
    yycj5_3,
 } from '../../asstes/images';

const datas = [{
    id: 0,
    list: [{
        id: '1',
        img: yycj1_1,
    },{
        id: '2',
        img: yycj1_2,
    }]
},{
    id: 1,
    list: [{
        id: '1',
        img: yycj2_1,
    },{
        id: '2',
        img: yycj2_2,
    },{
        id: '3',
        img: yycj2_3,
    },{
        id: '4',
        img: yycj2_4,
    }]
},{
    id: 2,
    list: [{
        id: '1',
        img: yycj3_1,
    },{
        id: '2',
        img: yycj3_2,
    }]
},{
    id: 3,
    list: [{
        id: '1',
        img: yycj4_1,
    },{
        id: '2',
        img: yycj4_2,
    },{
        id: '3',
        img: yycj4_3,
    }]
},{
    id: 4,
    list: [{
        id: '1',
        img: yycj5_1,
    },{
        id: '2',
        img: yycj5_2,
    },{
        id: '3',
        img: yycj5_3,
    }]
}]

export { datas };