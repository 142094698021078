/*
 * @Author: your name
 * @Date: 2021-10-11 17:30:21
 * @LastEditTime: 2021-10-15 11:30:37
 * @LastEditors: Please set LastEditors
 * @Description: 加入我们
 * @FilePath: \hiyan_pc_web\src\views\join-us\index.js
 */
import React,{useEffect} from 'react';
import { joinUsList } from '../../datas/join-us/join-us';
import { useLocation } from "react-router";
import './index.scss';
import Banner from '../../components/banner';
import { Steps } from 'antd';

function JoinUs() {
    const {backgroudImage,title,tag,hotJob,welfare,recruitmentProcess,resumeDelivery}=joinUsList;
    const { Step } = Steps;
    const banner={
        backgroundImage: backgroudImage,
        copywriting: {
            title: title,
            content:  tag
        }
    }
    function useScrollToTop() { 
        const { pathname } = useLocation();
        useEffect(() => {
            const layout = document.querySelector(".layout-main");
            if (layout) {
                console.log('JoinUs');
                layout.scrollTop = 200;
            }
        }, [pathname]);

        return null;
    };

    useScrollToTop();

    function renderHotJonList() {
        return (hotJob.jonInfo || []).map(({ station,desc,requirement}) => (
            <div className="join-us-job-item" key={station}>
                 <div className="join-us-job-item-station">{station}</div>
                 <div className="join-us-job-item-txt">{hotJob.desctitle}</div>
                 <div className="join-us-job-item-desc-main">
                     {renderDescList(desc)}
                 </div>
                 <div className="join-us-job-item-txt">{hotJob.requirementTitle }</div>
                 <div className="join-us-job-item-desc-main">
                     {renderDescList(requirement)}
                 </div>
            </div>
        ))
    }

    function renderDescList(list) {
        return (list || []).map((item,index) => (
            <div className="join-us-job-item-desc-item" key={index}>
                <div className="join-us-job-item-ico-container">
                   <div className="join-us-job-item-ico"></div>
                </div>
                <div className="join-us-job-item-desc">{item}</div>
            </div>
        ))
    }

    function renderProcessList(list) {
        return (recruitmentProcess.list || []).map(({id,process}) => (
            id===6?
             <div className="join-us-process-path-item active" key="process">
                 <div className="join-us-path-item-text">{process}</div>
                 <div className="join-us-path-item-process">
                     <div className="join-us-path-item-process-circle"></div>
                 </div>
            </div>
            :
            <div className="join-us-process-path-item" key={process}>
                 {id===5?<div className="join-us-path-item-text active">{process}</div>
                 :id===4?<div className="join-us-path-item-text other">{process}</div>
                 :<div className="join-us-path-item-text">{process}</div>}
                 <div className="join-us-path-item-process">
                     <div className="join-us-path-item-process-circle"></div>
                     <div className="join-us-path-item-process-line"></div>
                 </div>
            </div>
        ))
    }

    return (
        <div id="join-us">
            <Banner {...banner}/>
            <div className="join-us-contaniner">
                <div className="join-us-content">
                    <div className="join-us-content-title" id="join-us-title">
                         {hotJob.head}
                    </div>
                    <div className="join-us-content-line"></div>
                    <div className="join-us-job-container">
                        {renderHotJonList()}
                    </div>
                    <div className="join-us-welfare-container">
                        <div className="join-us-content-title">
                            {welfare.head}
                        </div>
                        <div className="join-us-content-line active"></div>
                        <img src={welfare.img}></img>
                    </div>
                    <div className="join-us-process-container">
                        <div className="join-us-process-head">{recruitmentProcess.head}</div>
                        <div className="join-us-process-path">{renderProcessList()}</div>  
                    </div>
                    <div className="join-us-email-container">
                        <div className="join-us-email-container-title">{resumeDelivery.head}</div>
                        <div className="join-us-email-container-detail">{resumeDelivery.email}</div>
                    </div>
                </div>
           </div>
        </div>
    );
}

export default JoinUs;