import React from 'react';
/**
 * @description: 表格
 * @param {props headList:['a', 'b', 'c'...依次按照顺序] dataSource:[{name: 'a'},{name: 'b'},{name: 'c'}...依次按照顺序的一维数组]} 
 * @return: 
 */ 
function Table({ headList = [], dataSource = [] }) {

    function renderThead() {
        let thead = <thead>
            <tr>
                {
                    (headList || []).map((item, index) => (
                        <th key={index} style={{padding:10,whiteSpace:'nowrap'}}>{item}</th>
                    ))
                }
            </tr>
        </thead>
        return thead;
    }

    function renderTbody() {

        let len = dataSource.length,
            n = headList.length,
            lineNum = len % n === 0 ? len / n : Math.floor((len / n) + 1),
            res = [];
        // 按照表头的长度将一维数组转化为二维数组
        for (let i = 0; i < lineNum; i++) {
            let temp = dataSource.slice(i * n, i * n + n);
            res.push(JSON.parse(JSON.stringify(temp)));
        }
        
        const listResult = res.map((item, index) => (
            <tr key={index}>
                {
                    item.map((_item, i) => (
                        <td key={i} style={{padding:10}}>{_item.name}</td>
                    ))
                }
            </tr>
        ))
        return <tbody>
            {listResult}
        </tbody>
    }

    return (
        <div style={{lineHeight: '30px', textIndent: 0}}>
            <table border="1" cellPadding="0" cellSpacing="0" width="100%">
                { renderThead() }
                { renderTbody() }
            </table>
        </div>
    )
}

export default Table;
