import React, { Component } from 'react';
import './team.scss'
import { TeamIcon } from '../../asstes/about-us'
import { datas } from '../../datas/about-us/about-us';
export default class Team extends Component {
    state = {
        teaminfo: datas.teamInfo.list
    }
    render() {
        const team = this.state.teaminfo
        return (
            <div className='team_container'>
                <div className='con_left'>
                    <img src={TeamIcon} width="30" height="33" alt='jj' />
                    <span>技术团队</span>
                </div>
                <div className='con_right'>
                    <div className='ditem'>
                        <div className='xitem'>
                            <img src={team[0].icon} width="80" height="80" alt='ren' />
                            <div className='desc'>
                                <div className='desc_title'>
                                    <span>{team[0].name}</span><span>{team[0].title}</span>
                                </div>
                                <div className='descp'>
                                    <p>{team[0].desc}</p>
                                </div>
                            </div>
                        </div>
                        <div className='xitem'>
                            <img src={team[1].icon} width="80" height="80" alt='ren' />
                            <div className='desc'>
                                <div className='desc_title'>
                                    <span>{team[1].name}</span><span>{team[1].title}</span>
                                </div>
                                <div className='descp'>
                                    <p>{team[1].desc}</p>
                                </div>
                            </div>
                        </div>
                        {/* </div>
                    <div className='ditem'> */}
                        <div className='xitem'>
                            <img src={team[2].icon} width="80" height="80" alt='ren' />
                            <div className='desc'>
                                <div className='desc_title'>
                                    <span>{team[2].name}</span><span>{team[2].title}</span>
                                </div>
                                <div className='descp'>
                                    <p>{team[2].desc}</p>
                                </div>
                            </div>
                        </div>
                        {/* <div className='xitem'>
                            <img src={team[3].icon} width="80" height="80" alt='ren' />
                            <div className='desc'>
                                <div className='desc_title'>
                                    <span>{team[3].name}</span><span>{team[3].title}</span>
                                </div>
                                <div className='descp'>
                                    <p>{team[3].desc}</p>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        )
    }
}