/*
 * @Author: your name
 * @Date: 2021-10-12 16:08:01
 * @LastEditTime: 2021-11-01 15:49:05
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \hiyan_pc_web\src\views\about-us-contact\index.js
 */
import React, { useEffect } from 'react';
import Banner from '../../components/banner';
import { datas } from '../../datas/about-us/about-us';
import './index.scss';

/**
 * @description: 关于我们
 * @param {}
 * @return: 
 */
 

function AboutUsContact() {

    const { contactInfo: { title: contactInfoTitle,tag,coordinate,list,backgroudImage,addressImage}} = datas;
    const banner={
        backgroundImage: backgroudImage,
        copywriting: {
            title: contactInfoTitle,
            content:  tag
        }
    }
    /**
     * @description: 渲染联系我们列表
     * @param {}
     * @return: list
     */
    function renderContactList() {
        return (list || []).map(({ id, icon,tit,text}) => (
            <div className="card-detail-information-item" key={id}>
               <img src={icon} className="card-detail-information-img"></img>
               <div className="card-detail-information-label">{tit}</div>
               <div className="card-detail-information-form">{text}</div>
            </div>
        ))
    }

    useEffect(() => {
        renderMap();
    }, []);


    const renderMap =  () => {
        let map=new window.BMap.Map("orderDetailMap"); 
        console.log(coordinate);
        let point=new window.BMap.Point(coordinate.split(',')[0], coordinate.split(',')[1]);
        map.centerAndZoom(point, 17);
        map.enableScrollWheelZoom();
        // var opts = {    
        //     width : 90,
        //     height: 30,
        //     enableCloseOnClick:false,
        // }

        // var infoWindow = new window.BMap.InfoWindow("海创基地北楼", opts);  // 创建信息窗口对象  
        // // var info=new window.BMap.infoBox();
        // var marker = new window.BMap.Marker(point);        // 创建标注    
        // map.addOverlay(marker);
        // marker.addEventListener('click', function () {
        //     map.openInfoWindow(infoWindow, point); // 开启信息窗口
        // });
        // map.openInfoWindow(infoWindow, map.getCenter()); 
        console.log('address',addressImage);
        var icon = new window.BMap.Icon(addressImage,new window.BMap.Size(148, 52));
        var markers = new window.BMap.Marker(point);
        markers.setIcon(icon);
        map.addOverlay(markers);
    };  
    
    return (
        <div id="about-us-contact">
            <Banner {...banner}/>
            <div className="about-us-contact-contaniner">
                <div className="about-us-contact-content">
                    <div className="about-us-contact-content-card">
                        <div className="contact-content-card-detail">
                            <div className="contact-content-card-detail-title">{contactInfoTitle}</div>
                            <div className="contact-content-card-detail-information">
                                {renderContactList()}
                            </div>
                        </div>
                        <div className="contact-content-card-map" id="orderDetailMap"></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutUsContact;
