import React from 'react';
import './index.scss';
import {
    newsDetailBanner1,
    newsDetailBanner2,
    newsDetailBanner3,
    newsDetailBanner4,
} from '../../asstes/banners';
import {
    Jiashicang,
    Banmaxianwf,
    Suidaokouwf,
    Sjijiashicang,
    Qjuzhanshijsc,
    Shizhenfu,
    Jiebanggs
} from '../../asstes/news-detail';
import Banner from '../../components/banner';
import { useParams } from 'react-router-dom';


function News() {
    const { id } = useParams();
    const newsBanner = Number(id) === 0 ? newsDetailBanner1 : Number(id) === 1 ? newsDetailBanner2 : Number(id) === 3 ? newsDetailBanner4 : newsDetailBanner3;
    const copywriting = () => {
        if (Number(id) === 0) {
            return {
                title: '科技赋能智慧交管',
            }
        } else if (Number(id) === 1) {
            return {
                title: '衢州市创新打造应用，破解电气焊监管难题“焊智保”',
            }
        } else if (Number(id) === 2) {
            return {
                title: '如何破解电气焊安全监管难题?',
                content: <div className='banner-title'>浙江衢州“焊智保”全链条数字化监管应用成效显</div>
            }
        } else {
            return {
                title: '巡特警揭榜挂帅，海宴科技成功揭榜!'
            }
        }
    }

    const Content_0 = () => {
        return (
            <div className='con0_con' style={{ display: Number(id) === 0 ? 'block' : 'none' }}>
                <div className='container'>
                    <div className='section1'>
                        <p>
                            跨巡-交通违章，采用多种手段实时监控道路路口或隧道口的行人和非机动车实时动态信息，并利用边缘计算设备现场跨媒体分析违法事件，对接已有的应急广播系统，快速响应违法事件通过应急广播进行语音警示。每个监控场景分析后的数据可统一汇聚到管理平台，管理平台为用户提供数据驾驶舱、数据分析、数据汇总和查询等功能模块，为制定管理决策提供一手数据。
                        </p>
                    </div>
                    <div className='imgstyle'>
                        <img src={Jiashicang} alt='jsc' width="600" height="299" />
                        <div className='imgexplain'>数据驾驶舱</div>
                    </div>
                    <div className='mainintroduce'>
                        <div className='main1'>
                            <div className='title'>(1) 事件检测</div>
                            <div className='shortind'  >道路路口及隧道口违法事件检测，检测目标是行人及非机动车(电瓶车)</div>
                            <div className='detailedind'>
                                <div style={{ width: "100%" }}>
                                    <div className='dtitle'>1）斑马线违法检测</div>
                                    <div className='dshortind'>
                                        <p>闯红灯违法行为检测及违法取证(调整相机焦距，对准局部重点区域，能看清人脸以及电瓶车车牌，不一定监控全区域，焦距调整到对面半边的路，能看到红绿灯，目前测试点位只能观察一半，真正部署对面也要有)，非机动车未佩戴头盔。</p>
                                    </div>
                                </div>
                                <img src={Banmaxianwf} alt='jsc' width="600" height="343" />
                                <div style={{ width: "100%" }}>
                                    <div className='dtitle'>2）隧道口违法检测</div>
                                    <div className='dshortind'>
                                        <p>隧道口行人及非机动车闯入（对监控指定区域内的行人、非机动车出现进行实时检测），护栏翻越行为</p>
                                    </div>
                                </div>
                                <img src={Suidaokouwf} alt='jsc' width="600" height="343" />
                            </div>

                            <div className='title'>(2) 联动警示</div>
                            <div className='shortind' style={{ marginBottom: "54px" }}>功能介绍：对接应急广播系统，事件产生后回调推送事件给应急广播系统，实现低延时联动。</div>

                            <div className='title'>(3) 数据分析</div>
                            <div className='shortind' style={{ marginBottom: "112px" }}>路口(隧道口) 节点及时间段违章统计分析，为警力分配提供科学依据：人员累计违法分析 (通过人脸识别) ，通过大数据平台给与个人合法警示提醒!</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const Content_1 = () => {
        return (
            <div className='con1_con' style={{ display: Number(id) === 1 ? 'block' : 'none' }}>
                <p>
                    该市聚焦电气焊无证上岗、违规动火作业监管难等问题，创新迭代“焊智保”数字化监管应用，实现电气焊作业从“以人管人”向“以码管机、以机管人、以智管焊” 转变。今年6月应用上线以来，该市电气焊事故零发生。
                </p>
                <p>
                    一是赋码加芯，作业流程全程管控。率先探索 “焊智保” 数字化监管应用，全面归集应急管理、市场监管、住建等6部门13个数据库400余万条数据。并对电焊机加装物联网芯片，开通专属电子工牌，通过电子工牌、电焊机、小程序三者联合控制、相互验证，实现对电气焊作业全流程、可追溯管理。截至目前，该应用累计接入涉电气焊企业 3567家、电气焊作业人员 6812名，记录作业数据2.9万条。
                </p>
                <p>
                    二是智能分析，风险隐患提早识别。构建 “焊智保”监控预警子场景，实时分析电焊工的作业数据、安全记录、违规行为等平台数据，对电焊机终端已注册但超1个月未开机使用、开机时长超12小时等多种异常情况进行预警提醒推送至执法部门现场检查，构建“线上监控+线下核查”的联动管控机制，确保电焊作业安全。目前，已通过系统智能识别并处理异常事件 18 起。
                </p>
                <p>
                    三是优化机制，违规作业及时处置。将无电气煤作业源头排查相关工作纳入基层“141”工作体系和乡镇安监员网格员巡查事项，实行网格化管理，发现隐患及时上报处置。并完善该市安全生产领域举报奖励办法，提升举报奖励力度，对于核查出的无证、假证上岗问题，给予行政处罚金额 15% 的奖励金,最低奖励3000 元。今年以来，累计排查并处置违规电气焊事件26起。
                </p>
                <div className='imgShow'>
                    <img src={Sjijiashicang} alt='jsc' width="530" height="299" />
                    <img src={Qjuzhanshijsc} alt='jsc' width="530" height="299" />
                </div>
            </div>
        )
    }

    const Content_2 = () => {
        return (
            <div className='con1_con' style={{ display: Number(id) === 2 ? 'block' : 'none' }}>
                <p>
                    如何破解电气焊安全监管难题? 10月27日，浙江省衢州市召开新闻发布会，介绍当地推进“电气焊安全监管服务一件事改革”的经验举措。
                </p>
                <p>
                    人员无证上岗且流动性大，违规作业难以被发现，监管涉及应急管理等多部门，难以形成合力·.长期以来，这些都是电气焊作业安全监管的难点痛点。
                </p>
                <p>
                    针对这些难点，今年以来，衢州市应急管理局针对电气焊无证上岗多、人员流动大、违规动火作业监管难等问题，在全省范围内率先开始电气焊作业数字化监管探索，谋划上线“焊智保”全链条数字化监管应用，实现电气焊作业从“以人管人”向“以码管机、以机管人、以智管焊”转变。
                </p>
                <p>
                    当地通过打通部门壁垒，强化协同共治，全面归集应急管理、市场监管、住建等多个部门特种作业操作证、建筑施工操作资格证、特种设备安全管理和作业人员证等数据库累计400余万条数据，精准覆盖电气焊企业、作业人员、电焊机终端等相关信息。此外，还通过对电焊机赋码加“芯”，操作工凭特种作业证入库，开通专属电子工牌，“人、码、机”三方互相验证联合控制，实现对电气焊作业全流程、可追溯管理。
                </p>
                <p>
                    除了筑牢“数据”和“设备”两道落篱，衢州还通过依托当地优势，优化考培和就业服务，在“人员”上下功夫。先后在“浙江省安全生产网”开设电气焊作业理论培训专栏实现线上免费培训，参与人数已超万人。组织特训班、定点辅导班等多种形式的培训班，并增加电气焊培训考试班次，取证合格率明显提升。依托培训机构探索组建特种作业劳务分包公司，完善持证人员考培体系，进一步规范电气焊特种作业劳务用工市场。
                </p>
                <p>
                    据统计，“焊智保”全链条数字化监管应用自2023年6月上线以来，累计覆盖衢州全市涉电气焊企业3567家，接入申气焊作业人员6812名，电焊机智能终端加装3575台，应用已在省内杭州、绍兴等多地复制推广。
                </p>
                <div className='imgShow'>
                    <img src={Shizhenfu} alt='jsc' width="553" height="326" />
                </div>
            </div>
        )
    }

    const Content_3 = () => {
        return (
            <div className='con1_con' style={{ display: Number(id) === 3 ? 'block' : 'none' }}>
                <p>
                    衢州在2022年承接了省厅揭榜挂帅子场景建设，并通过开发“电子数据智治共享应用项目”，实现了一键取证、模型预警等功能的开发。这些创新举措不仅在全市范围内得到了推广应用，还被省厅平台复用，并纳入了数字法治S3一本账，同时还获得了全省政法专项资金200万的支持。
                </p>
                <p>
                    今年，衢州市确定了“114N”体系架构，加快迭代升级系统应用，并加强开放实验室和取证标准建设，构建了省级集成、市级优化、所队延伸的三级电子数据作战架构。这一架构主动打造了分布采集、融合共享、网地一体协同作战体系，旨在提升衢州市在电子数据作战方面的能力和水平。具体而言，衢州市采取了以下做法：
                </p>
                <p>
                    一、强化警企战略合作，统一数据标准，优化警种协同作战，建立闭环处置流程。通过与企业开展战略合作，实现数据标准的统一，提高警种间协同作战能力，建立从预警到处置的闭环流程。同时，深化检法一体联动，实现举证高效协同，进一步提升执法效能。
                </p>
                <p>
                    二、打通上下级节点数据通道，建立存储和传输标准统一的数据指纹库，优化平台数据治理能力。通过建立标准化的数据指纹库，实现上下级节点数据通道的畅通，确保数据的安全存储和传输。同时，围绕数据预处理、特征选择、模型构建、分析挖掘、结果评估等环节进行优化，推进数据建模、全息画像、自助取证实战应用的迭代和提升。此外，还打通基层自助取证和执法办案平台数据链路，确保数据的实时同步，有效回应执法办案的现实需求。
                </p>
                <p>
                    三、开放实验室，将定岗人员纳入市局“双师”评定范围。同时，加强理论研究，形成系统性的调研文章。定期开展专项会战和技战法评比，总结提炼类案特征，形成一批可复用的情报挖掘战法和网络犯罪生态节点监测模型。通过以上做法，衢州市在电子数据作战方面取得了重要的创新成果。这些创新举措不仅提升了执法办案的效能和水平，还进一步推动了数字法治的发展，为打击犯罪、维护社会安全做出了积极贡献。
                </p>
                <div className='imgShow'>
                    <img src={Jiebanggs} alt='jsc' width="553" height="767" />
                </div>
            </div>
        )
    }

    return (
        <div id="news-details">
            <Banner height="340px" backgroundImage={newsBanner} copywriting={copywriting()} />
            <div className="news-details-main" >
                <Content_0 />
                <Content_1 />
                <Content_2 />
                <Content_3 />
            </div>
        </div>
    );
};

export default News;