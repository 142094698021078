/*
 * @Author: your name
 * @Date: 2021-10-13 10:23:02
 * @LastEditTime: 2024-01-12 11:44:16
 * @LastEditors: chenyu chenyu@hiyanai.com
 * @Description: 产品特点
 * @FilePath: \hiyan_pc_web\src\views\products\components\products-detail\index.js
 */
import React from 'react';
import { data } from '../../../../datas/products/products-detail';
/**
 * @description: 产品特点
 */
function ProductsDetail({ index }) {
    return (
        <div className="products-detail">
            <div className="products-detail-content">
                <div className="products-detail-content-header">
                    <h2 className="products-detail-content-title">产品特点</h2>
                    <div className="products-detail-content-title-bottomLine"></div>
                </div>
                <div className="products-detail-content-detailList">
                    {
                        (data[index].details || []).map(({ id, title, icon, info, infoStyle }) => {
                            return (
                                <div className="products-detail-content-item" key={id} >
                                    <img src={ icon }  alt="" />
                                    <p className="products-detail-content-item-title">{title}</p>
                                    <p className="products-detail-content-item-info" style={infoStyle}>{info}</p>
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        </div>
    );
};

export default ProductsDetail;