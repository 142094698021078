/*
 * @Author: chenyu chenyu@hiyanai.com
 * @Date: 2024-01-10 09:19:52
 * @LastEditors: chenyu chenyu@hiyanai.com
 * @LastEditTime: 2024-01-10 16:26:27
 * @FilePath: \hiyan_pc_web\src\views\core-tech\components\core-tech-content\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React from 'react';
import { Image } from 'antd';
import {
    rhjs,
    xrtt,
    yspbj,
    rqycxw,
    txy
} from '../../../../asstes/images';
import './index.scss';
/**
 * @description: 核心技术
 */

function CoreTechContent() {
    const advantageList = [{
        id: 0,
        content: '融合技术：',
        describe: '基于编码的跨模态混合融合方法，实现多模态场景数据的模态对齐以及跨媒体特征融合，提升领域应用场景下模型推理能力。',
    }, {
        id: 1,
        content: '编码通信（分布式计算）：',
        describe: '基于群的分布式编码计算框架，降低节点数据交互量，提升分布式计算平台的大模型运行效率。',
    }];

    const honorsImgList = [{
        id: 0,
        icon: xrtt,
    }, {
        id: 1,
        icon: yspbj,
    }, {
        id: 2,
        icon: rqycxw,
    }, {
        id: 3,
        icon: txy,
    }];

    return (
        <div className="core-framework">
            {/* <div className="framework-content">
                <div className="framework-content-title">CodecFlow（编码流）框架</div>
                <div className="framework-content-block">
                    <div className='framework-content-descript'>基于自研的CodecFlow技术构建平台框架和多模态大模型，降低分布式计算通信数据量以及增强跨媒体数据信息融合能力，解决领域多模态大模型的落地应用问题。</div>
                    <Image className="about-us-honor-item-certificate" src={framework}></Image>
                    <span className='codecFlow-text'>CodecFlow</span>
                </div>
            </div> */}
            <div className="framework-content">
                <div className="framework-content-title">技术优势</div>
                <div className="framework-content-block">
                    {/* {
                        (advantageList || []).map(({ id, content, describe }) => {
                            return (
                                <div className="advantage-list-item" key={id}>
                                    <p className='advantage-list-item-content'>{content}</p>
                                    <span className='advantage-list-item-describe'>{describe}</span>
                                </div>
                            );
                        })
                    } */}

                    <div className="advantage-list-item" key={advantageList[0].id}>
                        <p className='advantage-list-item-content'>{advantageList[0].content}</p>
                        <span className='advantage-list-item-describe'>{advantageList[0].describe}</span>
                    </div>
                    <Image className="about-us-honor-item-rhjs" src={rhjs} preview={false}></Image>
                    <div className="advantage-list-item" key={advantageList[1].id}>
                        <p className='advantage-list-item-content'>{advantageList[1].content}</p>
                        <span className='advantage-list-item-describe'>{advantageList[1].describe}</span>
                    </div>
                </div>
            </div>
            <div className="framework-content">
                <div className="framework-content-title">专利支持</div>
                <div className="framework-content-block">
                    <div className='honors-img-list'>
                        {(honorsImgList || []).map(({ id, icon }) => {
                            return (
                                <div className='honors-img' key={id}>
                                    <Image className="honors-img-certificate" src={icon}></Image>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className='patent-wrap'>
                    <span className='patent-text-bold'>目前专利布局中</span>
                    <span className='patent-text-bold'>发明专利号</span>
                    <span className='patent-text'>（Zl 202211357025.8）</span>
                    <span className='patent-text'>Transformer</span>
                    <span className='patent-text'>分布式计算</span>
                </div>
            </div>
        </div>
    );

};

export default CoreTechContent;