/*
 * @Author: your name
 * @Date: 2021-07-12 15:26:47
 * @LastEditTime: 2024-01-11 10:43:34
 * @LastEditors: chenyu chenyu@hiyanai.com
 * @Description: In User Settings Edit
 * @FilePath: \hiyan_pc_web\src\views\products\index.js
 */
import React,  { useState } from 'react';
import './index.scss';
// import { Carousel } from 'antd';
// import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
import { data } from '../../datas/products/products-banner';
import Banner from '../../components/banner';
import ProductsDetail from './components/products-detail';
import ProductsFunc from './components/products-func';
import ApplicationScenarios from './components/application-scenarios';
/**
 * @description: 产品介绍
 */
function Products({ index = 0 }) {
    const [indexA, setIndex] = useState(index);
    function onChange(index) {
        let productsDetail = document.querySelector('.products-detail-content-detailList');
        if(productsDetail) {
            productsDetail.style.opacity = 1;
        }
        setIndex(index);
    };
    function beforeChange() {
        let productsDetail = document.querySelector('.products-detail-content-detailList');
        if(productsDetail) {
            productsDetail.style.opacity = 0;
        }
    };
    const products = (data || []).map((item) => {
        return <Banner {...item} key={item.id} />;
    });
    return (
        <div id="products">
            {/* <Carousel   autoplaySpeed={3000} beforeChange={beforeChange} afterChange={ onChange }>
                {products}
            </Carousel> */}
            <Banner {...data[index]}  />
            <ProductsDetail index={index} />
            <ProductsFunc index={index} />
            <ApplicationScenarios index={index} />
        </div>
    )
}

export default ( parameter ) => {
    const index = parameter.location.pathname.split('/').slice(-1)[0];
    return <Products index={index} />
};
