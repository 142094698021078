/*
 * @Author: your name
 * @Date: 2021-07-12 15:26:47
 * @LastEditTime: 2024-01-11 15:32:43
 * @LastEditors: chenyu chenyu@hiyanai.com
 * @Description: In User Settings Edit
 * @FilePath: \hiyan_pc_web\src\views\home\index.js
 */
import React from 'react';
// import Banner from './components/banner';
import Banner from '../../components/banner';
import { CoreTechnology } from './components/core-technology';
import { CorporateCulture } from './components/corporate-culture';
import Framework from './components/framework';
import Honors from './components/honors';
import CoreSystem from './components/core-system';
import Solution from './components/solution';
import NewsInformation from './components/news-information';

import './index.scss';
import { Carousel } from 'antd';
import { data } from '../../datas/home/home-banner';


/**
 * @description: 首页
 * @param {props} 
 * @return: 
 */
function Home({ history, ...e }) {

    const products = (data || []).map((item) => {
        return <Banner {...item} height="550px" key={item.id} />;
    });
    return (
        <div id="home">
            <Carousel autoplay autoplaySpeed={3000}>
                {products}
            </Carousel>
            {/* <Banner backgroundImageContent={philosophyBanner}  cuttingText={hqhy} /> */}
            <CorporateCulture />
            {/* <Framework /> */}
            {/* <CoreTechnology /> */}
            <CoreSystem />
            <Honors />
            {/* <NewsInformation /> */}
        </div>
    )
}

export default Home;
