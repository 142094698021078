/*
 * @Author: your name
 * @Date: 2021-10-12 14:08:39
 * @LastEditTime: 2021-10-18 10:33:12
 * @LastEditors: Please set LastEditors
 * @Description: 解决方案
 * @FilePath: \hiyan_pc_web\src\views\home\components\solution\index.js
 */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {     
    solutionBg1,
    solutionBg2,
    next,
    switchLeft,
    switchRight,
} from '../../../../asstes/images';
import './index.scss';
/**
 * @description: 解决方案
 */

function Solution() {
    const [ blockState, setbBlockState ] = useState('left');

    const solutionList = [{
        id: 0,
        title: '跨媒体音视频笔记解决方案',
        article: '学习的重心在于校园，课堂知识是教与学的根本所在。帮助学生更好掌握课堂知识内容，让学习回归课堂，使学生学好学足课堂知识。在国家“双减”政策的指导下，通过运用AI 2.0技术，把学校课堂内容数字化，采用海宴跨媒体表征推理引擎进行课堂音视频脱敏重构，生成学生个性化的智能课堂笔记。基于互联网云服务、流媒体技术向学生提供学习辅导工具，有效减轻学生学习压力以及家长陪护辅导精力。',
        icon: solutionBg1,
        path: '/intelligent/programme/0',
    }
    ,{
        id: 1,
        title: '公安大数据实战研判解决方案',
        article: '基于人流、车流、手机流等数据，运用海宴跨媒体表征推理引擎实现对目标人群的行程轨迹重构和特征数据汇聚碰撞，通过大数据研判推理人、车、手机等物理对象之间的关联关系，掌握重点关注人员及场所关联信息，协助办案人员完成快速侦破，为高效排查提供线索，精准管控关注人员，达到及时掌控风险的目标。',
        icon: solutionBg2,
        path: '/intelligent/programme/1',
    }
    ];

    function Solutions() {
        return (solutionList || []).map(({ id, title, article, icon, path }) => {
            return (
                <div className="solution-content-block-item"  key={id}>
                    <img src={icon} className="solution-content-block-image" alt="" />
                    <div className="solution-content-block-detail">
                        <div className="solution-content-block-detail-title">{title}</div>
                        <div className="solution-content-block-detail-article">{article}</div>
                        <Link className="solution-content-block-detail-getmore" to={{pathname: path }}>
                            了解详情
                            <img src={ next } className="solution-content-block-detail-getmore-next" alt="" />
                        </Link>
                    </div>
                </div>
            );
        });
    };
    function solutionPre() {
        setbBlockState('left');
        let blockList = document.querySelector('.solution-content-block-list');
        blockList.style.transform = 'translateX(0px)';
    };
    function solutionNext() {
        setbBlockState('right');
        let blockList = document.querySelector('.solution-content-block-list');
        blockList.style.transform = 'translateX(-1200px)'
    }
    return (
        <div className="solution">
            <div className="solution-content">
                <div className="solution-content-title">解决方案</div>
                <div className="solution-content-block">
                    { blockState !== 'left' && <img onClick={ solutionPre } className="solution-content-control left" src={switchLeft} alt="" /> }
                    { blockState !== 'right' && <img onClick={ solutionNext } className="solution-content-control right" src={switchRight} alt="" /> }
                    
                    <div className="solution-content-block-list">
                        <Solutions />
                    </div>
                </div>
            </div>
            
        </div>
    );

};

export default Solution;