import { demoCard1, demoCard2, demoCard3, demoCard4, demoCard5 } from '../../asstes/images';

const datas = [{
    id: '1',
    imgSrc: demoCard1,
    imgName: 'demo-card-1.jpg'
},{
    id: '2',
    imgSrc: demoCard2,
    imgName: 'demo-card-2.jpg'
},{
    id: '3',
    imgSrc: demoCard3,
    imgName: 'demo-card-3.jpg'
},{
    id: '4',
    imgSrc: demoCard4,
    imgName: 'demo-card-4.jpg'
},{
    id: '5',
    imgSrc: demoCard5,
    imgName: 'demo-card-5.jpg'
}];

// 审核模板初始化数据
const initAuditTemplate = [{
        name: "色情识别",
        examine: "通过",
        status: 'pass',
        type: 0
    },
    {
        name: "性感低俗识别",
        examine: "通过",
        status: 'pass',
        type: 1
    },
    {
        name: "暴恐识别",
        examine: "通过",
        status: 'pass',
        type: 2
    },
    {
        name: "文字广告识别",
        examine: "通过",
        status: 'pass',
        type: 3
    },
    {
        name: "二维码识别",
        examine: "通过",
        status: 'pass',
        type: 4
    },
    {
        name: "文字敏感词识别",
        examine: "通过",
        status: 'pass',
        type: 5
    },
    {
        name: "政治人物识别",
        examine: "通过",
        status: 'pass',
        type: 6
    },
    {
        name: "公众人物识别",
        examine: "通过",
        status: 'pass',
        type: 7
    }]


export {
    datas,
    initAuditTemplate
}
