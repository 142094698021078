/*
 * @Author: your name
 * @Date: 2021-07-12 15:26:47
 * @LastEditTime: 2021-09-16 18:57:38
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \hiyan_pc_web\src\views\func-demonstration\index.js
 */
import React, { useState } from 'react';
import PictureReview from './components/picture-review';
import Http from './components/http';
import './index.scss';

/**
 * @description: 功能演示
 * @param {}
 * @return: 
 */
function FuncDemonstration() {

    const [ httpRes, sethttpRes] = useState(null);         // http检测返回的res
    const [ isChecking, setIsChecking ] = useState(true); // 是否正在检测
    const [ isCheckPass, setIsCheckPass ] = useState(true); // 检测是否合规
    const [ imageErrorInfo, setImageErrorInfo ] = useState({    // 图片错误信息
        isImageError: false,
        errorMsg: ''
    });                                               

    return (
        <div id="func-demonstration">
            <div className="func-demonstration-content">
                <PictureReview 
                    isChecking={isChecking}
                    imageErrorInfo={imageErrorInfo}
                    sethttpRes={(res) => sethttpRes(res)} 
                    setIsChecking={(flag) => setIsChecking(flag)}
                    setisCheckPass={(flag) => setIsCheckPass(flag)}
                    setImageErrorInfo={(flag) => setImageErrorInfo(flag)}
                />
                <Http httpRes={httpRes} isChecking={isChecking} isCheckPass={isCheckPass} imageErrorInfo={imageErrorInfo} />
            </div>
        </div>
    )
}

export default FuncDemonstration;
