import axios from 'axios';
import qs from 'qs';
import { MD5Params } from './index';

class Http {
    constructor() {
        /**
         * 跨越拦截标识'/api'
         * @description: 
         * ***开发中使用配置代理标识/api(拦截标识)解决开发中的跨域，同样上线的时候需要后端配置ngix拦截/api请求标识解决线上跨域问题
         *              
         *     或者前端开发中使用拦截标识如/api等进行webpack配置代理解决开发中的跨域问题，
         *     上线的时候，前端自己把所有的拦截标识如/api等全部改为真实的线上接口地址域名。
         *     --->create-react-app中process.env.NODE_ENV值:
         *                           'development' 表示开发环境--->运行的是npm start
         *                           'production'  表示生产环境--->运行的是npm run build
         *                           'test'        表示测试环境--->运行的是npm test
         */                          
        this.devBaseUrl = '/api';
        this.proBaseUrl = 'https://test.hiyanai.com' 
        
    }
    /**
     * @description: 获取当前环境下的baseUrl
     * @param {} 
     * @return: baseUrl
     */
    getCurrentBaseUrl() {
        const { devBaseUrl, proBaseUrl } = this;
        return process.env.NODE_ENV === 'development' ? devBaseUrl : proBaseUrl;
    }
    /**
     * @description: request请求方法
     *               默认情况下(get or post请求)：axios将JavaScript对象序列化为JSON，
     *               即'content-type': 'application/json',
     *               post请求根据需求可能需要将请求头'content-type'设置为'application/x-www-form-urlencoded',
     *               当设置为'application/x-www-form-urlencoded'的时候,需要配合qs.stringify({ ... })进行转化
     * 
     * @param {url, data, method, isFile:是否是文件上传(图片/文件等上传...)} 
     * @return: 
     */
    request({ url, data, method = 'get', isFile = false }) {
        const currentBaseUrl = this.getCurrentBaseUrl();
        const httpUrl = currentBaseUrl + url;

        if(method === 'get') {
            return this.getJSON(httpUrl, data);
        }else if(method === 'post') {
            return this.postJSON({
                url: httpUrl, 
                data, 
                isFile,
            });
        }

    }
    /**
     * @description: get请求
     * @param {url, data} 
     * @return: 
     */
    getJSON(url, data) {
        return new Promise((resolve, reject) => {
            axios.get(url, {
                params: {
                    ...data,
                    ...MD5Params()
                }
            }).then(res => {    
                if(res.status === 200) {
                    resolve(res.data);
                }else {
                    reject(res);
                }
            }).catch(err => {
                console.log('err', err);
                reject(err);
            })
        }) 
    }
    /**
     * @description: post请求
     * @param {url, data} 
     * @return: 
     */
    postJSON({ url, data, isFile }) {
        return new Promise((resolve, reject) => {
            const postData = isFile ? data : qs.stringify({ ...data, ...MD5Params() });
            axios.post(url, postData, {
                    headers: {
                        'content-type': 'application/x-www-form-urlencoded'
                    }
                }
            ).then(res => {
                if(res.status === 200) {
                    resolve(res.data);
                }else {
                    reject(res);
                }
            }).catch(err => {
                console.log('err', err);
                reject(err);
            })
        })
    }
}

export {
    Http
}
