import React, { useState } from 'react';
import Menu from './components/menu';
import ScrolllNav from './components/scroll-nav';
import { datas } from '../../datas/technical-doc/menu';
import './index.scss';

/**
 * @description: 技术文档
 * @param {}
 * @return: 
 */
function TechnicalDoc() {

    const [ currentMenuKeys, setMenuKeys ] = useState({ openKey: '1', selectedKey: '1-1'});
    const { openKey, selectedKey } = currentMenuKeys;
    
    /**
     * @description: 选择菜单具体某个选项事件
     * @param { fatherId, childrenId }
     * @return: 
     */
    function menuChildrenCheckClick({ fatherId, childrenId }) {
        setMenuKeys({ openKey: fatherId, selectedKey: childrenId });
        scrollToTop();
    }
    /**
     * @description: 滚动到页面顶部
     * @param {type} 
     * @return: 
     */    
    function scrollToTop() {
        const scrollDom = document.querySelector(".scroll-main-wrapper"),
              layout    = document.querySelector(".layout-main");
        scrollDom.scrollTop = 0;
        layout.scrollTop = 0;
    }

    return (
        <div id="technical-doc">
            <div className="technical-doc-content">
                <Menu  
                    openKey={openKey}
                    selectedKey={selectedKey}
                    datas={JSON.parse(JSON.stringify(datas))}
                    menuChildrenCheckClick={menuChildrenCheckClick}
                />
                <ScrolllNav currentId={selectedKey} />
            </div>
        </div>
    )
}

export default TechnicalDoc;
