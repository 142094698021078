import React, { useState, useEffect } from 'react';
import Anmite from '../../../../components/anmite';
import Button from '../../../../components/button';
import { datas, initAuditTemplate } from '../../../../datas/func-demonstration/picture-review';
import { imageError } from '../../../../asstes/images';
import { FuncModel } from '../../../../services/func-demonstration';
import { MD5Params, stopDefault } from '../../../../utils/index';
import { Img } from '../../../../utils/img';
const funcModel = new FuncModel();
const img = new Img();

/**
 * @description: 图片审核部分
 * @param {props}
 * @return: 
 */
function PictureReview({ isChecking, imageErrorInfo: { isImageError, errorMsg }, sethttpRes, setIsChecking, setisCheckPass, setImageErrorInfo }) {

    const [ currentPictureId, setCurrentPicture ] = useState('1');
    const [ inputPicVal, setInputPicVal] = useState('');
    const [ networkPictureUrl, setNetworkPictureUrl ] = useState('');
    const [ auditTemplateList, setAuditTemplateList ] = useState([]);

    useEffect(() => {
        const { imgSrc, imgName } = datas[0];
        
        checkCurrentPicture(imgSrc, imgName);

        return () => {
            
        }
    }, [])
    
    /**
     * @description: 渲染图片列表
     * @param {}
     * @return: 
     */
    function renderImageList() {
        return (datas || []).map(({ id, imgSrc, imgName }) => (
            <div className={"picture-review-image-wrapper" + (id === currentPictureId ? " active" : "")} key={id}>
                <img 
                    className="picture-review-image"
                    src={imgSrc} alt="" key={id}
                    onClick={() => {
                        if(!isLocked()) {
                            checkCurrentPicture(imgSrc, imgName);
                            handleCurrentPicture(id)
                        }
                    }}
                />
             </div>
        ))
    }
    /**
     * @description: 审核结果列表
     * @param {}
     * @return: 
     */
    function renderPicReviewResult() {
        return (auditTemplateList || []).map(({ name, examine, status, type }) => (
            <li className="picture-review-result-item" key={type}>
                <span className="picture-review-result-item-name">{name}</span>
                <span>{examine}</span>
            </li>
        ))
    }
    /**
     * @description: 图片列表点击事件
     * @param {id}
     * @return: 
     */
    function handleCurrentPicture(id) {
        if(networkPictureUrl) {
            restState(setNetworkPictureUrl);
        }
        setCurrentPicture(id);
    }
    /**
     * @description: 获取当前展示的图片大图
     * @param {}
     * @return: imgSrc
     */
    function getCurrentShowImage() {
        if(networkPictureUrl) {
            return networkPictureUrl;
        }
        const { imgSrc } = (datas || []).find(item => item.id === currentPictureId) || {};
        return imgSrc;
    }
    /**
     * @description: 检测网络图片
     * @param {}
     * @return: 
     */
    function handleNetworkPictureUrl(e) {
        stopDefault(e);
        if(!inputPicVal) {
            return;
        }
        img.isImgExist(inputPicVal).then((res) => {
            if(res && res.status) {
                const data = { type: '1', url: inputPicVal };
                
                setNetworkPictureUrl(inputPicVal);
                _checkPicture(true)(data);

            }
        }).catch(err => {
            console.log('err', err);
            setImageErrorInfo({
                isImageError: true,
                errorMsg: '图片地址或格式错误，请更换地址后重新体验。'
            });
        })

        restState(setInputPicVal);
        restState(setCurrentPicture);
    }
    /**
     * @description: 获取formData数据
     * @param {file: 文件对象file}
     * @return: 
     */
    function getFormData(file) {
        let formData = new FormData();
        const { timestamp } = MD5Params();
        formData.append('file', file);
        formData.append('type', '2');
        formData.append('timestamp', timestamp);
        // 去掉signature参数 formData.append('signature', signature);
        return formData;
    }
    /**
     * @description: 
     * 本地上传图片：将图片转换为base64进行预览---> FileReader的readAsDataURL()方法,在fileReader实例的onload函数参数e.target.result获取到
     * @param {event事件对象}
     * @return: 
     */
    function uploadFilePictureChange(event) {
        let file = event.target.files[0];
        if(file) {
            // 上传图片不能大于2M
            if(file.size > 2000 * 1024) {
                setImageErrorInfo({
                    isImageError: true,
                    errorMsg: '上传图片过大，请更换图片后重新体验。'
                })
                return;
            }
            const formData = getFormData(file);

            _checkPicture(false)(formData);
            picToBase64Show(file);
        }
    }
    /**
     * @description: 上传图片-审核图片
     * @param {isNetworkPic: 是否是在线图片检测}
     * @return: 
     */
    function _checkPicture(isNetworkPic) {
        if(isImageError) {
            setImageErrorInfo({
                isImageError: false,
                errorMsg: ''
            });
        }
        setIsChecking(true);
        let funcName = isNetworkPic ? 'checkPictureNetwork' : 'checkPictureUpload';
        /**函数柯里化 */
        return function(formData) {
            funcModel[funcName](formData).then(res => {
                /*
                 * conclusionType:1--->合规 conclusionType:2--->不合规
                 */
                if(res && res.conclusionType === 2) {  // 不合规
                    const data = res.data;
                    let result = (initAuditTemplate || []).map(item => {
                        let itemCopy = { ...item };
                        (data || []).forEach(_item => {
                            if(_item.type === item.type) {
                                itemCopy.status = 'unpass';
                                itemCopy.examine = _item.msg;
                            }
                        })
                        return itemCopy;
                    })
                    setAuditTemplateList(result);
                    setisCheckPass(false);
                }else {                              // 合规, 数据初始化
                    setAuditTemplateList(initAuditTemplate);
                    setisCheckPass(true);
                }
                sethttpRes(res);
            }).catch(err => {
                console.log(err);
            }).finally(() => {
                setIsChecking(false);
            })
        }
    }
    /**
     * @description: 上传图片-转化为base64进行展示预览
     * @param {file文件对象}
     * @return: 
     */
    function picToBase64Show(file) {
        img.getBase64ByFile(file).then(base64 => {
            if(base64) {
                setNetworkPictureUrl(base64);
            }
        })
        restState(setCurrentPicture);
    }
    /**
     * @description: 重置state初始状态
     * @param {setState, initState}
     * @return: 
     */
    function restState(setState, initState = '') {
        setState(initState);
    }
    /**
     * @description: 检测当前的图片
     * @param {imgSrc:需要用绝对路径(import/require方式, 相对路径无效), imgName}
     * @return: 
     */
    function checkCurrentPicture(imgSrc, imgName) {
        img.imageLoad(imgSrc).then((image) => {
            let base64 = img.getBase64Image(image);
            /** base64ToFile()方法不兼容IE, base64ToFileByBlob()方法可兼容IE
             * let file = img.base64ToFile(base64, imgName);
             */ 
            let file = img.base64ToFileByBlob(base64, imgName);
            const formData = getFormData(file);

            _checkPicture(false)(formData);
        })
    }
    /**
     * @description: 是否锁住
     * @param 
     * @return: 
     */
    function isLocked() {
        return isChecking;
    }
    
    return (
        <div className="picture-review">
            <div className="picture-review-imageShow-warpper">
                {
                    isImageError ? 
                    <div className="picture-review-image-error">
                        <img className="picture-review-image-error-img" src={imageError} alt="" />
                        <p className="picture-review-image-error-text">{errorMsg}</p>
                    </div> : null
                }
                {
                    !isImageError && 
                    <img className="picture-review-imageShow" src={getCurrentShowImage()} alt="" />
                }
                <div className="picture-review-input-wrapper">
                    <div className="picture-review-input-main">
                        <div className="picture-review-input-main-inner">
                            <input 
                                className="picture-review-input-main-input" 
                                type="text" 
                                placeholder="请输入网络图片URL" 
                                onChange={(e) => setInputPicVal(e.target.value)}
                                value={inputPicVal}
                            />
                            <Button 
                                className={"picture-review-input-main-input-submit" + (inputPicVal ? " active" : "")}
                                onClick={handleNetworkPictureUrl}
                            >检测</Button>
                        </div>
                        
                        <div className="picture-review-input-main-upload">
                            <input 
                                className="picture-review-input-main-upload-file" 
                                type="file"
                                onChange={uploadFilePictureChange}
                                accept=".png, .jpg, .jpeg, .bmp"
                            />
                            <Button className="picture-review-input-main-upload-btn">本地上传</Button>
                        </div>
                        
                    </div>
                    <p className="picture-review-input-warn-text">图片文件类型支持PNG、JPG、JPEG、BMP，图片大小不超过2M。</p>
                </div>
                {
                    isChecking || isImageError ? null :
                    <div className="picture-review-result">
                        <ul className="picture-review-result-inner">
                            { renderPicReviewResult() }
                        </ul>
                    </div>
                }
                <Anmite isAnmiting={isChecking} />
            </div>
            <div className="picture-review-imageList">
                { renderImageList() }
            </div>
        </div>
    )
}

export default PictureReview;
