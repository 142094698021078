import React from 'react';
import { stopDefault } from '../../utils';

/**
 * @description: <a>标签按钮
 */
function Button({ className, children, onClick }) {
    
    return (
        <a href="#!" className={className} onClick={(e) => {
            stopDefault(e);
            onClick && onClick();
        }}>{children}</a>
    )
    
}

export default Button;
