import React, { Component } from 'react';
import './evolution.scss'
import { EvolutionIcon,Jishugyjx } from '../../asstes/about-us'
export default class Evolution extends Component {
    render(){
        return(
            <div className='evo_container'>
                <div className='con_up'>
                    <img src={EvolutionIcon} width="30" height="34" alt='jj' />
                    <span>技术沿革</span>
                </div>
                <div className='con_bo'>
                    <img className='jximg' src={Jishugyjx} width="1200" height="432" alt='gy' />
                </div>
            </div>
        )
    }
}