/*
 * @Author: chenyu chenyu@hiyanai.com
 * @Date: 2024-01-09 16:53:58
 * @LastEditors: chenyu chenyu@hiyanai.com
 * @LastEditTime: 2024-01-10 15:07:36
 * @FilePath: \hiyan_pc_web\src\views\home\components\honors\index.js
 * @Description: 首页-资质荣誉
 */
import React from 'react';
import { Image } from 'antd';
import {
    xrtt,
    yspbj,
    rqycxw,
    txy
} from '../../../../asstes/images';
/**
 * @description: 资质荣誉
 */
function Honors() {
    const honorsImgList = [{
        id: 0,
        icon: xrtt,
    }, {
        id: 1,
        icon: yspbj,
    }, {
        id: 2,
        icon: rqycxw,
    }, {
        id: 3,
        icon: txy,
    }];

    return (
        <div className="home-honors-wrap">
            <div className="core-technology-content">
                <div className="core-technology-content-title">资质荣誉</div>
                <div className='honors-img-list'>
                    {(honorsImgList || []).map(({id, icon}) => {
                        return (
                            <div className='honors-img' key={id}>
                                <Image  className="honors-img-certificate" src={icon}></Image>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );

};

export default Honors;
