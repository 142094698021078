import React, { Component } from 'react';
import './briintro.scss'
import { BriIntro } from '../../asstes/about-us'
export default class Briintro extends Component {
    render() {
        return (
            <div className='bri_container'>
                <div className='con_left'>
                    <img src={BriIntro} width="43" height="33" alt='jj' />
                    <span>公司简介</span>
                </div>
                <div className='con_right'>
                    <p>
                        <span>杭州海宴科技有限公司</span>，成立于2019年9月，
                    </p>
                    <p>
                        面向多源多媒体异构数据处理，已在治安、监所、校园、交通、应急等公共领域开拓出广阔市场，且在公安、校园、监所等领域落地应用，其中公安领域的鹰巡系统项目得到了浙江省公安厅的高度认可，已成为揭榜挂帅项目，目前已经和衢州公安开始深入合作，正逐步向全省公安推广。
                    </p>
                    <p>
                        海宴科技自研的Codecflow框架能有效提升数据处理性能，降低算力成本，正扎根于公共安全这个万亿级的市场。
                    </p>
                </div>
            </div>
        )
    }
}

